import Logo from "@common/ui/Logo"
import TextButton from "@common/ui/TextButton"
import { useProjectDataStore } from "@state/projectDataStore"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components/macro"
import SplashEnter from "./components/SplashEnter"
import { getColor } from "@utils/getColor"

function Sidebar_Splash() {
  const { splashData, brandingData } = useProjectDataStore((s) => ({
    splashData: s.splashData,
    brandingData: s.brandingData,
  }))
  const logo = brandingData?.logos[splashData?.logo]
  const logoWidth = splashData?.logoWidth
  //
  const backgroundType = splashData?.splashBg
  const backgroundMedia =
    backgroundType === "image/video"
      ? splashData?.splashBgMedia?.publicURL
      : `var(--${backgroundType})`
  const isBackgroundMedia = backgroundMedia?.includes(".")
  const backgroundOpacity = splashData?.splashBgMediaOpacity
  //
  const buttonBgColour = splashData?.buttonBgColour
  const buttonBgLink = splashData?.buttonBgLink

  const navigate = useNavigate()

  return (
    <SplashWr
      style={{
        backgroundColor: isBackgroundMedia ? "black" : backgroundMedia,
      }}
    >
      {isBackgroundMedia &&
        (backgroundMedia?.includes(".mp4") ? (
          <SplashBgVideo
            style={{
              opacity: backgroundOpacity || 0.5,
              zIndex: -1,
            }}
            autoPlay
            muted
            loop
            playsInline
            src={backgroundMedia}
          />
        ) : (
          <SplashBgImg
            style={{
              opacity: backgroundOpacity || 0.5,
            }}
            src={backgroundMedia}
          />
        ))}
      <SplashLogo
        style={{
          width: `${logoWidth || 40}rem`,
        }}
        src={logo?.publicURL}
      />
      <Button>
        <TextButton
          onClick={() => navigate(`/${buttonBgLink || "residences"}`)}
          className="enterButton"
          color={buttonBgColour}
          variant="fill"
          icon={"assets/icons/right-arrow.svg"}
          iconPosition="right"
        >
          Enter
        </TextButton>
      </Button>
    </SplashWr>
  )
}

const SplashWr = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10rem;
`

const SplashBgImg = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  object-fit: cover;
`
const SplashBgVideo = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  object-fit: cover;
`

const SplashLogo = styled.img`
  width: 25%;
`

const Button = styled.div`
  .enterButton {
    letter-spacing: 8px;
    text-align: center;
  }

  img {
    transform: rotate(90deg);
  }
`

export default Sidebar_Splash
