import { useState } from "react"
import { ArrowLeftIcon } from "@radix-ui/react-icons"
import { QRCodeSVG } from "qrcode.react"
import styled from "styled-components"
import Form from "./Form"
import TextButton from "@common/ui/TextButton"
import QRCodeOnly from "./QRCodeOnly"
import { useFavoritesStore } from "@state/favouritesStore"

type Props = {
  config: any
  show: (value: boolean) => void
}

export type ShareAppData = {
  shareAppURL: string
  combinedEncoded: string
  favIDS: string[]
}

const Share = (props: Props) => {
  const { show, config } = props
  const {
    shareType: { options, hiddenProjectId },
  } = config
  const isPWA = false
  const shareAppURLRoot = config?.shareType.shareAppURL
  const [qrUrl, setQrUrl] = useState<string | null>(null)
  const isQrCodeOnly = options === "qrCodeOnly"
  const { favorites } = useFavoritesStore((state) => ({
    favorites: state.favorites,
  }))
  const favIDS = favorites?.map((fav: any) => fav.unitName)
  const combinedEncoded = btoa(`${favIDS?.join(",")}`)
  const shareAppURL = `${shareAppURLRoot}/?pwa=${isPWA}&projectId=${hiddenProjectId.id}&encodedUnits=${combinedEncoded}`
  console.log("SHARE APP URL", shareAppURL)

  const shareAppData: ShareAppData = {
    shareAppURL,
    combinedEncoded,
    favIDS,
  }

  return (
    <Wrapper isQrCodeOnly={isQrCodeOnly}>
      <TextButton
        color="#fff"
        className="close-button"
        onClick={() => show(false)}
        style={{ borderRadius: "50%" }}
        icon={<ArrowLeftIcon color="#fff" />}
        iconPosition="right"
        variant="outline"
      />
      {isQrCodeOnly ? (
        <QRCodeOnly shareAppData={shareAppData} show={show} />
      ) : (
        <>
          <div className="form">
            <Form
              config={config}
              shareAppData={shareAppData}
              setQrUrl={setQrUrl}
            />
          </div>
          <div className="qr">
            {qrUrl ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "3em",
                }}
              >
                <QR
                  value={qrUrl}
                  size={160}
                  level="Q"
                  bgColor="var(--accent)"
                  id="qr"
                />
                <div className="no-qr">Scan to access the app</div>
              </div>
            ) : (
              <div className="no-qr">
                QR Code <span>is waiting</span>
              </div>
            )}
          </div>
        </>
      )}
    </Wrapper>
  )
}

export default Share

const Wrapper = styled.div<{ isQrCodeOnly: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .close-button {
    position: absolute;
    top: 2em;
    left: 2em;
  }

  .form {
    height: 100%;
    width: 70%;
    padding: 3em;
    display: flex;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }
  .qr {
    width: ${(props) => (props.isQrCodeOnly ? "70%" : "100%")};
    margin: 5em;
    padding: 5em;
    display: flex;
    align-items: center;
    justify-content: ${(props) =>
      props.isQrCodeOnly ? "space-between" : "center"};
    background-color: var(--primary);
  }

  .no-qr {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    color: #fff;
    font-size: 1.5em;
    letter-spacing: 0.2em;
    line-height: 0.9;

    span {
      font-size: 0.8em;
      color: var(--accent);
      text-transform: lowercase;
      font-family: secondaryFont;
      text-align: right;
      margin-right: -2em;
      font-size: 2em;
    }
  }
`

const QR = styled(QRCodeSVG)`
  width: fit-content;
  aspect-ratio: 1/1;
  & path {
    :nth-of-type(2) {
      fill: var(--primary);
    }
  }
`
