import styled from "styled-components/macro"
import ResidenceInfoCard from "./ResidenceInfoCard"
import FloorplanImage from "@common/components/ResidenceGeneric/FloorplanImage"
import ImageOverlay from "../ImageOverlay"
import UnitCopy from "../UnitCopy"
import { useStore } from "@state/store"
import { useEffect } from "react"
import gsap from "gsap"

const FloorplanSection = ({ selectedUnit }) => {
  const contentControls = useStore((s) => s.contentControls)

  useEffect(() => {
    gsap.to(".floorplan", {
      autoAlpha: contentControls.index === 0 ? 1 : 0,
      duration: 0.6,
      ease: "power3.inOut",
    })
  }, [contentControls])

  return (
    <Wrapper className="floorplan">
      <ImageOverlay selectedUnit={selectedUnit} />
      <ResidenceInfoCard unit={selectedUnit} />
      <FloorplanImage unit={selectedUnit} />
      <UnitCopy unit={selectedUnit} />
    </Wrapper>
  )
}

export default FloorplanSection

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: beige;
  display: grid;
  gap: 2rem;
  grid-template-columns: 40% 60%;
  background-color: transparent;
`
