import { useEffect, useRef, useState } from "react"
import styled from "styled-components/macro"
import gsap from "gsap"
import { useStore } from "@state/store"

const ImageOverlay = ({ selectedUnit, controlIndex = null}) => {
  const imageRef = useRef()
  const imageOverlay = useStore((s) => s.imageOverlay)
  const syncState = useStore((s) => s.syncState)

  useEffect(() => {
    gsap.to(imageRef.current, {
      autoAlpha: imageOverlay ? 1 : 0,
      duration: 0.3,
    })
  }, [imageOverlay])

  const imgSrc =
    controlIndex && controlIndex === 0
      ? selectedUnit[0]?.floorplan
      : selectedUnit[0]?.viewID

  return (
    <Wrapper ref={imageRef} className="imageOverlay">
      {selectedUnit && (
        <img
          // src={imgSrc}
          src="/payload/floorPlanMedia/1.png"
          alt={"floorplan"}
          onClick={() => {
            syncState({ imageOverlay: false })
          }}
        />
      )}
    </Wrapper>
  )
}

export default ImageOverlay

const Wrapper = styled.div`
  padding: 10rem;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: var(--primary);
  display: flex;

  img {
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: contain;
    pointer-events: all;
  }
`
