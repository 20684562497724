import { create } from "zustand"

interface Resi3DStore {
  hoveredUnit: any

  addHoveredUnit: (unit: any) => void
  removeHoveredUnit: (unit: any) => void

  hasInteracted: boolean
  setHasInteracted: (hasInteracted: boolean) => void
}

export const useResi3DStore = create<Resi3DStore>((set) => ({
  hoveredUnit: [],

  hasInteracted: false,
  setHasInteracted: (hasInteracted) => set({ hasInteracted }),

  addHoveredUnit: (unit) =>
    set((state) => ({
      hoveredUnit: state.hoveredUnit.includes(unit)
        ? state.hoveredUnit
        : [...state.hoveredUnit, unit],
    })),

  removeHoveredUnit: (unit) =>
    set((state) => ({
      hoveredUnit: state.hoveredUnit.filter((l) => l !== unit),
    })),
}))
