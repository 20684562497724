import styled from "styled-components/macro"

function Favourites({ children }) {
  return <FavPage>{children}</FavPage>
}

export default Favourites

const FavPage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
