import NavTextButton from "@common/ui/NavTextButton"
import { useStore } from "@state/store"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components/macro"
import gsap from "gsap"
import { EffectFade, Navigation, Pagination } from "swiper/modules"
import Carousel from "@common/components/Carousel"
import { useProjectDataStore } from "@state/projectDataStore"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/pagination"
import InfoCarousel from "./InfoCarousel"

const First = ({ info1Data }) => {
  const contentControls = useStore((s) => s.contentControls)

  useEffect(() => {
    gsap.to(".firstWrapper", {
      autoAlpha: contentControls.index === 0 ? 1 : 0,
      duration: 0.6,
      ease: "power3.inOut",
    })
  }, [contentControls])

  return (
    <FirstWrapper className="firstWrapper">
      <InfoCarousel info1Data={info1Data} />
    </FirstWrapper>
  )
}

export default First

const FirstWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--primary);
`
