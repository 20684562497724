import { useStore } from "@state/store"
import { useEffect } from "react"
import styled from "styled-components/macro"
import { useProjectDataStore } from "@state/projectDataStore"
import First from "./components/First"

const Info1 = (props: any) => {
  const syncState = useStore((s) => s.syncState)
  const { info1Data } = useProjectDataStore((s) => ({
    info1Data: s.info1Data,
  }))
  const contentControls = useStore((s) => s.contentControls)

  useEffect(() => {
    syncState({
      contentControls: {
        index: 0,
      },
    })
  }, [])

  return (
    <Info1Wrapper>
      <First info1Data={info1Data?.sections[0]} />
    </Info1Wrapper>
  )
}

export default Info1

const Info1Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  display: flex;
`

const Step = styled.div``
