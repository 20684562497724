import { useEffect } from "react"
import { useProjectDataStore } from "@state/projectDataStore"

import fullscreenLayout from "@layouts/fullscreen-layout/fullscreen-layout"
import sidebarLayout from "@layouts/sidebar-layout/sidebar-layout"

// Add layouts here
const layoutComponents = {
  fullscreen: fullscreenLayout,
  sidebar: sidebarLayout,
}

const ProjectLayout = () => {
  const navigationData = useProjectDataStore((s) => s.navigationData)

  useEffect(() => {
    document.title = navigationData?.name?.replace("Navigation -", "")
  }, [navigationData])

  const layout = navigationData?.layout || "fullscreen"
  const LayoutComponent = layoutComponents[layout] || fullscreenLayout

  return <LayoutComponent />
}

export default ProjectLayout
