import { useState, useEffect } from "react"

const useViewsPublicURL = (viewID: string | undefined, files: any) => {
  // Split view string logic
  const splitView = (view: string | undefined): string[] | null => {
    if (!view) return null

    const parts = view?.split("-")

    if (!parts) return null

    if (parts.length === 3) {
      const [firstPoint, secondPoint, height] = parts
      return [`${firstPoint}-${height}`, `${secondPoint}-${height}`]
    }

    if (parts.length === 2) {
      const [point, height] = parts
      return [`${point}-${height}`]
    }

    return null
  }

  // Find matching public URLs
  const findPublicURLs = (view: string | undefined, files: any): string[] => {
    const matchedURLs: string[] = []
    const fileNamesToMatch = splitView(view)
    let temp

    if (!fileNamesToMatch) return matchedURLs

    fileNamesToMatch.forEach((fileNameToMatch) => {
      files.forEach((file) => {
        if (file?.image) {
          temp = file?.image?.filename?.split(".")[0]
          if (temp === fileNameToMatch) {
            matchedURLs.push(file.image.publicURL)
          }
        }
      })
    })

    return matchedURLs
  }

  return findPublicURLs(viewID, files)
}

export default useViewsPublicURL
