const messages = {
  noChars: "Name can't contain special characters or numbers",
  noEmpty: "can't be empty",
  validEmail: "Please provide a valid email",
}

export function validateForm(obj) {
  const errors = {}

  for (const key in obj) {
    // Skip validation for the "cc" field
    if (key.toLowerCase() === "cc" || key.toLowerCase() === "withattachments") {
      continue
    }

    const value = typeof obj[key] === "string" ? obj[key].trim() : ""

    if (!value) {
      errors[key] = `${capitalize(key)} ${messages.noEmpty}`
    } else if (key.toLowerCase() === "email" && !validateEmail(value)) {
      errors[key] = messages.validEmail
    } else if (key.toLowerCase() === "name" && !validateName(value)) {
      errors[key] = messages.noChars
    }
  }

  return Object.keys(errors).length ? errors : null
}

function validateName(value) {
  // Validates that the name contains only letters and spaces
  return /^[A-Za-z\s]+$/.test(value)
}

function validateEmail(value) {
  // Validates the email format
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
}

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
