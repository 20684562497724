import * as React from "react"
import { useCallback, useMemo, useEffect, useRef } from "react"
import styled from "styled-components/macro"
import {
  MapState,
  Place,
  useMapStore,
} from "@common/features/GoogleMaps/mapStore"
import DirectionsPanel from "./DirectionsPanel"
import IconLabel from "@common/ui/IconLabel"
import { useStore } from "@state/store"
import ContentIndexControls from "@common/components/ContentIndexControls"
import { averageDistance } from "@lib/utils/google.maps"
import TextButton from "@common/ui/TextButton"

export const MapSidebar: React.FC<{
  categories: string[]
  withDirections: boolean
  mainMarker: Place
  places: Place[]
  panel: any
}> = ({ withDirections, places, panel, categories }) => {
  const contentControls = useStore((s) => s.contentControls)

  const {
    routeSteps,
    activeSection,
    setActiveSection,
    activePOI,
    setActivePOI,
    setTravelMode,
  } = useMapStore((state: MapState) => ({
    routeSteps: state.routeSteps,
    activePOI: state.activePOI,
    setActivePOI: state.setActivePOI,
    activeSection: state.activeSection,
    setActiveSection: state.setActiveSection,
    setTravelMode: state.setTravelMode,
  }))

  const categoryIndex = contentControls?.index
  const currentCategory = categories[categoryIndex]

  const filteredPlaces = useMemo(() => {
    if (currentCategory === "All") {
      return places
    } else {
      return places.filter((place) => place.category === currentCategory)
    }
  }, [places, categoryIndex])

  const handleBoxClick = useCallback(
    (place: Place) => {
      setActivePOI(place)
    },
    [setActivePOI],
  )

  const boxesRef = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    if (activePOI) {
      const index = filteredPlaces.findIndex(
        (place) => place.name === activePOI.name,
      )
      if (index !== -1 && boxesRef.current[index]) {
        boxesRef.current[index]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }
    }
  }, [activePOI, filteredPlaces])

  useEffect(() => {
    if(activePOI){
      setActivePOI(null)
    }
  }, [contentControls])

  const onClose = () => {
    setTravelMode("WALKING")
    setActiveSection("pois")
    setActivePOI(null)
  }
  return (
    <>
      <ContentIndexControls
        categories={categories}
        className={
          activeSection === "directions"
            ? "map-categories hidden"
            : "map-categories visible"
        }
      />
      <Boxes
        className={`boxes ${activeSection === "pois" ? "visible" : "hidden"}`}
      >
        {filteredPlaces?.map((place: Place, index: number) => (
          <Box
            className={`map-poi-box ${
              place?.name === activePOI?.name ? "active" : ""
            }`}
            key={place + index.toString()}
            ref={(el) => (boxesRef.current[index] = el)}
            active={place?.name === activePOI?.name ? 1 : undefined}
            onClick={() => {
              handleBoxClick(place)
            }}
          >
            <div className="top-section">
              <div className="info">
                <div className="title">{place.name}</div>
                <div className="subtitle">{place?.description}</div>
              </div>
              <div className="poi-indicator">
                {index + 1}
                {/* <SvgIcon url={`/assets/icons/poi.svg`} /> */}
                <div className="corner top-left"></div>
                <div className="corner bottom-right"></div>
              </div>
            </div>

            <IconLabel
              icon={`/assets/icons/distance.svg`}
              iconColor="#fff"
              label={averageDistance(place?.distances)}
              variant="ghost"
            />

            <div className="divider" />
            <div className="times">
              <IconLabel
                icon={`/assets/icons/walking.svg`}
                iconColor="#fff"
                label={place?.distances?.WALKING?.duration}
                variant="ghost"
              />
              <IconLabel
                icon={`/assets/icons/driving.svg`}
                iconColor="#fff"
                label={place?.distances?.DRIVING?.duration}
                variant="ghost"
              />
              <IconLabel
                icon={`/assets/icons/tube.svg`}
                iconColor="#fff"
                label={place?.distances?.TRANSIT?.duration}
                variant="ghost"
              />
              <IconLabel
                icon={`/assets/icons/cycling.svg`}
                iconColor="#fff"
                label={place?.distances?.BICYCLING?.duration}
                variant="ghost"
              />
            </div>

            {withDirections && (
              <TextButton
                color="accent"
                style={{ width: "100%", marginTop: "1em" }}
                variant="outline"
                onClick={() => {
                  setActiveSection("directions")
                  setActivePOI(place)
                }}
              >
                Get Directions
              </TextButton>
            )}
          </Box>
        ))}
      </Boxes>
      <Directions
        className={
          withDirections === true && activeSection === "directions"
            ? "visible"
            : "hidden"
        }
      >
        <DirectionsPanel
          panelRef={panel}
          routeSteps={routeSteps}
          onClose={onClose}
        />
      </Directions>
    </>
  )
}

const Boxes = styled.div``

const Box = styled.div<{ active: number | undefined }>`
  min-height: 15em;
  width: 100%;
  padding: 1.5em;
  flex: 0 0 auto;
  position: relative;
  background: rgba(255, 255, 255, 0.1);

  // this class name is inside the IconLabel component
  .label {
    font-weight: 700;
  }

  .divider {
    height: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0.5em 0;
  }

  .info {
    width: calc(100% - 3em);
  }

  .get-directions {
    width: 100%;
    background-color: transparent;
    border: 1px solid #fff;
    padding: 0.5em 1em;
    font-size: 0.7em;
    cursor: pointer;
    text-transform: uppercase;
  }

  .top-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .title {
    font-size: 1.5em;
    font-weight: bold;
    transition: color 0.3s;
  }

  .subtitle {
    font-size: 1.1em;
    opacity: 0.7;
    padding: 0.5em 0;
  }

  .distance {
  }

  .times {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .time {
      display: flex;
      gap: 1em;
      padding: 1em 0;

      & span {
        font-weight: bold;
      }
    }
  }

  .poi-indicator {
    position: relative;
    height: 3em;
    width: 3em;
    min-height: 3em;
    min-width: 3em;
    border: 1px solid #fff;
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;

    .corner {
      opacity: ${(props) => (props.active ? 1 : 0)};
      position: absolute;
      width: 10px;
      height: 10px;
      border: 1px solid #ebb3f7;
      transition: all 0.3s;
    }

    .corner.top-left {
      top: ${(props) => (props.active ? "-6px" : "0")};
      left: ${(props) => (props.active ? "-6px" : "0")};
      border-right: none;
      border-bottom: none;
    }

    .corner.bottom-right {
      bottom: ${(props) => (props.active ? "-6px" : "0")};
      right: ${(props) => (props.active ? "-6px" : "0")};
      border-left: none;
      border-top: none;
    }
  }
`

const Directions = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--primary);

  &.visible {
    opacity: 1;
    z-index: 1;
    transition: all 1s;
  }

  &.hidden {
    opacity: 0;
    z-index: -1;
    transition: all 0.3s;
  }

  .close {
    position: absolute;
    width: 100%;
    bottom: 0;
    border: 1px solid #fff;
    padding: 0.5em 1em;
    font-size: 1em;
    cursor: pointer;
    text-transform: uppercase;
  }
`
