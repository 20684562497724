import styled from "styled-components/macro"

const Residences = ({ children }) => {
  return <ResidencesPageWr>{children}</ResidencesPageWr>
}

const ResidencesPageWr = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;

  .residencesCategory {
    position: absolute;
    left: 5%;
    bottom: 0;
    margin: 4rem 2rem;
    display: flex;
    border: 0.5px solid var(--primary);

    .buttons {
      background-color: transparent;
      color: var(--primary);
      padding: 0.75rem 4rem;
      border: 0.5px solid var(--primary);
    }

    .active {
      background-color: var(--primary);
      color: white;
    }
  }

  .residences-table {
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    pointer-events: none;

    .table {
      pointer-events: all;
      padding: 2em 1em;
    }
  }

  .canvas_view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .filters-container {
    width: 32%;
    height: 60%;
    margin-top: auto;
    margin-left: 2rem;

    .filters {
      border: 1px solid var(--accent);
      background-color: var(--primary);
      color: #fff;
    }
  }

  .residences-popup {
    z-index: 1;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;

    > * {
      pointer-events: all;
    }

    .addToFavorites {
      position: absolute;
      bottom: 0;
      margin: 4rem 2rem;
      border: 1px solid var(--primary);
      padding: 0.25rem 3.25rem;
    }

    .backButton {
      top: 15%;
    }
  }

  .imageOverlayToggle {
    position: absolute;
    bottom: 15%;
    right: 0;
    margin: 2rem;
    background-color: var(--accent);
    opacity: 0.75;
    z-index: 5;
  }
`

export default Residences
