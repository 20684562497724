import { useProjectDataStore } from "@state/projectDataStore"
import { useStore } from "@state/store"
import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import gsap from "gsap"
import useViewsPublicURL from "@src/hooks/useViewsPublicURL"

const ViewSection = ({ viewID }) => {
  const { viewsData } = useProjectDataStore((s) => ({
    viewsData: s.viewsData,
  }))
  const [view, setView] = useState(0)

  const publicURLs = useViewsPublicURL(viewID, viewsData.viewMedia)

  const { contentControls, syncState } = useStore((s) => ({
    contentControls: s.contentControls,
    syncState: s.syncState,
  }))

  useEffect(() => {
    gsap.to("#view", {
      autoAlpha: contentControls?.index === 1 ? 1 : 0,
      duration: 0.6,
      ease: "power3.inOut",
    })
  }, [contentControls])

  return (
    <Wrapper id="view">
      {/* <div className="view-controls">
        <div
          id="increase"
          className={view === 0 ? "buttons active" : "buttons"}
          onClick={() => setView(0)}
        >
          North
        </div>
        <div
          id="decrease"
          className={view === 1 ? "buttons active" : "buttons"}
          onClick={() => setView(1)}
        >
          South
        </div>
      </div> */}
      <View
        src={"pano.webp"}
        onClick={() =>
          syncState({
            contentControls: {
              ...contentControls,
              index: 0,
            },
          })
        }
      />
      {/* <View src={publicURLs[view]} /> */}
    </Wrapper>
  )
}

export default ViewSection

const Wrapper = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .view-controls {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    transition: 0.3s;

    .buttons {
      background-color: rgba(256, 256, 256, 0.5);
      backdrop-filter: blur(1px);
      color: var(--primary);
      padding: 0.5rem 2rem;
      cursor: pointer;
      transition: 0.3s;
    }

    .active {
      background-color: var(--primary);
      filter: blur(0px);
      color: white;
      transition: 0.3s;
    }
  }
`

const View = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
