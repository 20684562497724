import TCanvas from "@common/canvas/TCanvas"
import Sidebar from "@common/components/Navigation/SideBar"
import PageBuilder from "@common/general/PageBuilder"
import styled from "styled-components/macro"

const SidebarLayout = () => {
  return (
    <SidebarLayoutWrapper>
      <SidebarLayoutNavWr>
        <Sidebar />
      </SidebarLayoutNavWr>
      <SidebarLayoutContentWr>
        <PageBuilder />
        <TCanvas />
      </SidebarLayoutContentWr>
    </SidebarLayoutWrapper>
  )
}

export default SidebarLayout

const SidebarLayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

const SidebarLayoutNavWr = styled.nav`
  height: 100%;
  width: 15%;
  pointer-events: none;
`

const SidebarLayoutContentWr = styled.main`
  position: relative;
  width: 85%;
  height: 100%;
  z-index: 1;
`
