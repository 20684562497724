export interface ProjectData {
  // Define the structure of your project data here
}

export async function loadLiveProjectData({ projectId }): Promise<ProjectData> {
  try {
    //Swap this to live data
    const response = await fetch(
      `${
        import.meta.env.VITE_PAYLOAD_SERVER_URL
      }/api/projects/data/${projectId}?depth=3`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    if (!response.ok) {
      throw new Error(`Fetch error! status: ${response.status}`)
    }
    const data: ProjectData = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching static data:", error)
    throw new Error("Failed to load project data")
  }
}
