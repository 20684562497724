import styled from "styled-components"
import { useStore } from "@state/store"
import { useProjectDataStore } from "@state/projectDataStore"

import { poundFormatter } from "../table/TableRow"

const ResidenceInfoCard2 = ({ unit }) => {
  const contentControls = useStore((s) => s.contentControls)
  const residencePopup = useStore((s) => s.residencePopup)

  // this is for compare mode
  if (!Array.isArray(unit)) {
    unit = [unit]
  }

  const selectedUnit = unit[0]
  const { brandingData } = useProjectDataStore((s) => ({
    brandingData: s.brandingData,
  }))

  const bgImage = brandingData?.bgImage?.publicURL

  if (!selectedUnit || contentControls.index === null) return null

  let name = selectedUnit?.unitName
  name = name.split("-")
  let block = name[0]
  name = Number(`${name[1]}${name[2]}`)

  let type = selectedUnit?.unitType
  type = type.split(" ").filter((txt) => txt !== "TYPE")
  type = type.join("").replace("1-STUDIO", "1-ST")

  return (
    <Wrapper
      id="residenceInfoCard"
      bgImage={bgImage}
      style={{
        transform: residencePopup ? "translateX(0%)" : "translate(-100%)",
      }}
    >
      <div className="specsContainer">
        <div className="floor">Floor {selectedUnit?.floor}</div>
        <div className="UnitID">
          <div>UNIT {name}</div>
          <div className="beds">{selectedUnit?.beds} bedroom</div>
        </div>
        <svg
          width="28"
          height="22"
          viewBox="0 0 28 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginBottom: "2rem" }}
        >
          <path
            d="M26.16 0H1L13.86 21.86L26.16 0Z"
            fill="var(--secondary)"
            fillOpacity={0.9}
          />
        </svg>

        <div className="spec coloredSpec">
          <div>Block</div>
          <div className="spec-info">{block}</div>
        </div>
        <div className="spec coloredSpec">
          <div>Price</div>
          <div className="spec-info">
            {poundFormatter.format(selectedUnit?.price)}{" "}
          </div>
        </div>
        <div className="spec coloredSpec">
          <div>Type</div>
          <div className="spec-info">{type}</div>
        </div>
      </div>

      <div className="financialContainer">
        <div className="spec">
          <div>Aspect</div>
          <div className="spec2">{selectedUnit?.aspect} </div>
        </div>
        <div className="spec">
          <div>Price</div>
          <div className="spec2">
            {poundFormatter.format(selectedUnit?.price)}{" "}
          </div>
        </div>
        <div className="spec">
          <div>Beds</div>
          <div className="spec2">{selectedUnit?.beds} </div>
        </div>
        <div className="spec">
          <div>Floor</div>
          <div className="spec2">{selectedUnit?.floor} </div>
        </div>
      </div>
      <div className="copy">
        Each apartment offer stunning views over The City, blending urban
        sophistication with serene surroundings. Experience a lifestyle that
        offers both comfort and convenience in one exceptional location. <br />
        The Shared Ownership studios, 1, and 2-bedroom apartments are located on
        floors two to fourteen of this impressive building. <br />
        The apartments, along with the gardens and park, reflect a commitment to
        social and environmental responsibility, helping you save on bills while
        benefiting the planet. <br />
        Each spacious apartment is designed for comfort and convenience,
        featuring practical details like full- height glass splashbacks, ample
        storage, and built-in wardrobes. With elegant flooring, Juliet
        balconies, and integrated appliances for your gorgeous modern kitchen,
        all that’s left for you to do is move in.
      </div>
    </Wrapper>
  )
}

export default ResidenceInfoCard2

const Wrapper = styled.div<{ bgImage: any }>`
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-blend-mode: soft-light;
  background-color: #fff;
  width: 45%;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  transition: transform 0.4s ease-in-out;
  transform: translateX(-100%);
  font-size: 3.5rem !important;
  color: var(--primary) !important;
  
  .UnitID {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: 1em;
    font-weight: 800;
    margin-bottom: 2.5rem;
    
    .beds {
      font-size: 0.7em;
      font-family: secondaryFont;
      color: var(--secondary);
      text-transform: capitalize;
      letter-spacing: 0.05em;
    }
  }
  
  .floor {
    font-size: 0.3em;
    letter-spacing: 0.2em;
  }
  
  .specsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2rem;
    
    .spec {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: 200;
      letter-spacing: 0.3em;
      font-size: 0.3em;
    }
    
    .coloredSpec {
      color: var(--secondary);
    }
    
    .spec-info {
      font-size: 1.2em;
      letter-spacing: 0.2em;
      font-weight: 800;
    }
  }
  
  .financialContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2rem;
    
    .spec {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: 300;
      letter-spacing: 0.3em;
      font-size: 0.3em;
    }
    
    .spec2 {
      font-size: 1.2em;
      letter-spacing: 0.2em;
      font-weight: 800;
    }
  }
  
  .copy {
    font-size: 0.25em;
    line-height: 1.5em;
    text-transform: none;
    width: 100%;
    text-align: left;
    opacity: 0.5;
    letter-spacing: 0.1em;
    padding: 0 2rem;
    transform: translateY(70%);
  }
`
