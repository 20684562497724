import React from "react"
import gsap from "gsap"
import { Camera, MathUtils } from "three"
import { useThree } from "@react-three/fiber"
import { sphericalCoords } from "./sphericalCoords"
import { useCanvasStore } from "@state/canvasStore"

export const useCamera = (
  canvasReady: any,
  intitalCameraSettings: any,
  onAzimuthPolarChange?: (
    azimuth: number,
    polar: number,
    camera: Camera,
  ) => void,
) => {
  const cameraData = useCanvasStore((s) => s.cameraData)

  const cameraRef = React.useRef<any>(null)
  const controlsRef = React.useRef<any>(null)

  const onChange = (e: any) => {
    const { azimuth, polar } = sphericalCoords(cameraRef.current.position)

    if (onAzimuthPolarChange) {
      onAzimuthPolarChange(azimuth, polar, cameraRef.current)
    }
  }

  React.useEffect(() => {
    if (!canvasReady) return

    const duration = 2

    const controls = controlsRef.current

    gsap.to(controls.target, {
      delay: 0.5,

      x: cameraData.target.x,
      y: cameraData.target.y,
      z: cameraData.target.z,
      duration: duration,
      ease: "power1.inOut",
    })

    gsap.to(cameraRef.current.position, {
      delay: 0.5,
      x: cameraData?.position.x,
      y: cameraData?.position.y,
      z: cameraData?.position.z,
      duration: duration,
      ease: "power1.inOut",
      onUpdate: () => {
        controls.update()
      },
    })
  }, [cameraData, canvasReady])

  return {
    cameraRef,
    controlsRef,
    onChange,
    cameraData,
  }
}
