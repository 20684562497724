import { Spherical } from "three"

export const sphericalCoords = (position) => {
  const spherical = new Spherical().setFromVector3(position)
  let thetaRadians = spherical.theta
  let phiRadians = spherical.phi

  const azimuth = (thetaRadians * 180) / Math.PI
  const polar = (phiRadians * 180) / Math.PI

  return {
    azimuth,
    polar,
  }
}
