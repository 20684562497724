import React, { useEffect, useRef, useState } from "react"
import gsap from "gsap"
import { useMapStore } from "@common/features/GoogleMaps/mapStore"
import {
  calculateAndDisplayRoute,
  getUpdatedMapStyle,
} from "@lib/utils/google.maps"
import { useStore } from "@state/store"
import SearchBar from "./SearchBar"

type MapCustomColors = {
  landscape?: string
  water?: string
  parks?: string
  labels?: string
}

export type POI = {
  iconPath: any
  category?: string | null
  place: string
  description?: string
  size: number
  coordinates: { lat: number; lng: number }
}

type MapProps = {
  map: google.maps.Map | null
  directionsRenderer: google.maps.DirectionsRenderer | null
  mapRef: React.RefObject<HTMLDivElement>
  panelRef?: React.RefObject<HTMLDivElement>
  setPlaces: (places: any[]) => void
  categories: string[]
  mapSettings: {
    zoom: number
    center: { lat: number; lng: number }
    unitSystem?: "IMPERIAL" | "METRIC"
  }
  mapStyling: {
    styleKey?: string
    style?: any[]
    customColors?: MapCustomColors
  }
  mapPOIS: {
    mainMarker: POI
    pois: any
    poiSettings: {
      poiVisibility: "hideOthers" | "scaleActive"
      poiIcon: {
        url?: string
        publicURL?: string
      }
    }
  }
}

const calculateDistances = async (
  pois: any, // Can be an array of POIs or a single POI
  mainMarker: any,
  unitSystem: string,
  distRef: any,
  setPlaces?: any, // Cb to set the places with the distances for sidebar
) => {
  const service = new google.maps.DistanceMatrixService()

  const travelModes = [
    google.maps.TravelMode.WALKING,
    google.maps.TravelMode.DRIVING,
    google.maps.TravelMode.BICYCLING,
    google.maps.TravelMode.TRANSIT,
  ]

  const poiArray = Array.isArray(pois) ? pois : [pois]

  if (poiArray.length === 0) {
    return
  }

  const distanceResults: any = poiArray.map((poi: POI) => ({
    name: poi.place,
    description: poi.description,
    category: poi.category,
    coordinates: poi.coordinates,
    distances: {},
  }))

  for (const mode of travelModes) {
    service.getDistanceMatrix(
      {
        origins: [mainMarker.coordinates],
        destinations: poiArray.map((poi: any) => poi.coordinates),
        travelMode: mode,
        avoidHighways: true,
        unitSystem: google.maps.UnitSystem[unitSystem || "IMPERIAL"],
      },
      (response: any, status: any) => {
        if (status === "OK") {
          response.rows[0].elements.forEach((element: any, index: number) => {
            distanceResults[index].distances[mode] = {
              distance: element?.distance.text,
              duration: element?.duration.text,
            }
            // distRef is a bit of a hack to pass the distances to the activePOI
            distRef.current = distanceResults[index].distances
          })

          // If calculating distances for multiple POIs, update the places
          if (setPlaces) {
            if (
              distanceResults.every(
                (result: any) =>
                  Object.keys(result.distances).length === travelModes.length,
              )
            ) {
              setPlaces(distanceResults)
            }
          }
        } else {
          console.error("Error with distance matrix service:", status)
        }
      },
    )
  }

  // If calculating for a single POI, return the result directly
  if (!Array.isArray(pois)) {
    return distanceResults[0]
  }
}

const Map = (props: MapProps) => {
  const { categories, map, directionsRenderer } = props
  const { mainMarker, pois } = props.mapPOIS
  const { unitSystem } = props.mapSettings
  const { poiVisibility } = props.mapPOIS?.poiSettings
  const contentControls = useStore((s) => s.contentControls)
  const currentCategory = categories[contentControls?.index]

  const {
    travelMode,
    transitMode,
    activePOI,
    mapInstance,
    setActivePOI,
    setRouteSteps,
  } = useMapStore((state) => ({
    travelMode: state.travelMode,
    transitMode: state.transitMode,
    activePOI: state.activePOI,
    mapInstance: state.mapInstance,
    setActivePOI: state.setActivePOI,
    setRouteSteps: state.setRouteSteps,
  }))

  const markersRef = useRef<{
    [key: string]: google.maps.marker.AdvancedMarkerElement
  }>({})

  const updatedMapStyle = getUpdatedMapStyle(
    props.mapStyling?.style,
    props.mapStyling?.customColors,
  )
  // distRef is a bit of a hack to pass the distances to the activePOI
  // this is because I want to show the distances in the sidebar
  // when the user clicks on a POI on the map
  const distRef = useRef<any>({})
  const currentPOIRef = useRef<any>(null)
  const opacityMarker = {
    value: 0.01,
  }

  useEffect(() => {
    const updateColors = async () => {
      if (map) {
        const styledMapType = new google.maps.StyledMapType(updatedMapStyle, {
          name: "Styled Map",
        })
        map.mapTypes.set("styled_map", styledMapType)
        map.setMapTypeId("styled_map")
      }
    }

    updateColors()
  }, [updatedMapStyle, map])

  useEffect(() => {
    const updateMarkers = async () => {
      if (map) {
        const { AdvancedMarkerElement } = (await google.maps.importLibrary(
          "marker",
        )) as google.maps.MarkerLibrary

        const filteredPOIs = pois.filter(
          (poi) =>
            currentCategory === "All" || poi.category === currentCategory,
        )

        // Create or update markers based on the filtered POIs
        filteredPOIs.forEach((poi, i) => {
          let marker = markersRef.current[poi.place]
          if (!marker) {
            const element = SVGPoiMarker(i, poi.place)

            marker = new AdvancedMarkerElement({
              map,
              title: poi.place,
              position: poi.coordinates,
              content: element,
              gmpClickable: true,
            })

            marker.addListener("gmp-click", () => {
              const poiOBJ = {
                name: poi.place,
                description: poi.description,
                category: poi.category || "",
                coordinates: poi.coordinates,
                // distRef is a bit of a hack to pass the distances to the activePOI
                // this is because I want to show the distances in the sidebar
                // when the user clicks on a POI on the map
                distances: distRef.current,
              }
              if (currentPOIRef.current === null) {
                currentPOIRef.current = poiOBJ
              } else {
                if (currentPOIRef.current.name === poiOBJ.name) {
                  currentPOIRef.current = null
                } else {
                  currentPOIRef.current = poiOBJ
                }
              }
              setActivePOI(currentPOIRef.current)
            })
            markersRef.current[poi.place] = marker
          }

          // const markerElement = marker.content as HTMLImageElement

          // if (poiVisibility === "scaleActive") {
          //   if (activePOI && poi.place === activePOI.name) {
          //     markerElement.style.transform = "scale(1.3)"
          //   } else {
          //     markerElement.style.transform = "scale(1)"
          //   }
          //   markerElement.style.transition = "transform 200ms ease-in"
          // } else if (poiVisibility === "hideOthers") {
          //   if (activePOI && poi.place !== activePOI.name) {
          //     markerElement.style.opacity = "0"
          //     markerElement.style.transition = "opacity 200ms ease-in"
          //     // Force reflow to ensure the transition is applied
          //     markerElement.getBoundingClientRect()
          //     setTimeout(() => {
          //       markerElement.style.opacity = "0"
          //     }, 400) // delay hiding element until transition completes
          //   } else {
          //     markerElement.style.opacity = "1"
          //     markerElement.style.transform = "scale(1)"
          //     markerElement.style.display = "block"
          //     markerElement.style.transition = "opacity 200ms ease-in"
          //   }
          // }
        })

        // Remove markers not in the filteredPOIs
        Object.keys(markersRef.current).forEach((key) => {
          if (!filteredPOIs.find((poi) => poi.place === key)) {
            const marker = markersRef.current[key]
            marker.map = null
            delete markersRef.current[key]
          }
        })

        await calculateDistances(
          pois,
          mainMarker,
          unitSystem,
          distRef,
          props.setPlaces,
        )
      }
    }
    updateMarkers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCategory,
    map,
    pois,
    mainMarker.coordinates,
    setActivePOI,
    activePOI,
  ])

  useEffect(() => {
    if (map && directionsRenderer && activePOI) {
      const directionsService = new google.maps.DirectionsService()
      calculateAndDisplayRoute(
        activePOI.coordinates,
        mainMarker.coordinates,
        travelMode,
        transitMode,
        directionsService,
        directionsRenderer,
        mapInstance,
        setRouteSteps,
      )
    }
    if (activePOI === null && directionsRenderer && mapInstance) {
      gsap.fromTo(
        opacityMarker,
        {
          value: 1,
        },
        {
          value: 0.01,
          duration: 0.4,
          onUpdate: () => {
            directionsRenderer.setOptions({
              polylineOptions: {
                //@ts-ignore
                ...directionsRenderer?.polylineOptions,
                strokeOpacity: opacityMarker.value,
              },
            })
            directionsRenderer.setMap(mapInstance)
          },
          onComplete: () => {
            setTimeout(() => {
              directionsRenderer?.setMap(null)
            }, 100)
          },
        },
      )
    }
    if (currentMarkerRef.current && !activePOI) {
      gsap.to(currentMarkerRef.current, {
        opacity: 0,
        duration: 0.4,
        delay: 0.4,
        onComplete: () => (currentMarkerRef.current.map = null),
      })
    }
  }, [
    map,
    directionsRenderer,
    travelMode,
    transitMode,
    setRouteSteps,
    activePOI,
  ])

  const currentMarkerRef =
    useRef<google.maps.marker.AdvancedMarkerElement | null>(null)

  const setCustomSearchPOI = async (poi: any) => {
    let marker = null

    const customPOI = await calculateDistances(
      poi,
      mainMarker,
      unitSystem,
      distRef,
    )

    setActivePOI(customPOI)

    if (map) {
      const { AdvancedMarkerElement } = (await google.maps.importLibrary(
        "marker",
      )) as google.maps.MarkerLibrary

      // Remove the old marker if it exists
      if (currentMarkerRef.current) {
        currentMarkerRef.current.map = null
      }

      const markerElement = document.createElement("img")
      markerElement.src = poi?.image
      markerElement.width = 50
      markerElement.height = 50
      markerElement.style.borderRadius = "50%"
      markerElement.style.border = "5px solid var(--accent)"
      markerElement.classList.add("marker")

      marker = new AdvancedMarkerElement({
        map,
        title: poi.place,
        position: poi.coordinates,
        content: markerElement,
      })

      // we need to store the marker in a ref so we can remove it
      // when we set a new custom search POI
      currentMarkerRef.current = marker
    }
  }

  useEffect(() => {
    const targets = gsap.utils.toArray("[data-map-poi]")
    if (targets.length === 0) return
    targets.forEach((node: HTMLElement) => {
      const tag = node.tagName
      const value = node.getAttribute("data-map-poi")
      const current = activePOI?.name ?? null
      const active = value === current
      const duration = 0.8
      switch (tag) {
        case "text":
          gsap.to(node, {
            duration,
            fill: active ? "#25303b" : "#FFF",
          })
          return
        case "rect":
          gsap.to(node, {
            duration,
            fill: active ? "#ebb3f7" : "#25303b",
            fillOpacity: active ? 1 : 0.6,
            stroke: active ? "#25303b" : "#FFF",
          })
          return
        case "polyline":
          gsap.to(node, {
            duration,
            stroke: active ? "#25303b" : "transparent",
          })
          return
        default:
          return
      }
    })
  }, [activePOI])

  return (
    <>
      {map && (
        <SearchBar
          mainMarker={mainMarker}
          onResultItemClick={setCustomSearchPOI}
        />
      )}
      <div id="map" ref={props.mapRef} />
    </>
  )
}

export default Map

function SVGPoiMarker(i, id) {
  const bgColor = getComputedStyle(document.body).getPropertyValue("--primary")
  const strokeColor = "#FFF"
  const width = 50
  const margin = width / 5
  const svgNS = "http://www.w3.org/2000/svg"

  const mainMarkerElement = document.createElementNS(svgNS, "svg")
  mainMarkerElement.style.position = "absolute"
  mainMarkerElement.style.transform = "translateY(50%)"
  mainMarkerElement.setAttribute("width", "100%")
  mainMarkerElement.setAttribute("height", "100%")
  mainMarkerElement.setAttribute("viewBox", `0 0 ${width} ${width}`)
  mainMarkerElement.setAttribute("preserveAspectRatio", "xMidYMid meet")

  const rectangle = document.createElementNS(svgNS, "rect")
  rectangle.setAttribute("width", `${width - margin}`)
  rectangle.setAttribute("height", `${width - margin}`)
  rectangle.setAttribute("x", `${margin / 2}`)
  rectangle.setAttribute("y", `${margin / 2}`)
  rectangle.setAttribute("fill", bgColor)
  rectangle.setAttribute("stroke", strokeColor)
  rectangle.setAttribute("stroke-width", `${margin * 0.1}`)
  rectangle.setAttribute("fill-opacity", "0.6")
  rectangle.setAttribute("data-map-poi", id)
  mainMarkerElement.appendChild(rectangle)

  const borderOne = document.createElementNS(svgNS, "polyline")
  borderOne.setAttribute("points", `1,${margin * 2} 1,1 ${margin * 2},1`)
  borderOne.setAttribute("stroke", "transparent")
  borderOne.setAttribute("stroke-width", `${margin * 0.1}`)
  borderOne.setAttribute("fill", "none")
  borderOne.setAttribute("data-map-poi", id)
  mainMarkerElement.appendChild(borderOne)

  const borderTwo = document.createElementNS(svgNS, "polyline")
  borderTwo.setAttribute(
    "points",
    `${width - margin * 2},${width - 1} ${width - 1},${width - 1} ${
      width - 1
    },${width - margin * 2}`,
  )
  borderTwo.setAttribute("stroke", "transparent")
  borderTwo.setAttribute("stroke-width", `${margin * 0.1}`)
  borderTwo.setAttribute("fill", "none")
  borderTwo.setAttribute("data-map-poi", id)
  mainMarkerElement.appendChild(borderTwo)

  const text = document.createElementNS(svgNS, "text")
  text.setAttribute("x", `${width / 2}`)
  text.setAttribute("y", `${width / 2}`)
  text.setAttribute("fill", strokeColor)
  text.setAttribute("font-size", `${width * 0.4}`)
  text.setAttribute("text-anchor", "middle")
  text.setAttribute("dominant-baseline", "central")
  text.innerHTML = i + 1
  text.setAttribute("data-map-poi", id)
  mainMarkerElement.appendChild(text)

  const markerWrapper = document.createElement("div")
  markerWrapper.style.position = "relative"
  markerWrapper.style.width = `${width}px`
  markerWrapper.style.height = `${width}px`
  markerWrapper.style.position = "relative"
  markerWrapper.appendChild(mainMarkerElement)
  return markerWrapper
}
