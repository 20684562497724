import styled from "styled-components/macro"

const SpecsImage = ({ unitData }) => {
  //TODO Impliment new spec database/payload logic
  // const residenceStyleIndex = useStore((s) => s.residenceStyleIndex)
  // const selectedUnit = useStore((s) => s.selectedUnit)

  // const style1 = unitData.specs.value.SpecData[0].specs[0].style1.url
  // const style2 = unitData.specs.value.SpecData[0].specs[0].style2.url

  // useEffect(() => {
  //   gsap.to(".style2", {
  //     autoAlpha: residenceStyleIndex === 1 ? 1 : 0,
  //     duration: 0.3,
  //   })
  // }, [residenceStyleIndex])

  return (
    <Wrapper>
      <div className="style">
        {/* <img className="style1" src={style1} alt={style1} /> */}
        {/* <img className="style2" src={style2} alt={style2} /> */}
      </div>
    </Wrapper>
  )
}

export default SpecsImage

const Wrapper = styled.div`
  width: 60%;
  height: 100%;
  position: relative;

  .style {
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .style2 {
      opacity: 0;
    }
  }
`
