import { useEffect, useRef, useState } from "react"
import styled from "styled-components/macro"
import { EffectFade, Navigation, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/pagination"

const InfoCarousel = ({ info1Data }) => {
  const swiperRef = useRef(null)
  const [currentInfoIndex, setCurrentInfoIndex] = useState(0)

  return (
    <Wrapper>
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper
        }}
        effect="fade"
        className="bgImage"
        spaceBetween={0}
        slidesPerView={1}
        centeredSlides={true}
        onSlideChange={(_swiper) => setCurrentInfoIndex(_swiper.activeIndex)}
        fadeEffect={{ crossFade: true }}
        modules={[EffectFade]}
        speed={1000}
        style={{ height: "100%", width: "100%" }}
      >
        {info1Data?.block.map((content, index) => (
          <SwiperSlide
            key={content?.img?.publicURL + index?.toString()}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt={content?.content?.filename}
              src={content?.img?.publicURL}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="copy">
        <div className="mainText">
          <div className="headline">
            {info1Data?.block[currentInfoIndex]?.headline}
          </div>
          <div className="body">{info1Data?.block[currentInfoIndex]?.body}</div>
        </div>
      </div>
      <div className="controls">
        <div className="button">
          <div
            onClick={() => {
              if (currentInfoIndex > 0)
                setCurrentInfoIndex(currentInfoIndex - 1)
              swiperRef.current.slideTo(currentInfoIndex - 1)
            }}
          >
            {" "}
            <img src="/assets/icons/back.svg" alt="" />
          </div>
          <div
            onClick={() => {
              if (currentInfoIndex + 1 !== info1Data.block.length)
                setCurrentInfoIndex(currentInfoIndex + 1)
              swiperRef.current.slideTo(currentInfoIndex + 1)
            }}
          >
            <img
              src="/assets/icons/back.svg"
              style={{ transform: "rotate(180deg)" }}
              alt=""
            />
          </div>
        </div>
        <div className="timeline">
          {info1Data.block.map((headline, index) => (
            <div
              onClick={() => {
                setCurrentInfoIndex(index), swiperRef.current.slideTo(index)
              }}
              className={index === currentInfoIndex ? "active" : ""}
            >
              {headline.headline}
            </div>
          ))}
        </div>
        <div className="index">
          {currentInfoIndex + 1}/{info1Data.block.length}
        </div>
      </div>
    </Wrapper>
  )
}

export default InfoCarousel

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .bgImage {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .copy {
    width: 90%;
    margin-left: auto;
    height: 85%;
    border-left: 1px solid var(--accent);
    padding: 2.75rem;
    display: flex;
    align-items: center;
    color: white;
    z-index: 1;
    pointer-events: none;

    .mainText {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .headline {
        font-size: 4rem;
      }

      .body {
        font-size: 1.25rem;
        line-height: 25px;
      }
    }
  }

  .controls {
    width: 100%;
    height: 15%;
    border-top: 1px solid var(--accent);
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 5rem;
    z-index: 1;

    .button {
      display: flex;
      gap: 1rem;
      cursor: pointer;

      div {
        background-color: #00000035;
        padding: 1rem;
        color: #fff;
        width: 4rem;
        height: 4rem;
        img {
          width: 100%;
          height: 100%;
          padding: 0.5rem;
        }
        &:active {
          opacity: 0.75;
          transform: scale(0.95);
          transition: 0.3s;
        }
      }
    }

    .timeline {
      display: flex;
      color: #ffffff73;
      gap: 2rem;
      transition: 0.3s;
      cursor: pointer;

      .active {
        color: white;
        transition: 0.3s;
      }
    }

    .index {
      font-size: 2rem;
      color: white;
      letter-spacing: 5px;
    }
  }
`
