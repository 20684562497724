import { updateDocumentStyles } from "@lib/utils/setDocumentStyles"

export function findAndCleanPageValue(pages: any[], relationTo: string) {
  const page = pages.find((page) => page.relationTo === relationTo)
  if (!page || !page.value) return null

  const { createdAt, id, relations, updatedAt, ...cleanedValue } = page.value
  return { relationTo, ...cleanedValue }
}

export function updateProjectDataStore(data: any, setLocalState) {
  // Update page-specific data
  const pages = data.pages || []

  const navigationPage = pages.find((page) => page.relationTo === "navigation")
  const layout = navigationPage?.value?.layout || "fullscreen"

  const brandingData = findAndCleanPageValue(pages, "branding")

  // Update document styles with branding data
  updateDocumentStyles(brandingData)

  setLocalState({
    orbitData: findAndCleanPageValue(pages, "orbits"),
    brandingData: findAndCleanPageValue(pages, "branding"),
    navigationData: findAndCleanPageValue(pages, "navigation"),
    explorerData: findAndCleanPageValue(pages, "explorerPage"),
    favouritesData: findAndCleanPageValue(pages, "favourites"),
    galleryData: findAndCleanPageValue(pages, "gallery"),
    mapData: findAndCleanPageValue(pages, "map"),
    residencesData: findAndCleanPageValue(pages, "residences"),
    splashData: findAndCleanPageValue(pages, "splash"),
    availabilityData: findAndCleanPageValue(pages, "unitAvailability"),
    typeData: findAndCleanPageValue(pages, "unitTypes"),
    viewsData: findAndCleanPageValue(pages, "unitViews"),
    detailsData: findAndCleanPageValue(pages, "unitDetails"),
  })
}
