import styled from "styled-components/macro"

const AboutPage = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default AboutPage

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: var(--primary);

  .aboutCategories {
    position: absolute;
    bottom: 0;
    margin: 2rem;
    display: flex;
    border: 1px solid white;

    .buttons {
      background-color: transparent;
      color: white;
      padding: 1.5rem 4rem;
      border: 1px solid white;
      font-family: PbdyPrimary;
    }

    .active {
      background-color: white;
      color: var(--peabodyGray);
    }
  }
`
