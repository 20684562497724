import React from "react"
import { useNavigate } from "react-router-dom"

export default function SplashEnter({
  width = 10,
  icon = "assets/icons/up-arrow.svg",
  buttonBgColour = "var(--primary)",
  ...props
}) {
  const navigate = useNavigate()
  return (
    <div
      style={{
        width: `${width}rem`,
        aspectRatio: 1,
        border: `1px solid var(--${buttonBgColour})`,
        borderRadius: "100%",
        display: "grid",
        placeSelf: "center",
        placeItems: "center",
      }}
      {...props}
    >
      {icon && (
        <svg
          onClick={() => navigate("/residences")}
          style={{
            transform: "rotate(90deg)",
            width: "50%",
            cursor: "pointer",
            pointerEvents: "auto",
          }}
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="50%"
          height="50%"
          x="0"
          y="0"
          viewBox="0 0 24 24"
          fillRule="evenodd"
        >
          <g>
            <path
              d="m11 5.414-5.293 5.293a1 1 0 0 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7a1 1 0 0 1-1.414 1.414L13 5.414V21a1 1 0 0 1-2 0z"
              fill={`var(--${buttonBgColour})`}
            ></path>
          </g>
        </svg>
      )}
    </div>
  )
}
