import { useProjectDataStore } from "@state/projectDataStore"
import React from "react"
import styled from "styled-components/macro"

interface Props {
  onPointerUp?: (event: React.PointerEvent<HTMLImageElement>) => void
  className?: string
  width?: number
  style?: {}
}

function Logo({ className, width = 12.25, onPointerUp, ...props }: Props) {
  const { brandingData, navigationData } = useProjectDataStore((s) => ({
    brandingData: s.brandingData,
    navigationData: s.navigationData,
  }))

  const logoType = navigationData?.logo
  const primaryLogo = brandingData?.logos[logoType]
  const isButton = onPointerUp !== undefined
  return (
    <>
      <LogoWrapper
        onPointerUp={onPointerUp}
        className={className}
        isButton={isButton}
        $width={width}
        {...props}
      >
        <img src={primaryLogo?.publicURL} alt="logo" />
      </LogoWrapper>
    </>
  )
}

export default Logo

const LogoWrapper = styled.div<{ isButton: boolean; $width: number }>`
  display: flex;
  justify-content: center;
  height: auto;
  width: ${(p) => p.$width}rem;

  img {
    max-width: 200px;
    width: 100%;
    height: 100%;
    cursor: ${({ isButton }) => (isButton ? "pointer" : "default")};
    transition: 0.3s;
    margin: auto;

    &:active {
      transform: scale(0.975);
      opacity: 0.5;
      transition: 0.3s;
    }
  }
`
