import styled from "styled-components/macro"
import { useProjectDataStore } from "@state/projectDataStore"
import { useStore } from "@state/store"

const ResidenceInfoCard = ({ unit }) => {
  const contentControls = useStore((s) => s.contentControls)
  if (!unit || contentControls.index === null) return null
  const selectedUnit = unit[0]
  return (
    <Wrapper id="residenceInfoCard">
      <div className="specsContainer">
        <div className="spec">
          <div>{selectedUnit?.beds} Bed</div>
          <div> Floor {selectedUnit?.floor}</div>
        </div>
        <div className="UnitID">
          <div>Apartment {selectedUnit?.unitName}</div>
        </div>

        <div className="spec">
          <div>Price</div>
          <div className="spec2">{selectedUnit?.price} </div>
        </div>
        <div className="spec">
          <div>Availability</div>
          <div className="spec2">{selectedUnit?.status} </div>
        </div>
      </div>
      <div className="specsContainer">
        <div className="spec">
          <div>Bedrooms</div>
          <div className="spec2">{selectedUnit?.beds} </div>
        </div>
        <div className="spec">
          <div>Bathrooms</div>
          <div className="spec2">{selectedUnit?.baths} </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default ResidenceInfoCard

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid var(--accent);
  border-left: 1px solid var(--accent);
  margin-left: 10%;

  .UnitID {
    display: flex;
    font-size: 4rem;
    font-weight: 400;
    margin-bottom: 4rem;
  }

  .specsContainer {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;

    &:nth-child(2) {
    }

    .spec {
      width: 100%;
      display: flex;
      gap: 1rem;
      font-size: 1rem;
    }
  }

  .spec2 {
    margin-left: auto;
    letter-spacing: 5px;
    font-size: 1rem;
  }
`
