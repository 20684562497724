import Logo from "@common/ui/Logo"
import { useProjectDataStore } from "@state/projectDataStore"
import { useNavigate } from "react-router-dom"

const ErrorScreen = ({ error }) => {
  return (
    <div
      style={{
        fontSize: "1.5rem",
        fontWeight: "bold",
        color: "var(--primary)",
        background: `var(--secondary)`,
        border: "1px solid white",
        padding: "10px",
        borderRadius: "5px",

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div> {error}</div>
    </div>
  )
}

export default ErrorScreen
