import TextButton from "@common/ui/TextButton"
import { Cross2Icon, CheckIcon } from "@radix-ui/react-icons"

type Props = {
  onConfirm: () => void
}

const Prompt = (props: Props) => {
  const { onConfirm } = props
  return (
    <div className="prompt">
      <TextButton
        color="#fff"
        variant="outline"
        icon={<Cross2Icon height={20} width={20} color="#fff" />}
        style={{
          margin: 0,
          padding: "1em",
          width: "max-content",
        }}
        onClick={() =>
          // @ts-ignore
          document.querySelector(".prompt").classList.toggle("active")
        }
      >
        No
      </TextButton>
      <TextButton
        color="#fff"
        variant="outline"
        icon={<CheckIcon height={20} width={20} color="#fff" />}
        style={{
          margin: 0,
          padding: "1em",
          width: "max-content",
        }}
        onClick={onConfirm}
      >
        Yes
      </TextButton>
    </div>
  )
}

export default Prompt
