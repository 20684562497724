import styled from "styled-components/macro"

function Development({ children }) {
  return <Wrapper>{children}</Wrapper>
}

export default Development

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .pdf-document {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pdf-page {
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b3b3b3;
  }

  .pdf-page-controls {
    position: absolute;
    bottom: 5em;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
`
