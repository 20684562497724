import { useState, useRef, useEffect } from "react"
import gsap from "gsap"

export function useGPUSaver(
  pathname: string,
  renderCanvasOn: string[],
  timeout: number,
) {
  const [frameLoop, setFrameLoop] = useState<"always" | "demand" | "never">(
    "always",
  )
  const [pointerLock, setPointerLock] = useState(false)

  const showMapScreenSaver = useScreenTimeout(timeout, [pointerLock, pathname])

  const gpuSaverRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setPointerLock(true)
  }, [pathname])

  useEffect(() => {
    if (showMapScreenSaver) {
      gsap.to(gpuSaverRef.current, {
        onEnd: () => {
          setFrameLoop("demand")
        },
        delay: 0.5,
        autoAlpha: 1,
        duration: 0.5,
      })
    } else {
      gsap.to(gpuSaverRef.current, {
        autoAlpha: 0,
        duration: 0.5,
        onEnd: () => {
          setFrameLoop("always")
        },
      })
    }
  }, [showMapScreenSaver])

  // Function to handle pointer down event
  const handlePointerDown = () => {
    // console.log(frameLoop)
    setPointerLock(true)
  }

  // Function to handle pointer up event
  const handlePointerUp = () => {
    setPointerLock(false)
  }

  // Function to handle resume (user interaction to dismiss screen saver)
  const handleResume = () => {
    setFrameLoop("always")
    setPointerLock(false)
  }

  return {
    gpuSaverRef,
    frameLoop,
    pointerLock,
    handlePointerDown,
    handlePointerUp,
    handleResume,
    showMapScreenSaver,
    shouldRenderCanvas: renderCanvasOn.includes(pathname),
  }
}

export const useScreenTimeout = (delay: number, state: any) => {
  const [showMapScreenSaver, setSetShowMapScreenSaver] = useState(false)

  useEffect(() => {
    setSetShowMapScreenSaver(false)
    const timer = setTimeout(
      () => {
        setSetShowMapScreenSaver(true)
      },

      delay,
    )

    return () => {
      clearTimeout(timer)
    }
  }, state)

  return showMapScreenSaver
}

export const useRenderLoop = (frameLoop: string) => {}
