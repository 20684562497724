import { Vector3 } from "three"
import { create } from "zustand"
import { subscribeWithSelector } from "zustand/middleware"

interface CanvasStore {
  cameraData: {
    position: Vector3
    target: Vector3
  }

  setCameraData: (cameraData: { position?: Vector3; target?: Vector3 }) => void

  camera: any
  setCamera: (camera: any) => void

  polar: number
  azimuth: number

  setSpherical: (spherical: { polar: number; azimuth: number }) => void

  distance: number
  setDistance: (distance: number) => void

  canvasReady: boolean
  setCanvasReady: (canvasReady: boolean) => void

  speed: number
  setSpeed: (speed: number) => void

  touchPoints: any[]
  setTouchPoints: (touchPoints: any[]) => void
}

export const useCanvasStore = create(
  subscribeWithSelector<CanvasStore>((set) => ({
    canvasReady: false,
    setCanvasReady: (canvasReady) => set({ canvasReady }),
    cameraData: {
      position: new Vector3(20, 20, 20),
      target: new Vector3(20, 20, 20),
    },

    setCameraData: (cameraData) =>
      set((state) => ({
        cameraData: {
          ...state.cameraData,
          ...cameraData,
        },
      })),
    camera: null,
    setCamera: (camera) => set({ camera }),

    polar: 0,
    azimuth: 0,

    distance: 0,
    setDistance: (distance) => set({ distance }),

    speed: 0,
    setSpeed: (speed) => set({ speed }),

    setSpherical: (spherical) =>
      set({ polar: spherical.polar, azimuth: spherical.azimuth }),

    touchPoints: [],
    setTouchPoints: (touchPoints) => set({ touchPoints }),
  })),
)
