import styled from "styled-components/macro"

const steps = [
  {
    id: 1,
    title: "Register",
    description: {
      p1: "Once you have found a place you love, get in touch with the Peabody New Homes team by completing an application form to let us know you're interested.",
    },
  },
  {
    id: 2,
    title: "Assess",
    description: {
      p1: "Before you can view any homes, we'll need to make sure you meet all the criteria.",
      p2: "We'll arrange for you to have an initial assessment with an Independent Mortgage Advisor (IMA) to understand a bit more about your financial position and whether Shared Ownership is the best option for you.",
    },
  },
  {
    id: 3,
    title: "Reserve",
    description: {
      p1: "Once the show home is ready, we will invite you to book a viewing either in person or online from the comfort of your own home, whatever suits you best. ",
      p2: "You can reserve a home for £500 and choose a selection of homes to give you a higher chance of being offered a home if we receive more than one reservation for any single property.",
    },
  },
  {
    id: 4,
    title: "Offer",
    description: {
      p1: "Within seven days of making a reservation we will confirm whether we're able to offer you a Shared Ownership home with an offer letter. ",
      p2: "If we receive more than one reservation for the home, we will make a decision based on who best meets the eligibility criteria and the minimum affordability requirements.",
    },
  },
  {
    id: 5,
    title: "Meet",
    description: {
      p1: "Within five days of being offered a property, you’ll be invited to meet an Independent Mortgage Advisor (IMA) from our selected panel of experts (but you are under no obligation to use them).",
      p2: "They’ll do a more in-depth check to make sure you’re able to afford the home and double-check you meet the eligibility criteria set out on page 16. You'll need to bring some ID, bank statements and payslips",
    },
  },
  {
    id: 6,
    title: "Instruct",
    description: {
      p1: "You’ll need to formally instruct a solicitor and mortgage advisor on the day of your financial assessment. You can choose from our selected panel or appoint your own. Some Mortgage Advisors will offer this service free of charge while others charge a small fee, so please check before choosing.",
      p2: "Once you've done this, you'll need to complete an Offer Acceptance Form. At this stage your reservation fee will be non-refundable up to completion.",
    },
  },
  {
    id: 7,
    title: "Secure",
    description: {
      p1: "Submit a mortgage application within two working days of your financial assessment. If you’re going directly to a lender, you might need an appointment before this to meet the timescale. An independent valuation of your new home will be carried out on behalf of the mortgage lender.",
    },
  },
  {
    id: 8,
    title: "Sign",
    description: {
      p1: "The Peabody New Homes solicitor will be in communication with your solicitor to provide legal paperwork and respond to any legal enquiries. Once we receive an Offer Acceptance Form, we will send a Memorandum of Sale to your solicitors.",
      p2: "After all legal enquiries have been satisfied, your solicitor will contact you to arrange exchange of contracts. Upon exchange, you're legally bound to buy the home and Peabody New Homes is legally required to sell it to you. This is usually within six weeks of you being offered the home, or within five working days of receiving your mortgage offer (whichever date is earliest).",
    },
  },
]

const SharedOwnership = (props: any) => {
  return (
    <TimelineWrapper>
      <img
        className="title"
        src="/assets/icons/shared-ownership/title.svg"
        alt="Title"
      />
      <div className="timeline">
        {steps.map((step, index) => (
          <Step key={step.id}>
            <img
              src={`/assets/icons/shared-ownership/${index + 1}.svg`}
              alt="Icon"
            />
            <span className="arrow"></span>
            <div className="step-wrapper">
              <div className="circle">
                <span className="step-number">{step.id}</span>
              </div>
            </div>
            <div className="text">
              <h4>{step.title}</h4>
              {step.description.p1 && <p>{step.description.p1}</p>}
              {step.description.p2 && <p>{step.description.p2}</p>}
            </div>
          </Step>
        ))}
      </div>
      <img
        className="flower"
        src="/assets/icons/shared-ownership/flower.svg"
        alt="Flower"
      />
    </TimelineWrapper>
  )
}

export default SharedOwnership

const TimelineWrapper = styled.div`
  --circle-size: 3em;
  width: 100%;
  overflow-x: auto;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 2;

  &::-webkit-scrollbar {
    height: 3px;
  }

  .title {
    position: absolute;
    top: 10em;
    left: 2em;
  }

  .flower {
    position: absolute;
    bottom: 3px;
    left: 0;
    z-index: 0;
    width: 40%;
  }

  .timeline {
    margin-top: 15%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4em;
    position: relative;
    padding: 0 5em;

    &::after {
      content: "";
      position: absolute;
      top: calc(var(--circle-size) / 2);
      left: 0;
      right: 0;
      height: 1px;
      width: 100%;
      background-color: var(--peabodyTeal);
      z-index: 0;
    }
  }
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  min-width: 25em;
  position: relative;
  z-index: 1;

  img {
    width: 60px;
    height: 60px;
    position: absolute;
    top: -80px;
    left: 0;
  }

  .arrow {
    position: absolute;
    top: 0.8em;
    left: calc(50% + 2em);
    transform: translateX(-50%);
    width: 1em;
    height: 1em;
    border-right: 1px solid var(--peabotyTeal);
    border-bottom: 1px solid var(--peabotyTeal);
    transform: rotate(-45deg);
    background: none;
  }

  .step-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    .circle {
      width: var(--circle-size);
      height: var(--circle-size);
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid var(--peabodyTeal);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      z-index: 1;

      .step-number {
        color: var(--peabodyTeal);
        font-size: 1.4rem;
      }
    }
  }

  .text {
    margin-top: 20px;
    h4 {
      font-size: 1.3rem;
      margin-bottom: 10px;
      color: var(--peabodyTeal);
    }
    p {
      font-size: 1rem;
      color: var(--peabodyGray);
      line-height: 1.5rem;
      margin-bottom: 2em;
    }
  }
`
