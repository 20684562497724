import styled from "styled-components"
import { FlexTable } from "@common/components/table/FlexTable"
import { useStore } from "@state/store"
import useSortConfig from "@common/hooks/useSortConfig"
import { useEffect } from "react"
import ResidencePopup2 from "@common/components/westHam/ResidencePopup2"
import { useProjectDataStore } from "@state/projectDataStore"
import TextButton from "@common/ui/TextButton"
import Filters from "@common/components/ResidenceGeneric/Filters"
import { cameraPositionData } from "@data/cameraData"
import { useCanvasStore } from "@state/canvasStore"

const ResidencesType1 = () => {
  const { residencesData } = useProjectDataStore((s) => ({
    residencesData: s.residencesData,
    availabilityData: s.availabilityData,
    setLocalState: s.setLocalState,
  }))

  const setCameraData = useCanvasStore((s) => s.setCameraData)

  const selectedUnit = useStore((s) => s.selectedUnit)

  const { sortConfig, setSortConfig } = useSortConfig()
  const syncState = useStore((s) => s.syncState)

  const filteredUnits = useStore((s) => s.filteredUnits)

  const categories = residencesData?.categories

  useEffect(() => {
    syncState({
      contentControls: {
        categories: categories,
        index: 0,
      },
    })
    return () => {
      syncState({
        selectedUnit: null,
        residencePopup: false,
        contentControls: null,
      })
    }
  }, [])

  const headers = [
    "unitName",
    "price",
    "floor",
    "unitType",
    "beds",
    // "baths",
    "aspect",
    "status",
  ]

  const cells = [
    "unitName",
    "price",
    "floor",
    "unitType",
    "beds",
    // "baths",
    "aspect",
    "status",
  ]

  return (
    <>
      <Wrapper>
        <TableContainer className="residences-table">
          {filteredUnits && (
            <FlexTable
              className="table"
              uniqueKey="unitName"
              headers={headers}
              selection="single"
              data={filteredUnits.filter((d) => d.active)}
              cells={cells}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
              onSelectionChange={(rows) => {
                syncState({
                  selectedUnit: rows,
                })
              }}
            />
          )}
          <TextButton
            className="exploreBtn"
            onClick={() => syncState({ residencePopup: true })}
            style={{
              opacity: selectedUnit?.[0] ? 1 : 0,
              pointerEvents: selectedUnit?.[0] ? "all" : "none",
              transform: `rotate(90deg) translate(-3px, ${selectedUnit?.[0] ? -100 : 0}%)`,
              width: "50%",
            }}
          >
            View Unit
          </TextButton>
        </TableContainer>
        <FilterContainer className="filters-container">
          <Filters className="filters" show={true} />
        </FilterContainer>
        <ResidencePopup2 selectedUnit={selectedUnit} />
        <CameraViewControls>
          <div
            onClick={() => {
              setCameraData(cameraPositionData.defaultFider)
            }}
          >
            <svg
              width="50%"
              height="50%"
              viewBox="0 0 82 82"
              fill="none"
              opacity={0.56}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.0315 6.625H19.1252C20.844 6.625 22.2502 5.2188 22.2502 3.5C22.2502 1.7812 20.844 0.375 19.1252 0.375H3.53125H3.28125C2.65625 0.40625 2.06245 0.65625 1.59375 1.03125C1.4375 1.15625 1.28125 1.3125 1.125 1.46875C0.65625 2 0.375 2.71875 0.375 3.49995V19.125C0.375 20.8438 1.7812 22.25 3.5 22.25C5.2188 22.25 6.625 20.8438 6.625 19.125V11.0312L29.406 33.8121C30.031 34.4371 30.8122 34.7496 31.6248 34.7496C32.4374 34.7496 33.2186 34.4371 33.8436 33.8434C35.0624 32.6246 35.0624 30.6559 33.8436 29.4372L11.0315 6.625Z"
                fill="white"
              />
              <path
                d="M81.6247 3.28125C81.5935 2.65625 81.3435 2.06245 80.9997 1.59375C80.8747 1.4375 80.7185 1.28125 80.5622 1.125C79.9997 0.65625 79.281 0.375 78.4997 0.375H62.8747C61.1559 0.375 59.7497 1.7812 59.7497 3.5C59.7497 5.2188 61.1559 6.625 62.8747 6.625H70.9685L48.1875 29.406C46.9687 30.6248 46.9687 32.5935 48.1875 33.8122C48.7813 34.4372 49.5625 34.7497 50.375 34.7497C51.1875 34.7497 51.9688 34.4372 52.5938 33.8435L75.3748 11.0315V19.1252C75.3748 20.844 76.781 22.2502 78.4998 22.2502C80.2186 22.2502 81.6248 20.844 81.6248 19.1252V3.53125L81.6247 3.28125Z"
                fill="white"
              />
              <path
                d="M78.4997 59.7507C76.7809 59.7507 75.3747 61.1569 75.3747 62.8757V70.9695L52.5937 48.1885C51.3749 46.9697 49.4062 46.9697 48.1875 48.1885C46.9688 49.4073 46.9687 51.376 48.1875 52.5947L70.9685 75.3757H62.8747C61.1559 75.3757 59.7497 76.7819 59.7497 78.5007C59.7497 80.2195 61.1559 81.6257 62.8747 81.6257H78.4687H78.5H78.7187C79.3437 81.5945 79.9375 81.3445 80.4062 80.9695C80.5625 80.8445 80.7187 80.6882 80.875 80.532C81.3437 80.0007 81.625 79.282 81.625 78.5008V62.8758C81.625 61.157 80.2185 59.7507 78.4997 59.7507Z"
                fill="white"
              />
              <path
                d="M29.406 48.1564L6.625 70.9684V62.8746C6.625 61.1558 5.2188 59.7496 3.5 59.7496C1.7812 59.7496 0.375 61.1558 0.375 62.8746V78.4686V78.7186C0.40625 79.3436 0.65625 79.9374 1 80.4061C1.125 80.5623 1.28125 80.7186 1.4375 80.8748C2 81.3436 2.7187 81.6248 3.5 81.6248H19.125C20.8438 81.6248 22.25 80.2186 22.25 78.4998C22.25 76.781 20.8438 75.3748 19.125 75.3748H11.0312L33.8122 52.5938C35.031 51.375 35.031 49.4063 33.8122 48.1876C32.5934 46.9689 30.6247 46.9376 29.406 48.1564Z"
                fill="white"
              />
            </svg>
          </div>
        </CameraViewControls>
      </Wrapper>
    </>
  )
}

export default ResidencesType1

const CameraViewControls = styled.div`
  position: absolute;
  padding: 2rem;
  z-index: 9;

  width: 23vw;
  height: 10%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 2em;

  border-right: 3px solid white;
  div {
    pointer-events: all;
    border: white solid 2px;
    height: 5vh;
    width: 5vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`

const Wrapper = styled.div`
  .toggle {
    transform-origin: left center;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;
    width: 60vh;
    height: 4em;
    transform: rotate(90deg) translate(calc(-50% - 2em), -50%);
    transition: opacity 0.4s ease-in-out;
    border-radius: 1rem 1rem 0 0;
  }

  .toggleButton {
    width: 50% !important;
    padding: 1rem;
    color: white;
    border-radius: 1rem 1rem 0 0;
  }

  .filterIcon {
    z-index: 2;
    position: absolute;
    border-radius: 100%;
    left: 26.5%;
    bottom: 38%;
    margin: 2rem;
    border: 1px solid var(--accent);
    transform: scale(0.75);
  }

  .toggleIcon {
    z-index: 2;
    position: absolute;
    border-radius: 100%;
    left: 29%;
    bottom: 38%;
    margin: 2rem;
    border: 1px solid var(--accent);
    transform: scale(0.75);
  }
`

const FilterContainer = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 10;
  pointer-events: all;
  transition: transform 0.4s ease-in-out;
`

const TableContainer = styled.div`
  .table {
    top: 0;
  }

  .exploreBtn {
    z-index: -1000;
    position: absolute;

    border: 3px solid var(--primary);
    border-bottom: none;
    left: calc(23vw);
    width: 4rem;
    top: 0;
    color: var(--primary);

    transform-origin: left top;

    border-radius: 1rem 1rem 0 0;

    background-color: var(--secondary);

    cursor: pointer;
  }
`
