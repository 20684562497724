import { useStore } from "@state/store"
import { useRef } from "react"
import NavTextButton from "@common/ui/NavTextButton"

type StateContentProps = {
  className: string
  categories: any
  style?: React.CSSProperties
  [x: string]: unknown
}

const ContentIndexControls = ({
  categories,
  className,
  ...props
}: StateContentProps) => {
  const pageControls = useRef()
  const syncState = useStore((s) => s.syncState)
  const contentControls = useStore((s) => s.contentControls)

  if (contentControls === null) return null

  const { index: activeIndex } = contentControls

  return (
    <div className={className} ref={pageControls} style={{ ...props.style }}>
      {categories &&
        categories.map((category, index) => (
          <NavTextButton
            active={categories[activeIndex] === category}
            className={`buttons ${
              categories[activeIndex] === category && "active"
            }`}
            key={`${category}-${index}`} // Ensure a unique key for each category
            onClick={() => {
              const newIndex = categories.findIndex((cat) => cat === category)
              syncState({
                contentControls: {
                  ...contentControls,
                  index: newIndex,
                },
              })
            }}
          >
            {category}
          </NavTextButton>
        ))}
    </div>
  )
}

export default ContentIndexControls
