import { useStore } from "@state/store"
import { useProjectDataStore } from "@state/projectDataStore"
import { filterTable, getFiltersDynamically } from "@lib/utils/residenceTableFn"
import { useEffect, useMemo, useState } from "react"

export type TFilters = {
  floor: number[]
  price: number[]
  beds: string[]
  baths: string[]
  blocks: string | null
}

export const useFilters = () => {
  const availabiltyData = useProjectDataStore((s) => s.availabilityData)
  const [initialFilters, setInitialFilters] = useState(null)

  const selectedUnit = useStore((s) => s.selectedUnit)
  const { syncState } = useStore((s) => ({
    syncState: s.syncState,
  }))

  const filters = useStore((s) => s.filters)

  useEffect(() => {
    const filteredData = filterTable(
      filters,
      availabiltyData.data,
      selectedUnit,
    )

    setTimeout(() => {
      syncState({
        filteredUnits: filteredData,
      })
    }, 250)
  }, [filters, selectedUnit])

  const handleReset = () => {
    setTimeout(() => {
      syncState({
        filters: initialFilters,
      })
    }, 250)
  }

  const handleToggleChange = (key: keyof TFilters, value: any) => {
    const currentValues = filters[key] || []

    const updatedValues = currentValues.includes(value)
      ? currentValues.filter((item) => item !== value)
      : [...currentValues, value]

    setTimeout(() => {
      syncState({
        filters: {
          ...filters,
          [key]: updatedValues,
        },
      }),
        250
    })
  }

  const handleRangeChange = (key: keyof TFilters, value: any) => {
    setTimeout(() => {
      syncState({
        filters: {
          ...filters,
          [key]: value,
        },
      }),
        250
    })
  }

  const dynamicFilters = useMemo(() => {
    const dynamicFilters = getFiltersDynamically(availabiltyData.data)
    setInitialFilters(dynamicFilters)
    return dynamicFilters
  }, [availabiltyData])

  return {
    dynamicFilters,
    handleToggleChange,
    filters,
    handleRangeChange,
    handleReset,
  }
}
