import { useState, useEffect, useRef, useMemo } from "react"
import styled from "styled-components/macro"
import gsap from "gsap"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import MenuButton from "@common/ui/MenuButton"
import useMaskGSAP from "@common/hooks/useMaskGSAP"
import Logo from "@common/ui/Logo"
import ListItem from "@common/ui/ListItem"
import { useProjectDataStore } from "@state/projectDataStore"
import { getColor } from "@utils/getColor"
import TextButton from "@common/ui/TextButton"
import NavTextButton from "@common/ui/NavTextButton"
import { useStore } from "@state/store"
import ContentIndexControls from "../ContentIndexControls"

function Sidebar() {
  const {
    brandingData,
    navigationData,
    galleryData,
    residencesData,
    info1Data,
  } = useProjectDataStore((s) => ({
    brandingData: s.brandingData,
    navigationData: s.navigationData,
    galleryData: s.galleryData,
    residencesData: s.residencesData,
    info1Data: s.info1Data,
  }))
  const [categories, setCategories] = useState([])
  const [sidebarLinks, setSidebarLinks] = useState(true)
  const logoWidth = navigationData?.logoWidth || 12.25
  const navigate = useNavigate()
  const { search } = useLocation()
  const currentPath = window.location.pathname
  const routes =
    navigationData?.menuItems.filter((item) => item.include === "true") || []
  const gallery = [
    ...Array.from(
      new Set(galleryData?.images?.map((image) => image?.category)),
    ),
  ]
  const residences = [
    ...Array.from(
      new Set(residencesData?.categories?.map((category) => category)),
    ),
  ]

  const info1 = [
    ...Array.from(
      new Set(info1Data?.sections?.map((category) => category?.title)),
    ),
  ]

  const categoriesIndex = {
    gallery: gallery,
    residences: residences,
    info1: info1,
  }

  function handleClick(page: string) {
    const currentPage = page.split("?")[0]
    setCategories(categoriesIndex[currentPage])
    // console.log(categories)
  }

  useEffect(() => {
    gsap.to(".links", {
      autoAlpha: sidebarLinks ? 1 : 0,
      x: sidebarLinks ? 0 : -10,
      duration: 0.6,
      ease: "power3.inOut",
    })
    gsap.to(".subLinks", {
      autoAlpha: sidebarLinks ? 0 : 1,
      x: sidebarLinks ? 20 : 0,
      duration: 0.6,
      ease: "power3.inOut",
    })
  }, [sidebarLinks])

  return (
    <SidebarWrapper $bgColor={getColor(navigationData?.bgColor)}>
      <Logo
        width={logoWidth}
        onPointerUp={() => {
          navigate("/")
        }}
        style={{
          margin: "0 auto",
          padding: "4rem 0",
          pointerEvents: "auto",
        }}
      />
      <LinksWrapper className="links">
        {routes
          ?.filter(
            (route) => route?.include === "true" && route?.path !== "splash",
          )
          ?.map(({ navLabel, pageType }, index) => {
            return (
              <div
                key={index}
                className="navItem"
                onClick={() => {
                  navigate(`${pageType}${search}`)
                  setSidebarLinks(false)
                  handleClick(`${pageType}${search}`)
                }}
              >
                <TextButton
                  variant="text"
                  color={"secondary"}
                  style={{ letterSpacing: "5px" }}
                >
                  {navLabel}
                </TextButton>
              </div>
            )
          })}
      </LinksWrapper>
      <SubLinksWrapper className="subLinks">
        <div className="back" onClick={() => setSidebarLinks(true)}>
          <img src="/assets/icons/back.svg" alt="" />
          Back
        </div>
        <ContentIndexControls
          categories={categories}
          className="sidebarCategories"
        />
      </SubLinksWrapper>
    </SidebarWrapper>
  )
}

export default Sidebar

type TNavStyledProps = {
  $bgColor?: string
}

const SidebarWrapper = styled.nav<TNavStyledProps>`
  background-color: ${(p) => p.$bgColor || "var(--primary)"};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10rem;

  .sidebarCategories {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    cursor: pointer;
    pointer-events: all;

    .buttons {
      color: var(--secondary);
      opacity: 0.5;
    }

    .active {
      color: var(--secondary);
      opacity: 1;
    }
  }
`

const LinksWrapper = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0;

  padding: 2rem 4rem;

  .navItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .line {
      width: 0px;
      height: 1px;
      border-radius: 1rem;
      background-color: #000000;
    }
  }

  > * {
    pointer-events: all;
  }
`
const SubLinksWrapper = styled.div`
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  opacity: 0;
  padding: 2rem 4rem;

  .back {
    margin-right: auto;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;

    img {
      filter: invert(100%);
    }

    &:active {
      transform: scale(0.95);
      opacity: 0.5;
      transition: 0.3s;
    }
  }

  .navItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .line {
      width: 0px;
      height: 1px;
      border-radius: 1rem;
      background-color: #000000;
    }
  }

  > * {
    pointer-events: all;
  }
`
