import { useProjectDataStore } from "@state/projectDataStore"
import React, { ReactNode, useRef, useState } from "react"
import styled, { css, keyframes } from "styled-components"

interface ButtonProps {
  variant?: "fill" | "outline" | "text"
  color?: `#${string}` | "primary" | "secondary" | "accent"
  icon?: ReactNode | string
  iconPosition?: "left" | "right"
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  style?: React.CSSProperties
  onClick?: (e?: any) => void | Promise<void>
}

const isLightColor = (color: string) => {
  const hex = color.replace("#", "")
  const bigint = parseInt(
    hex.length === 3
      ? hex
          .split("")
          .map((h) => h + h)
          .join("")
      : hex,
    16,
  )
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  // Calculate luminance to determine if the color is light
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255
  return luminance > 0.7 // Higher luminance means lighter color
}

const TextButton: React.FC<ButtonProps> = ({
  variant = "fill",
  color = "accent",
  icon,
  iconPosition = "left",
  onClick,
  children,
  className,
  disabled = false,
  style,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const buttonRef = useRef<any>(null)
  const [buttonWidth, setButtonWidth] = useState<number | null>(null)
  const { brandingData } = useProjectDataStore((s) => ({
    brandingData: s.brandingData,
  }))

  const handleClick = async () => {
    if (isLoading) return

    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth)
    }

    if (onClick) {
      const result = onClick()
      if (result instanceof Promise) {
        setIsLoading(true)
        try {
          await result
        } finally {
          setIsLoading(false)
        }
      }
    }
  }

  const isCustomColor = (color: string) =>
    /^#([0-9a-f]{3}){1,2}$/i.test(color) ||
    /^(rgb|rgba|hsl|hsla|white|black|gray|grey)$/i.test(color)

  const colorScheme = isCustomColor(color)
    ? color
    : brandingData.colours[color] || brandingData.colours.primary

  const textColor = isLightColor(colorScheme) ? "#000" : "#fff" // Set text color based on background

  const borderRadius = brandingData.borderStyle === "rounded" ? "50px" : "0px"

  return (
    <StyledButton
      ref={buttonRef}
      disabled={disabled || isLoading}
      variant={variant}
      colorScheme={colorScheme}
      textColor={textColor}
      borderRadius={borderRadius}
      onClick={handleClick}
      className={className}
      style={{
        height: "4em", // Set height to 3em for all buttons
        width:
          isLoading && buttonWidth && children
            ? `${buttonWidth}px`
            : children
              ? "auto"
              : "4em", // If no children, fixed width for icon button
        ...style,
      }}
      iconPosition={iconPosition}
      hasIcon={!!icon}
      {...props}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {icon && iconPosition === "left" && (
            <IconWrapper iconPosition={iconPosition} hasChildren={children}>
              {typeof icon === "string" ? <img src={icon} alt="icon" /> : icon}
            </IconWrapper>
          )}
          {children && <Text>{children}</Text>}
          {icon && iconPosition === "right" && (
            <IconWrapper iconPosition={iconPosition} hasChildren={children}>
              {typeof icon === "string" ? <img src={icon} alt="icon" /> : icon}
            </IconWrapper>
          )}
        </>
      )}
    </StyledButton>
  )
}

export default TextButton

interface StyledButtonProps {
  variant: "fill" | "outline" | "text"
  colorScheme: string
  borderRadius: string
  iconPosition: "left" | "right"
  hasIcon: boolean
  textColor: string
  disabled: boolean
}

const hoverStyles = {
  fill: css`
    filter: brightness(1.1);
  `,
  outline: css`
    background-color: rgba(255, 255, 255, 0.1);
  `,
  text: css``,
}

const StyledButton = styled.div<StyledButtonProps>`
  height: 3em;
  display: inline-flex;
  align-items: center;
  justify-content: ${({ hasIcon, iconPosition }) =>
    hasIcon ? (iconPosition === "left" ? "center" : "center") : "center"};

  cursor: pointer;
  user-select: none;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: all 0.3s ease;
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ hasIcon }) => (hasIcon ? "0.5em 1em" : "0.5em 1em")};

  ${({ variant, colorScheme, textColor }) => {
    switch (variant) {
      case "fill":
        return css`
          background-color: ${colorScheme};
          color: ${textColor}; // Use dynamic text color
          border: none;

          &:hover {
            ${hoverStyles.fill}
          }

          &:active {
            opacity: 0.5;
            transform: scale(0.95);
          }
        `
      case "outline":
        return css`
          background-color: transparent;
          color: ${colorScheme};
          border: 1px solid ${colorScheme};

          &:hover {
            ${hoverStyles.outline}
          }

          &:active {
            opacity: 0.5;
            transform: scale(0.95);
          }
        `
      case "text":
        return css`
          background-color: transparent;
          color: ${colorScheme};
          border: none;

          &:hover {
            ${hoverStyles.text}
          }

          &:active {
            opacity: 0.5;
            transform: scale(0.95);
          }
        `
      default:
        return ""
    }
  }}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`

const IconWrapper = styled.div<{
  iconPosition: "left" | "right"
  hasChildren: any
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ iconPosition }) =>
    iconPosition === "left" ? "0 8px 0 0" : "0 0 0 10px"};

  ${({ hasChildren }) => !hasChildren && "margin: 0;"}

  svg {
    line-height: 1;
    /* width: 1em;
    height: 1em; */
  }

  img {
    width: 1.5em;
    height: 1.5em;
    max-width: 100%;
    max-height: 100%;
  }
`

const Text = styled.span`
  flex: 1;
  text-align: center; // Align text to center when there's no icon
  line-height: 1;
`

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #fff;
  border-radius: 50%;
  margin: auto;
  animation: ${spin} 1s linear infinite;
`
