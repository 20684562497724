import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import * as Slider from "@radix-ui/react-slider"
import TextButton from "@common/ui/TextButton"
import ToggleGroup from "@common/ui/ToggleGroup"

import { useFilters } from "@common/hooks/useFilteredData"

const formatPrice = (val: number) => {
  const formattedVal = Number(val).toLocaleString()
  return formattedVal
}

// TODO - Should come from payload
const marketingNames = {
  S01C: "Cherry Point",
  N01B: "Oak House",
}

const bedroomDisplayNames = {
  "0": "Studio",
  "1": "1 Bed",
  "2": "2 Bed",
}

type ResidenceFiltersProps = {
  className?: string
  show: boolean
}

const Filters: React.FC<ResidenceFiltersProps> = ({ className }) => {
  const {
    dynamicFilters,
    handleToggleChange,
    handleRangeChange,
    filters,
    handleReset,
  } = useFilters()
  const { floorRange, priceRange, bedOptions, blockOptions } = dynamicFilters

  const [localPrice, setLocalPrice] = useState(priceRange)
  const [localFloor, setLocalFloor] = useState(floorRange)

  return (
    <FiltersWrapper className={className}>
      <div className="buildings">
        <div className="label">Building</div>
        <ToggleGroup
          options={blockOptions}
          labels={marketingNames}
          selected={filters.blocks}
          type="blocks"
          onClick={(value: string) => {
            handleToggleChange("blocks", value)
          }}
        />
      </div>
      <div className="price">
        <div className="label">Price</div>
        <div className="filter">
          <div className="price-values">
            <div className="price-value">£{formatPrice(localPrice[0])}</div>
            <div className="price-value">£{formatPrice(localPrice[1])}</div>
          </div>
          <form>
            <Slider.Root
              value={localPrice}
              className="SliderRoot"
              step={10000}
              min={priceRange[0]}
              max={priceRange[1]}
              onValueChange={(value) => {
                setLocalPrice(value as [number, number])
              }}
              onValueCommit={(value) => {
                handleRangeChange("price", value)
              }}
            >
              <Slider.Track className="SliderTrack">
                <Slider.Range className="SliderRange" />
              </Slider.Track>
              <Slider.Thumb className="SliderThumb" aria-label="Volume" />
              <Slider.Thumb className="SliderThumb" aria-label="Volume" />
            </Slider.Root>
          </form>
        </div>
      </div>
      <div className="floors">
        <div className="label">Floor</div>
        <div className="filter">
          <div className="floor-values">
            <div className="floor-value">{localFloor[0]}</div>
            <div className="floor-value">{localFloor[1]}</div>
          </div>
          <form>
            <Slider.Root
              className="SliderRoot"
              value={localFloor}
              minStepsBetweenThumbs={1.5}
              step={1}
              min={floorRange[0]}
              max={floorRange[1]}
              onValueChange={(value) => {
                setLocalFloor(value as [number, number])
              }}
              onValueCommit={(value) => {
                handleRangeChange("floor", value)
              }}
            >
              <Slider.Track className="SliderTrack">
                <Slider.Range className="SliderRange" />
              </Slider.Track>
              <Slider.Thumb className="SliderThumb" />
              <Slider.Thumb className="SliderThumb" />
            </Slider.Root>
          </form>
        </div>
      </div>
      <div className="beds">
        <div className="label">Bedrooms</div>
        <ToggleGroup
          labels={bedroomDisplayNames}
          options={bedOptions}
          selected={filters.beds}
          type="beds"
          onClick={(value: string) => {
            handleToggleChange("beds", value)
          }}
        />
      </div>

      <TextButton
        style={{
          width: "50%",
          margin: "1.5rem 0",
          fontSize: "0.75rem",
          padding: "1rem 2rem",
          border: "1px solid white",
          color: "white",
        }}
        variant="outline"
        onClick={() => {
          setLocalFloor(floorRange)
          setLocalPrice(priceRange)
          handleReset()
        }}
      >
        Reset Filters
      </TextButton>
    </FiltersWrapper>
  )
}
export default Filters

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1;
  cursor: pointer;
  padding: 3rem 3rem 3rem 3em;

  .SliderRoot {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    height: 2rem;
  }

  .SliderTrack {
    background-color: #ffffff49;
    position: relative;
    flex-grow: 1;
    height: 1px;
  }

  .SliderRange {
    background-color: white;
    position: absolute;
    height: 1px;
  }

  .SliderThumb {
    display: block;
    width: 16px;
    height: 16px;
    background-color: var(--secondary);
    border: 1px solid white;
  }

  .floors .filter,
  .price .filter {
    color: white;
  }

  .label {
    font-size: 0.75rem;
    text-transform: uppercase;
    padding: 1rem 0;
    letter-spacing: 0.15em;
    color: white;
  }

  .baths .filter,
  .buildings .filter,
  .beds .filter,
  .view .filter {
    width: 100%;
    display: flex;
    border: 1px solid white;
    font-weight: bold;

    color: white;

    button {
      all: unset;
    }

    .option {
      box-sizing: border-box;
      width: 100%;
      text-align: center;
      padding: 0.75rem 0;
      font-size: 0.8rem;
      cursor: pointer;
      transition: 0.3s;
    }

    .option:not(:last-child) {
      border-right: 1px solid white;
      transition: 0.3s;
    }

    .option.selected {
      background-color: var(--secondary);
      color: var(--primary);
      transition: 0.3s;
    }
  }

  .floors .floor-values,
  .price .price-values {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
  }
`
