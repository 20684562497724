import React, { FC, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import styled, { css, keyframes } from "styled-components"

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  isFullScreen?: boolean
  style?: React.CSSProperties
}

const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  isFullScreen = false,
  style,
}) => {
  const [showModal, setShowModal] = useState(isOpen)
  const [isAnimating, setIsAnimating] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setShowModal(true)
      setIsAnimating(true)
    } else {
      setIsAnimating(true)
      setTimeout(() => {
        setShowModal(false)
        setIsAnimating(false)
      }, 300) // Ensure this matches the animation duration
    }
  }, [isOpen])

  // Close the modal when the escape key is pressed
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        onClose()
      }
    }

    document.addEventListener("keydown", handleEscape)
    return () => document.removeEventListener("keydown", handleEscape)
  }, [onClose])

  // Prevent scrolling when the modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else if (!isAnimating) {
      document.body.style.overflow = ""
    }
  }, [isOpen, isAnimating])

  if (!showModal) return null

  return ReactDOM.createPortal(
    <Backdrop onClick={onClose} isOpen={isOpen} isFullScreen={isFullScreen}>
      <ModalContainer
        style={style}
        isFullScreen={isFullScreen}
        isOpen={isOpen}
        onClick={(e) => e.stopPropagation()}
      >
        {/* <CloseButton onClick={onClose}>&times;</CloseButton> */}
        {children}
      </ModalContainer>
    </Backdrop>,
    document.body,
  )
}

export default Modal

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
`

const Backdrop = styled.div<{ isOpen: boolean; isFullScreen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ isFullScreen }) =>
    !isFullScreen ? "rgba(0, 0, 0, 0.5)" : "transparent"};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  animation: ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)} 0.3s ease-out;
`

const ModalContainer = styled.div<{ isFullScreen: boolean; isOpen: boolean }>`
  background: white;
  border-radius: 8px;
  max-width: 100%;
  width: 100%;
  position: relative;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  animation: ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)} 0.3s ease-out;
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;

  ${({ isFullScreen }) =>
    isFullScreen &&
    css`
      width: 100%;
      height: 100%;
      max-width: none;
      max-height: none;
      border-radius: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
`
