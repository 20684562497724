import { ArrowLeftIcon } from "@radix-ui/react-icons"
import { QRCodeSVG } from "qrcode.react"
import styled from "styled-components"
import TextButton from "@common/ui/TextButton"
import { ShareAppData } from "./Share"

type Props = {
  shareAppData: ShareAppData
  show: (value: boolean) => void
}

const QRCodeOnly = (props: Props) => {
  const { show, shareAppData } = props

  return (
    <Wrapper>
      <TextButton
        color="#fff"
        className="close-button"
        onClick={() => show(false)}
        style={{ borderRadius: "50%" }}
        icon={<ArrowLeftIcon color="#fff" />}
        iconPosition="right"
        variant="outline"
      />

      <div className="qr">
        {true ? (
          <>
            <h1 className="text">
              View Your <span>favourites at home</span>
              <span>
                Scan the QR code to see you favourite units on your device.
              </span>
            </h1>
            <QR
              value={shareAppData.shareAppURL}
              size={160}
              level="Q"
              bgColor="var(--accent)"
              id="qr"
            />
          </>
        ) : (
          <div className="no-qr">
            QR Code <span>is waiting</span>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default QRCodeOnly

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .close-button {
    position: absolute;
    top: 2em;
    left: 2em;
  }

  .form {
    height: 100%;
    width: 70%;
    padding: 3em;
    display: flex;
    border-right: 1px solid #fff;
  }

  .qr {
    background-color: var(--primary);
    border: 2px solid #fff;

    .text {
      font-size: 5em;
      color: #fff;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 0.7;

      & span {
        font-family: secondaryFont;
        line-height: 1;
        color: var(--accent);
        font-weight: 400;
        display: block;
        text-transform: lowercase;

        &:last-child {
          font-family: primaryFont;
          color: #fff;
          font-size: 0.3em;
          font-weight: 200;
          text-transform: none;
          margin-top: 1em;
        }
      }
    }
  }

  .no-qr {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    color: #fff;
    font-size: 1.5em;
    letter-spacing: 0.2em;
    line-height: 0.9;

    span {
      font-size: 0.8em;
      color: var(--accent);
      text-transform: lowercase;
      font-family: secondaryFont;
      text-align: right;
      margin-right: -2em;
      font-size: 2em;
    }
  }
`

const QR = styled(QRCodeSVG)`
  width: fit-content;
  aspect-ratio: 1/1;
  & path {
    :nth-of-type(2) {
      fill: var(--primary);
    }
  }
`
