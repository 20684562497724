import { useProjectDataStore } from "@state/projectDataStore"
import React from "react"
import styled from "styled-components/macro"
import SvgIcon from "./SvgIcon"

interface IconLabelProps {
  icon?: string | React.ReactNode
  iconColor?: string
  label?: string | number
  borderRadius?: string
  variant?: "fill" | "outline" | "ghost"
}

const IconLabel: React.FC<IconLabelProps> = ({
  icon,
  iconColor,
  label,
  borderRadius = "0",
  variant = "fill",
}) => {
  const { brandingData } = useProjectDataStore((s) => ({
    brandingData: s.brandingData,
  }))
  const colours = brandingData?.colours

  return (
    <Wrapper
      color={iconColor}
      style={{
        color: variant === "fill" ? colours.white : colours.white,
        backgroundColor: variant === "fill" ? colours.primary : "transparent",
        border: variant === "outline" ? `1px solid ${colours.primary}` : "none",
        borderRadius: borderRadius,
        gap: label ? "0.5em" : "0",
        padding:
          variant === "fill" || variant === "outline" ? "0.5em 1em" : "0",
      }}
    >
      {typeof icon === "string" ? <SvgIcon url={icon} /> : icon}
      <div className="label">{label}</div>
    </Wrapper>
  )
}

export default IconLabel

const Wrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 1.2em;
  margin: 0.5em 0px;
  width: fit-content;

  svg {
    width: 1.5em;
    height: 1.5em;

    path {
      stroke: ${(p) => p.color};
    }
  }

  .label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 0 0.5em;
  }
`
