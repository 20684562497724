import styled from "styled-components/macro"

const SharedOwnership = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default SharedOwnership

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  background-color: var(--primary);
`
