import { useState } from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/Page/TextLayer.css"
import "react-pdf/dist/Page/AnnotationLayer.css"
import TextButton from "@common/ui/TextButton"
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`

type PDFViewerProps = {
  pageDisplay: "single" | "double"
  pdfFile: {
    publicURL: string
  }
}

export default function PDFViewer(props: PDFViewerProps) {
  const [numPages, setNumPages] = useState<number>()
  const [pageNumber, setPageNumber] = useState<number>(1)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages)
  }

  const { pageDisplay, pdfFile } = props

  const prevButtonDisabled = pageNumber <= 1
  const nextButtonDisabled =
    pageDisplay === "single"
      ? pageNumber >= numPages
      : pageNumber >= numPages - 1

  return (
    <>
      <Document
        className="pdf-document"
        file={pdfFile?.publicURL}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
      >
        <Page className="pdf-page" pageNumber={pageNumber} />
        {pageDisplay === "double" && (
          <Page className="pdf-page" pageNumber={pageNumber + 1} />
        )}
      </Document>
      <div className="pdf-page-controls">
        <TextButton
          variant="fill"
          color="primary"
          icon={<ChevronLeftIcon />}
          disabled={prevButtonDisabled}
          onClick={() => setPageNumber(pageNumber - 1)}
        />
        <TextButton
          variant="fill"
          color="primary"
          icon={<ChevronRightIcon />}
          disabled={nextButtonDisabled}
          onClick={() => setPageNumber(pageNumber + 1)}
        />
      </div>
    </>
  )
}
