import { useStore } from "@state/store"
import styled from "styled-components"
import TextButton from "./TextButton"

type Props = {
  main: string
  sub: string

  align: "left" | "right" | "center"
  body?: string
}
const Title = ({ main, sub, align, body }: Props) => {
  const selectedUnit = useStore((s) => s.selectedUnit)
  const residencePopup = useStore((s) => s.residencePopup)
  const syncState = useStore((s) => s.syncState)
  const alignment = {
    left: "flex-start",
    right: "flex-end",
    center: "center",
  }

  return (
    <TitleWr textAlign={align} flexAlign={alignment[align]}>
      {selectedUnit && !residencePopup && (
        <div
          onClick={() => {
            syncState({
              residencePopup: true,
            })
          }}
          className="explore"
          style={{
            cursor: "pointer",
            zIndex: 5,
            pointerEvents: "all",
            background: "var(--accent)",
            border: "1px solid var(--accent)",
            color: "var(--primary)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1rem",
            padding: "1.25rem 2rem",
          }}
        >
          Explore Unit
        </div>
      )}
      <h3> {main} </h3>
      <span>{sub}</span>
      <p> {body}</p>
    </TitleWr>
  )
}

export default Title

const TitleWr = styled.div<{
  flexAlign: string
  textAlign: string
}>`
  display: flex;
  background-color: var(--primary);
  border: 2px solid var(--secondary);
  padding: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: ${(p) => p.flexAlign};
  font-weight: 800;
  margin-left: auto;
  gap: 1rem;
  white-space: wrap;
  width: 100%;
  height: 100%;

  .explore:active {
    transform: scale(0.98);
    transition: 0.3s;
    opacity: 0.75;
  }

  h3 {
    color: var(--secondary);
    font-size: 1.2em;
    text-align: ${(p) => p.textAlign};
    text-transform: uppercase;
  }

  p {
    color: #fff;
    font-size: 0.5em;
    font-weight: 400;
    text-align: ${(p) => p.textAlign};
  }

  span {
    font-size: .7em;
    font-family: secondaryFont;
    color: var(--accent);
    font-weight: 10;
  }
`
