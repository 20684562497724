import { useLivePreview } from "@payloadcms/live-preview-react"
import { useProjectDataStore } from "@state/projectDataStore"
import { useEffect } from "react"
import { updateProjectDataStore } from "./updateProjectDataStore"
import { updateDocumentStyles } from "@lib/utils/setDocumentStyles"
import { loadLiveProjectData } from "./projectDataLiveLoader"
import { useSearchParams } from "react-router-dom"

export function camelCase(str: string): string {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase())
}

export function getCollectionKey(
  name: string,
  removeDataSuffix: boolean = false,
): string {
  const [collectionName] = name.split(" - ")
  return `${camelCase(collectionName)}${
    removeDataSuffix ? "" : "Data"
  }`?.replace("Page", "")
}

export default function PayloadLivePreview() {
  const [searchParams] = useSearchParams()
  const projectId = searchParams.get("projectId")

  const { setLocalState } = useProjectDataStore((s) => ({
    setLocalState: s.setLocalState,
  }))

  const { data }: any = useLivePreview({
    serverURL: `${import.meta.env.VITE_PAYLOAD_SERVER_URL}`,
    initialData: {},
    depth: 3,
  })

  useEffect(() => {
    loadLiveProjectData({ projectId })
      .then((projectData) => {
        console.log("Loaded project data", projectData)
        updateProjectDataStore(projectData, setLocalState)
      })
      .catch((err) => console.log(err.message))
  }, [])

  useEffect(() => {
    if (data && data?.name) {
      const collectionKey = getCollectionKey(data?.name)
      const { createdAt, id, relations, updatedAt, name, ...cleanedData } = data

      console.log(`Updating ${collectionKey} with:`, cleanedData)

      setLocalState({
        [collectionKey]: cleanedData,
      })

      if (collectionKey === "brandingData") {
        updateDocumentStyles(cleanedData)
      }
    }
  }, [data, setLocalState])

  return null
}
