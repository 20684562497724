import gsap from "gsap"
import React, { useEffect } from "react"
import styled from "styled-components"
import { useResi3DStore } from "../state/resi3DStore"

const ResiLandingOverlay = () => {
  const hasInteracted = useResi3DStore((s) => s.hasInteracted)
  const setHasInteracted = useResi3DStore((s) => s.setHasInteracted)

  const ref = React.useRef(null)

  const handleInteract = () => {
    if (!ref.current) return
    gsap.to(ref.current, {
      autoAlpha: 0,
      duration: 1,
      ease: "power3.inOut",
      onComplete: () => {
        setTimeout(() => {
          setHasInteracted(true)

          window.removeEventListener("pointerdown", handleInteract)
        }, 300)
      },
    })
  }

  useEffect(() => {
    window.addEventListener("pointerdown", handleInteract)

    return () => {
      setHasInteracted(false)
      window.removeEventListener("pointerdown", handleInteract)
    }
  }, [])

  if (hasInteracted) return null
  return (
    <ResiLandingWr ref={ref}>
      <img src="/assets/landing.svg" />
    </ResiLandingWr>
  )
}

const ResiLandingWr = styled.div`
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  img {
    width: 90%;
    height: 90%;
    object-fit: cover;
    object-position: top left;
  }
`

export default ResiLandingOverlay
