
  export const initialProjectState = {
  "brandingData": {
    "relationTo": "branding",
    "name": "Branding - West Ham",
    "colours": {
      "primary": "#25303b",
      "secondary": "#ff7b4f",
      "accent": "#ebb3f7"
    },
    "fonts": {
      "primaryFont": {
        "id": "6712447baa5c84cdc27dac2a",
        "filename": "westHamPrimary.ttf",
        "mimeType": "font/ttf",
        "filesize": 176352,
        "createdAt": "2024-10-18T11:20:27.754Z",
        "updatedAt": "2024-10-18T11:20:27.817Z",
        "publicURL": "/payload/projectFontMedia/westHamPrimary.ttf",
        "thumbnailURL": null,
        "url": "https://luna.vmistudio.com/api/projectFontMedia/file/westHamPrimary.ttf"
      },
      "secondaryFont": {
        "id": "67124481aa5c84cdc27dac4b",
        "filename": "westHamSecondary.otf",
        "mimeType": "font/otf",
        "filesize": 111256,
        "createdAt": "2024-10-18T11:20:33.990Z",
        "updatedAt": "2024-10-18T11:20:34.096Z",
        "publicURL": "/payload/projectFontMedia/westHamSecondary.otf",
        "thumbnailURL": null,
        "url": "https://luna.vmistudio.com/api/projectFontMedia/file/westHamSecondary.otf"
      }
    },
    "logos": {
      "lightColourLogo": {
        "id": "671244c6aa5c84cdc27dac92",
        "filename": "westHamLight.png",
        "mimeType": "image/png",
        "filesize": 18396,
        "width": 694,
        "height": 152,
        "focalX": 50,
        "focalY": 50,
        "createdAt": "2024-10-18T11:21:42.675Z",
        "updatedAt": "2024-10-18T11:21:42.736Z",
        "publicURL": "/payload/projectLogoMedia/westHamLight.png",
        "thumbnailURL": null,
        "url": "https://luna.vmistudio.com/api/projectLogoMedia/file/westHamLight.png"
      },
      "darkColourLogo": {
        "id": "671244cfaa5c84cdc27dacb4",
        "filename": "westHamDark.png",
        "mimeType": "image/png",
        "filesize": 16316,
        "width": 880,
        "height": 192,
        "focalX": 50,
        "focalY": 50,
        "createdAt": "2024-10-18T11:21:51.602Z",
        "updatedAt": "2024-10-18T11:21:51.660Z",
        "publicURL": "/payload/projectLogoMedia/westHamDark.png",
        "thumbnailURL": null,
        "url": "https://luna.vmistudio.com/api/projectLogoMedia/file/westHamDark.png"
      }
    },
    "bgImage": "6712448eaa5c84cdc27dac71"
  },
  "navigationData": {
    "relationTo": "navigation",
    "name": "Navigation - West Ham",
    "menuItems": [
      {
        "include": "true",
        "navLabel": "Destination",
        "pageType": "orbits",
        "id": "67122594858aa60026fdd838"
      },
      {
        "include": "true",
        "navLabel": "Transport & Lifestyle",
        "pageType": "map",
        "id": "67122594858aa60026fdd833"
      },
      {
        "include": "true",
        "navLabel": "Apartment Finder",
        "pageType": "residences",
        "id": "67122594858aa60026fdd832"
      },
      {
        "include": "true",
        "navLabel": "Gallery",
        "pageType": "gallery",
        "id": "67122594858aa60026fdd834"
      },
      {
        "include": "true",
        "navLabel": "Favourites",
        "pageType": "favourites",
        "id": "67122594858aa60026fdd835"
      },
      {
        "include": "false",
        "navLabel": "Explorer",
        "pageType": "explorer",
        "id": "67122594858aa60026fdd836"
      },
      {
        "include": "false",
        "navLabel": "Development",
        "pageType": "development",
        "id": "67122594858aa60026fdd837"
      },
      {
        "include": "true",
        "navLabel": "Shared Ownership",
        "pageType": "sharedOwnership",
        "id": "67122594858aa60026fdd839"
      },
      {
        "include": "true",
        "navLabel": "Peabody & Partners",
        "pageType": "about",
        "id": "67122594858aa60026fdd83a"
      },
      {
        "include": "false",
        "navLabel": "Info1 Page",
        "pageType": "info1",
        "id": "67122594858aa60026fdd83b"
      }
    ],
    "layout": "fullscreen",
    "logo": "lightColourLogo",
    "logoWidth": 12.25,
    "fontColour": "white",
    "bgColor": "primary",
    "burgerBackgroundColour": "primary",
    "burgerBorderColour": "accent",
    "burgerWidth": 3.5,
    "shapeColour": "secondary"
  },
  "explorerData": null,
  "sharedOwnershipData": {
    "relationTo": "sharedOwnership",
    "name": "Shared Ownership - West Ham",
    "feature": "sharedOwnership"
  },
  "aboutData": {
    "relationTo": "about",
    "name": "About - West Ham",
    "sections": [
      {
        "title": "Peabody",
        "description": [
          {
            "title": "Building History",
            "desc": "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
            "id": "6712474f7330ed002d7eda72"
          },
          {
            "title": "Social Impact",
            "desc": "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
            "id": "671247557330ed002d7eda73"
          },
          {
            "title": "",
            "desc": "",
            "img": {
              "id": "67124b2629ce53533d1243c4",
              "filename": "about1.jpg",
              "mimeType": "image/jpeg",
              "filesize": 363141,
              "width": 938,
              "height": 520,
              "focalX": 50,
              "focalY": 50,
              "createdAt": "2024-10-18T11:48:54.250Z",
              "updatedAt": "2024-10-18T11:48:54.308Z",
              "publicURL": "/payload/galleryMedia/about1.jpg",
              "thumbnailURL": null,
              "url": "https://luna.vmistudio.com/api/galleryMedia/file/about1.jpg"
            },
            "id": "67124b3e52326f002648a78c"
          },
          {
            "title": "",
            "desc": " ",
            "img": {
              "id": "67124ca529ce53533d12480d",
              "filename": "about2.jpg",
              "mimeType": "image/jpeg",
              "filesize": 612857,
              "width": 1174,
              "height": 862,
              "focalX": 50,
              "focalY": 50,
              "createdAt": "2024-10-18T11:55:17.916Z",
              "updatedAt": "2024-10-18T11:55:17.971Z",
              "publicURL": "/payload/galleryMedia/about2.jpg",
              "thumbnailURL": null,
              "url": "https://luna.vmistudio.com/api/galleryMedia/file/about2.jpg"
            },
            "id": "67124c9e52326f002648a794"
          },
          {
            "title": "Customer Focus",
            "desc": " Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
            "id": "67124b5e52326f002648a78f"
          },
          {
            "title": "Quality and sustainability",
            "desc": " Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
            "id": "67124b6a52326f002648a790"
          },
          {
            "title": "Strong focus",
            "desc": " Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
            "id": "67124b7252326f002648a791"
          },
          {
            "title": "Award Winning",
            "desc": " Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
            "id": "67124b7852326f002648a792"
          }
        ],
        "stats": [],
        "id": "671247447330ed002d7eda71"
      },
      {
        "title": "The Architects",
        "description": [
          {
            "title": "Customer focus",
            "desc": "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
            "id": "67124dae52326f002648a798"
          },
          {
            "title": "Customer focus",
            "desc": "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se.",
            "id": "67124db452326f002648a799"
          }
        ],
        "main": "Architects",
        "mainName": "Peter Taylor",
        "quote": "The vision was to create a well-connected city quarter with nature at its core parkland living, with London at your doorstep.",
        "by": "Andrew Taylor & Pankaj Patel",
        "by2": "FOUNDING DIRECTORS, PATEL TAYLOR",
        "image": {
          "id": "67124d2f29ce53533d1248e2",
          "filename": "about3.jpg",
          "mimeType": "image/jpeg",
          "filesize": 4017229,
          "width": 2748,
          "height": 3052,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-18T11:57:35.328Z",
          "updatedAt": "2024-10-18T11:57:35.384Z",
          "publicURL": "/payload/galleryMedia/about3.jpg",
          "thumbnailURL": null,
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/about3.jpg"
        },
        "stats": [
          {
            "text": "70%",
            "type": "Statistic Info",
            "id": "67124d3552326f002648a795"
          },
          {
            "text": "5k",
            "type": "Statistic Info",
            "id": "67124d8152326f002648a796"
          },
          {
            "text": "100+",
            "type": "Statistic Info",
            "id": "67124d8652326f002648a797"
          }
        ],
        "id": "67124b4852326f002648a78d"
      },
      {
        "title": "Community",
        "description": [
          {
            "title": "Dominic Schneider",
            "desc": "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se",
            "img": {
              "id": "67124f6529ce53533d124db1",
              "filename": "about4.jpg",
              "mimeType": "image/jpeg",
              "filesize": 5756383,
              "width": 4232,
              "height": 2560,
              "focalX": 50,
              "focalY": 50,
              "createdAt": "2024-10-18T12:07:01.797Z",
              "updatedAt": "2024-10-18T12:07:01.872Z",
              "publicURL": "/payload/galleryMedia/about4.jpg",
              "thumbnailURL": null,
              "url": "https://luna.vmistudio.com/api/galleryMedia/file/about4.jpg"
            },
            "id": "67124f4f52326f002648a79d"
          },
          {
            "title": "Dominic Fike",
            "desc": "Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam sem fringilla ut morbi tincidunt. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Vesti blandit cursus risus. In aliquam se",
            "img": {
              "id": "67124f7f29ce53533d124de4",
              "filename": "about2-1.jpg",
              "mimeType": "image/jpeg",
              "filesize": 612857,
              "width": 1174,
              "height": 862,
              "focalX": 50,
              "focalY": 50,
              "createdAt": "2024-10-18T12:07:27.752Z",
              "updatedAt": "2024-10-18T12:07:27.818Z",
              "publicURL": "/payload/galleryMedia/about2-1.jpg",
              "thumbnailURL": null,
              "url": "https://luna.vmistudio.com/api/galleryMedia/file/about2-1.jpg"
            },
            "id": "67124f6852326f002648a79e"
          }
        ],
        "stats": [],
        "id": "67124f4352326f002648a79c"
      }
    ],
    "feature": "about"
  },
  "info1Data": {
    "relationTo": "info1",
    "name": "Info1 - West Ham",
    "feature": "info1",
    "style": "full",
    "sections": []
  },
  "developmentData": {
    "relationTo": "development",
    "name": "Development - West Ham",
    "feature": "development"
  },
  "favouritesData": {
    "relationTo": "favourites",
    "name": "Favourites - West Ham",
    "feature": "favourites",
    "shareType": {
      "shareAppURL": "https://luna-pwa.vercel.app/",
      "hiddenProjectId": {
        "id": "67122594092d9036925b38c1",
        "name": "West Ham",
        "organisation": "670e76cd7ae1d67934334ad5",
        "published": true,
        "createdAt": "2024-10-18T09:08:36.526Z",
        "updatedAt": "2024-10-24T15:11:10.543Z",
        "pages": [
          {
            "relationTo": "branding",
            "value": "67122594092d9036925b38d1"
          },
          {
            "relationTo": "navigation",
            "value": "67122594092d9036925b38d2"
          },
          {
            "relationTo": "splash",
            "value": "67122594092d9036925b38cd"
          },
          {
            "relationTo": "residences",
            "value": "67122594092d9036925b38ce"
          },
          {
            "relationTo": "map",
            "value": "67122594092d9036925b38d3"
          },
          {
            "relationTo": "gallery",
            "value": "67122594092d9036925b38cf"
          },
          {
            "relationTo": "favourites",
            "value": "67122594092d9036925b38d0"
          },
          {
            "relationTo": "orbits",
            "value": "67122594092d9036925b38c3"
          },
          {
            "relationTo": "explorer",
            "value": "67122594092d9036925b38c4"
          },
          {
            "relationTo": "development",
            "value": "67122594092d9036925b38c5"
          },
          {
            "relationTo": "sharedOwnership",
            "value": "67122594092d9036925b38c6"
          },
          {
            "relationTo": "about",
            "value": "67122594092d9036925b38c7"
          },
          {
            "relationTo": "info1",
            "value": "67122594092d9036925b38c8"
          },
          {
            "relationTo": "unitTypes",
            "value": "67122594092d9036925b38ca"
          },
          {
            "relationTo": "unitViews",
            "value": "67122594092d9036925b38cb"
          },
          {
            "relationTo": "unitDetails",
            "value": "67122594092d9036925b38cc"
          },
          {
            "relationTo": "unitAvailability",
            "value": "671a63809d9dc294c18f2e40"
          }
        ],
        "deployHook": "https://api.vercel.com/v1/integrations/deploy/prj_ZuIge9774D8V7BxhumXfy6Q6qJMB/8frCDOBIVR"
      },
      "options": "qrCodeOnly"
    },
    "emailSettings": {
      "ccAgentList": []
    }
  },
  "galleryData": {
    "relationTo": "gallery",
    "name": "Gallery - West Ham",
    "feature": "masonrygallery",
    "scroll": "horizontal",
    "enableCategories": true,
    "enableRounded": false,
    "style": "window",
    "spacing": "large",
    "categoryStyle": "type1",
    "categoryColor": "black",
    "images": [
      {
        "content": {
          "id": "671f697cee5ee5f3d8e8a760",
          "publicURL": "/payload/galleryMedia/c6_aerial2_02.webp",
          "filename": "c6_aerial2_02.webp",
          "mimeType": "image/webp",
          "filesize": 286680,
          "width": 2109,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:37:48.645Z",
          "updatedAt": "2024-10-28T10:37:48.645Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/c6_aerial2_02.webp",
          "thumbnailURL": null
        },
        "category": "Exteriors",
        "id": "671f6967042af00026886d30"
      },
      {
        "content": {
          "id": "671f68baee5ee5f3d8e8a5a5",
          "publicURL": "/payload/galleryMedia/c1_CO1_amenity_space_opt3_fin.webp",
          "filename": "c1_CO1_amenity_space_opt3_fin.webp",
          "mimeType": "image/webp",
          "filesize": 528266,
          "width": 1622,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:34:34.322Z",
          "updatedAt": "2024-10-28T10:34:34.322Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/c1_CO1_amenity_space_opt3_fin.webp",
          "thumbnailURL": null
        },
        "category": "Exteriors",
        "id": "671f68a5042af00026886c4c"
      },
      {
        "content": {
          "id": "671f6931ee5ee5f3d8e8a6c9",
          "publicURL": "/payload/galleryMedia/c3_NO1B_streetscape_NEW_fin.webp",
          "filename": "c3_NO1B_streetscape_NEW_fin.webp",
          "mimeType": "image/webp",
          "filesize": 372534,
          "width": 1940,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:36:33.926Z",
          "updatedAt": "2024-10-28T10:36:33.926Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/c3_NO1B_streetscape_NEW_fin.webp",
          "thumbnailURL": null
        },
        "category": "Exteriors",
        "id": "671f6922042af00026886d2c"
      },
      {
        "content": {
          "id": "671f6946ee5ee5f3d8e8a6f4",
          "publicURL": "/payload/galleryMedia/c4_Park_opt4.webp",
          "filename": "c4_Park_opt4.webp",
          "mimeType": "image/webp",
          "filesize": 286808,
          "width": 1125,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:36:54.290Z",
          "updatedAt": "2024-10-28T10:36:54.290Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/c4_Park_opt4.webp",
          "thumbnailURL": null
        },
        "category": "Exteriors",
        "id": "671f6937042af00026886d2d"
      },
      {
        "content": {
          "id": "671f691bee5ee5f3d8e8a69e",
          "publicURL": "/payload/galleryMedia/c2_NO1B_HERO_opt6.webp",
          "filename": "c2_NO1B_HERO_opt6.webp",
          "mimeType": "image/webp",
          "filesize": 492666,
          "width": 1782,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:36:11.683Z",
          "updatedAt": "2024-10-28T10:36:11.683Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/c2_NO1B_HERO_opt6.webp",
          "thumbnailURL": null
        },
        "category": "Exteriors",
        "id": "671f6905042af00026886d2b"
      },
      {
        "content": {
          "id": "671f6956ee5ee5f3d8e8a718",
          "publicURL": "/payload/galleryMedia/c5_SO1C_HERO_opt7.webp",
          "filename": "c5_SO1C_HERO_opt7.webp",
          "mimeType": "image/webp",
          "filesize": 218888,
          "width": 1195,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:37:10.505Z",
          "updatedAt": "2024-10-28T10:37:10.505Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/c5_SO1C_HERO_opt7.webp",
          "thumbnailURL": null
        },
        "category": "Exteriors",
        "id": "671f6948042af00026886d2e"
      },
      {
        "content": {
          "id": "671f6965ee5ee5f3d8e8a73c",
          "publicURL": "/payload/galleryMedia/c6_aerial1.webp",
          "filename": "c6_aerial1.webp",
          "mimeType": "image/webp",
          "filesize": 361638,
          "width": 2438,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:37:25.039Z",
          "updatedAt": "2024-10-28T10:37:25.039Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/c6_aerial1.webp",
          "thumbnailURL": null
        },
        "category": "Exteriors",
        "id": "671f6958042af00026886d2f"
      },
      {
        "content": {
          "id": "671f6ad7ee5ee5f3d8e8a8e8",
          "publicURL": "/payload/galleryMedia/West Ham-5.webp",
          "filename": "West Ham-5.webp",
          "mimeType": "image/webp",
          "filesize": 408302,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:43:35.114Z",
          "updatedAt": "2024-10-28T10:43:35.114Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-5.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6abc042af00026886e0f"
      },
      {
        "content": {
          "id": "671f6ae6ee5ee5f3d8e8a90f",
          "publicURL": "/payload/galleryMedia/West Ham-41.webp",
          "filename": "West Ham-41.webp",
          "mimeType": "image/webp",
          "filesize": 172620,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:43:50.598Z",
          "updatedAt": "2024-10-28T10:43:50.598Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-41.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6ad9042af00026886e10"
      },
      {
        "content": {
          "id": "671f6af6ee5ee5f3d8e8a936",
          "publicURL": "/payload/galleryMedia/West Ham-53.webp",
          "filename": "West Ham-53.webp",
          "mimeType": "image/webp",
          "filesize": 158612,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:44:06.830Z",
          "updatedAt": "2024-10-28T10:44:06.830Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-53.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6ae9042af00026886e11"
      },
      {
        "content": {
          "id": "671f6b06ee5ee5f3d8e8a960",
          "publicURL": "/payload/galleryMedia/West Ham-71.webp",
          "filename": "West Ham-71.webp",
          "mimeType": "image/webp",
          "filesize": 245258,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:44:22.067Z",
          "updatedAt": "2024-10-28T10:44:22.067Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-71.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6afa042af00026886e12"
      },
      {
        "content": {
          "id": "671f6b16ee5ee5f3d8e8a987",
          "publicURL": "/payload/galleryMedia/West Ham-102.webp",
          "filename": "West Ham-102.webp",
          "mimeType": "image/webp",
          "filesize": 170134,
          "width": 937,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:44:38.119Z",
          "updatedAt": "2024-10-28T10:44:38.119Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-102.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b0a042af00026886e13"
      },
      {
        "content": {
          "id": "671f6b27ee5ee5f3d8e8a9b1",
          "publicURL": "/payload/galleryMedia/West Ham-103.webp",
          "filename": "West Ham-103.webp",
          "mimeType": "image/webp",
          "filesize": 143198,
          "width": 2109,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:44:55.219Z",
          "updatedAt": "2024-10-28T10:44:55.219Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-103.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b18042af00026886e14"
      },
      {
        "content": {
          "id": "671f6b36ee5ee5f3d8e8a9d8",
          "publicURL": "/payload/galleryMedia/West Ham-108.webp",
          "filename": "West Ham-108.webp",
          "mimeType": "image/webp",
          "filesize": 87440,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:45:10.049Z",
          "updatedAt": "2024-10-28T10:45:10.049Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-108.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b2a042af00026886e15"
      },
      {
        "content": {
          "id": "671f6b6fee5ee5f3d8e8aa30",
          "publicURL": "/payload/galleryMedia/West Ham-137.webp",
          "filename": "West Ham-137.webp",
          "mimeType": "image/webp",
          "filesize": 251446,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:46:07.726Z",
          "updatedAt": "2024-10-28T10:46:07.726Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-137.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b60042af00026886e17"
      },
      {
        "content": {
          "id": "671f6b7fee5ee5f3d8e8aa5a",
          "publicURL": "/payload/galleryMedia/West Ham-139.webp",
          "filename": "West Ham-139.webp",
          "mimeType": "image/webp",
          "filesize": 437342,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:46:23.530Z",
          "updatedAt": "2024-10-28T10:46:23.530Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-139.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b71042af00026886e18"
      },
      {
        "content": {
          "id": "671f6b8dee5ee5f3d8e8aa84",
          "publicURL": "/payload/galleryMedia/West Ham-146.webp",
          "filename": "West Ham-146.webp",
          "mimeType": "image/webp",
          "filesize": 76264,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:46:37.788Z",
          "updatedAt": "2024-10-28T10:46:37.788Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-146.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b81042af00026886e19"
      },
      {
        "content": {
          "id": "671f6b9dee5ee5f3d8e8aaae",
          "publicURL": "/payload/galleryMedia/West Ham-154.webp",
          "filename": "West Ham-154.webp",
          "mimeType": "image/webp",
          "filesize": 601190,
          "width": 2109,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:46:53.147Z",
          "updatedAt": "2024-10-28T10:46:53.147Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-154.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b8f042af00026886e1a"
      },
      {
        "content": {
          "id": "671f6babee5ee5f3d8e8aad8",
          "publicURL": "/payload/galleryMedia/West Ham-156.webp",
          "filename": "West Ham-156.webp",
          "mimeType": "image/webp",
          "filesize": 280152,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:47:07.605Z",
          "updatedAt": "2024-10-28T10:47:07.605Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-156.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6b9f042af00026886e1b"
      },
      {
        "content": {
          "id": "671f6bc2ee5ee5f3d8e8ab02",
          "publicURL": "/payload/galleryMedia/West Ham-166.webp",
          "filename": "West Ham-166.webp",
          "mimeType": "image/webp",
          "filesize": 449578,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:47:30.189Z",
          "updatedAt": "2024-10-28T10:47:30.189Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-166.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6bad042af00026886e1c"
      },
      {
        "content": {
          "id": "671f6bcfee5ee5f3d8e8ab2c",
          "publicURL": "/payload/galleryMedia/West Ham-169.webp",
          "filename": "West Ham-169.webp",
          "mimeType": "image/webp",
          "filesize": 120850,
          "width": 938,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:47:43.700Z",
          "updatedAt": "2024-10-28T10:47:43.700Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-169.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6bc3042af00026886e1d"
      },
      {
        "content": {
          "id": "671f6be9ee5ee5f3d8e8ab5a",
          "publicURL": "/payload/galleryMedia/West Ham-175.webp",
          "filename": "West Ham-175.webp",
          "mimeType": "image/webp",
          "filesize": 246432,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:48:09.641Z",
          "updatedAt": "2024-10-28T10:48:09.641Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-175.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6bd3042af00026886e1e"
      },
      {
        "content": {
          "id": "671f6bf8ee5ee5f3d8e8ab84",
          "publicURL": "/payload/galleryMedia/West Ham-176.webp",
          "filename": "West Ham-176.webp",
          "mimeType": "image/webp",
          "filesize": 324582,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:48:24.324Z",
          "updatedAt": "2024-10-28T10:48:24.324Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-176.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6beb042af00026886e1f"
      },
      {
        "content": {
          "id": "671f6c08ee5ee5f3d8e8abab",
          "publicURL": "/payload/galleryMedia/West Ham-181.webp",
          "filename": "West Ham-181.webp",
          "mimeType": "image/webp",
          "filesize": 178388,
          "width": 2109,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:48:40.515Z",
          "updatedAt": "2024-10-28T10:48:40.515Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-181.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6bfa042af00026886e20"
      },
      {
        "content": {
          "id": "671f6c19ee5ee5f3d8e8abd5",
          "publicURL": "/payload/galleryMedia/West Ham-216.webp",
          "filename": "West Ham-216.webp",
          "mimeType": "image/webp",
          "filesize": 172206,
          "width": 938,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:48:57.993Z",
          "updatedAt": "2024-10-28T10:48:57.993Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-216.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6c0a042af00026886e21"
      },
      {
        "content": {
          "id": "671f6c29ee5ee5f3d8e8abfc",
          "publicURL": "/payload/galleryMedia/West Ham-219.webp",
          "filename": "West Ham-219.webp",
          "mimeType": "image/webp",
          "filesize": 306294,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:49:13.190Z",
          "updatedAt": "2024-10-28T10:49:13.190Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-219.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6c1c042af00026886e22"
      },
      {
        "content": {
          "id": "671f6c4dee5ee5f3d8e8ac2e",
          "publicURL": "/payload/galleryMedia/West Ham-234.webp",
          "filename": "West Ham-234.webp",
          "mimeType": "image/webp",
          "filesize": 431338,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:49:49.329Z",
          "updatedAt": "2024-10-28T10:49:49.329Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-234.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6c2d042af00026886e23"
      },
      {
        "content": {
          "id": "671f6c61ee5ee5f3d8e8ac5c",
          "publicURL": "/payload/galleryMedia/West Ham-270.webp",
          "filename": "West Ham-270.webp",
          "mimeType": "image/webp",
          "filesize": 296956,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:50:09.219Z",
          "updatedAt": "2024-10-28T10:50:09.219Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-270.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6c50042af00026886e24"
      },
      {
        "content": {
          "id": "671f6c70ee5ee5f3d8e8ac82",
          "publicURL": "/payload/galleryMedia/West Ham-273.webp",
          "filename": "West Ham-273.webp",
          "mimeType": "image/webp",
          "filesize": 361786,
          "width": 2108,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:50:24.327Z",
          "updatedAt": "2024-10-28T10:50:24.327Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-273.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6c53042af00026886e25"
      },
      {
        "content": {
          "id": "671f6c7fee5ee5f3d8e8acac",
          "publicURL": "/payload/galleryMedia/West Ham-278.webp",
          "filename": "West Ham-278.webp",
          "mimeType": "image/webp",
          "filesize": 314746,
          "width": 2109,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:50:39.325Z",
          "updatedAt": "2024-10-28T10:50:39.325Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-278.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6c72042af00026886e26"
      },
      {
        "content": {
          "id": "671f6c8fee5ee5f3d8e8acd6",
          "publicURL": "/payload/galleryMedia/West Ham-325.webp",
          "filename": "West Ham-325.webp",
          "mimeType": "image/webp",
          "filesize": 234228,
          "width": 2109,
          "height": 1406,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-28T10:50:55.142Z",
          "updatedAt": "2024-10-28T10:50:55.142Z",
          "url": "https://luna.vmistudio.com/api/galleryMedia/file/West Ham-325.webp",
          "thumbnailURL": null
        },
        "category": "Lifestyle",
        "id": "671f6c81042af00026886e27"
      }
    ]
  },
  "mapData": {
    "relationTo": "map",
    "name": "Map - West Ham",
    "feature": "googleMaps",
    "mapSettings": {
      "zoom": 16,
      "unitSystem": "IMPERIAL",
      "withDirections": true
    },
    "mapPOIS": {
      "poiSettings": {
        "poiVisibility": "scaleActive",
        "poiIcon": {
          "id": "6712503129ce53533d124f74",
          "filename": "poi.png",
          "mimeType": "image/png",
          "filesize": 4083,
          "width": 60,
          "height": 60,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-18T12:10:25.577Z",
          "updatedAt": "2024-10-18T12:10:25.577Z",
          "url": "https://luna.vmistudio.com/api/organisationMedia/file/poi.png",
          "thumbnailURL": null
        }
      },
      "mainMarker": {
        "place": "West Ham Village",
        "description": "West Ham Village Development Location",
        "iconPath": {
          "id": "671a0b398168a18eb3451545",
          "publicURL": "/payload/organisationMedia/logo-2.png",
          "filename": "logo-2.png",
          "mimeType": "image/png",
          "filesize": 45795,
          "width": 2638,
          "height": 554,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-24T08:54:17.837Z",
          "updatedAt": "2024-10-24T08:54:17.837Z",
          "url": "https://luna.vmistudio.com/api/organisationMedia/file/logo-2.png",
          "thumbnailURL": null
        },
        "coordinates": {
          "lat": 51.52925,
          "lng": 0.00871
        }
      },
      "pois": [
        {
          "place": "Star Lane Pizza Bar",
          "description": " ",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.5213023534721,
            "lng": 0.0025663613635026825
          },
          "id": "6720a22d042af00026887411"
        },
        {
          "place": "Studio Baristas",
          "description": "",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.521520037523786,
            "lng": 0.001910373003560795
          },
          "id": "6720a2cc042af00026887481"
        },
        {
          "place": "Yapix",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.51489,
            "lng": 0.01058
          },
          "id": "6720a3fa042af00026887560"
        },
        {
          "place": "Rosetta Arts",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.52513,
            "lng": 0.00756
          },
          "id": "6720a43c042af00026887561"
        },
        {
          "place": "Cody Dock",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.51914,
            "lng": -0.00169
          },
          "id": "6720a45a042af00026887562"
        },
        {
          "place": "Arch1",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.52258,
            "lng": 0.00496
          },
          "id": "6720a4d8042af000268875d2"
        },
        {
          "place": "LDN East",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.51718,
            "lng": 0.00452
          },
          "id": "6720a505042af000268875d3"
        },
        {
          "place": "East London RFC",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.5293,
            "lng": 0.0109
          },
          "id": "6720a598042af00026887643"
        },
        {
          "place": "New Docklands Steam Baths",
          "category": "Leisure",
          "coordinates": {
            "lat": 51.5174,
            "lng": 0.00545
          },
          "id": "6720a5e7042af00026887722"
        },
        {
          "place": "Memorial Park",
          "category": "Nature",
          "coordinates": {
            "lat": 51.52628,
            "lng": 0.01037
          },
          "id": "6720a656042af00026887723"
        },
        {
          "place": "Westfield Stratford",
          "category": "Retail",
          "coordinates": {
            "lat": 51.54238,
            "lng": -0.00436
          },
          "id": "6720a759042af00026887793"
        },
        {
          "place": "Morrisons Canning Town",
          "category": "Retail",
          "coordinates": {
            "lat": 51.51676,
            "lng": 0.01466
          },
          "id": "6720a7de042af00026887794"
        },
        {
          "place": "Hackney Marshes",
          "category": "Nature",
          "coordinates": {
            "lat": 51.55837,
            "lng": -0.02911
          },
          "id": "6720a857042af00026887804"
        },
        {
          "place": "West Ham Park",
          "category": "Nature",
          "coordinates": {
            "lat": 51.54,
            "lng": 0.02013
          },
          "id": "6720a8c1042af00026887874"
        },
        {
          "place": "Grange Food and Wine",
          "category": "Retail",
          "coordinates": {
            "lat": 51.52532,
            "lng": 0.01241
          },
          "id": "6720a97e042af000268878e4"
        }
      ]
    },
    "mapStyling": {
      "styleKey": "silver",
      "style": [],
      "customColors": {
        "landscapeColor": "#ebc000",
        "waterColor": "#1b00ff",
        "parksColor": "#ffdd01",
        "arterialColor": "#ffffff",
        "localColor": "#ffffff",
        "labelsColor": "#eae7d3"
      }
    },
    "categories": [
      "Leisure",
      "Retail",
      "Nature"
    ]
  },
  "residencesData": {
    "relationTo": "residences",
    "feature": "residencesType1",
    "categories": [
      "Floorplan",
      "View",
      "Specifications"
    ],
    "name": "Residences - West Ham"
  },
  "orbitData": {
    "relationTo": "orbits",
    "name": "Orbits - West Ham",
    "feature": "orbit3d",
    "cameraPoints": [
      {
        "title": "Welcome to West Ham Village.",
        "subTitle": "A remarkable collection of Shared Ownership apartments situated in Zone 2",
        "body": "Just moments from West Ham station in Berkeley’s TwelveTrees Park, discover this exciting new neighbourhood. With 12 acres of parkland, shops, and restaurants, this well-connected area of east London blends urban life with tranquillity. Isn’t it time you came home to your peaceful oasis in the city?",
        "positions": {
          "x": 0.13716703312747883,
          "y": 0.13776017086592215,
          "z": 0.16171126459442597
        },
        "targets": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "id": "671246277330ed002d7eda56"
      },
      {
        "title": "East London's",
        "subTitle": "Best kept Secret",
        "body": "With its rich heritage, diverse creative scene, and proximity to stunning green and blue spaces, West Ham combines urban convenience with a welcoming atmosphere. Discover the hidden gems and thriving energy that make West Ham an exceptional place to live, work, and play.",
        "positions": {
          "x": 0.040955497008338566,
          "y": 0.16083660438919942,
          "z": 0.24380504491982283
        },
        "targets": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "id": "671246357330ed002d7eda59"
      },
      {
        "title": "Refreshing Connections",
        "subTitle": "Where nature and community blooms",
        "body": "West Ham is set to become one of east London’s most dynamic locations. With excellent transport links, vibrant culture, and picturesque waterside walks, there’s so much to explore and enjoy.",
        "positions": {
          "x": -0.0967079247979765,
          "y": 0.15279397695919308,
          "z": 0.2329983773790439
        },
        "targets": {
          "x": 0,
          "y": 0,
          "z": 0
        },
        "id": "671246437330ed002d7eda5c"
      }
    ],
    "touchPoints": []
  },
  "splashData": {
    "relationTo": "splash",
    "name": "Splash - West Ham",
    "heroCopy": "STUDIO, 1&2 BEDROOM apartments",
    "heroCopyFont": "primaryFont",
    "heroCopyColour": "white",
    "heroCopyStyle": "italicEnd",
    "buttonBgColour": "accent",
    "buttonWidth": 4,
    "buttonBgLink": "orbits",
    "logo": "lightColourLogo",
    "logoWidth": 50,
    "splashBg": "image/video",
    "splashBgMediaOpacity": 1,
    "splashBgMedia": "6712785a1a3e7c7399913365"
  },
  "availabilityData": {
    "relationTo": "unitAvailability",
    "name": "Unit Availability - West Ham",
    "data": [
      {
        "unitName": "N01B-04-01",
        "price": 200000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af000268898fa"
      },
      {
        "unitName": "N01B-04-02",
        "price": 300000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af000268898fb"
      },
      {
        "unitName": "N01B-04-03",
        "price": 350000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 3-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af000268898fc"
      },
      {
        "unitName": "N01B-04-04",
        "price": 280000,
        "floor": 4,
        "beds": 0,
        "baths": null,
        "unitType": "TYPE 1-STUDIO",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af000268898fd"
      },
      {
        "unitName": "N01B-04-05",
        "price": 355000,
        "floor": 4,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af000268898fe"
      },
      {
        "unitName": "N01B-04-06",
        "price": 384000,
        "floor": 4,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 2-1B/2P",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af000268898ff"
      },
      {
        "unitName": "N01B-04-07",
        "price": 413000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889900"
      },
      {
        "unitName": "N01B-04-08",
        "price": 442000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/3P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889901"
      },
      {
        "unitName": "N01B-05-01",
        "price": 471000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889902"
      },
      {
        "unitName": "N01B-05-02",
        "price": 500000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889903"
      },
      {
        "unitName": "N01B-05-03",
        "price": 529000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 3-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889904"
      },
      {
        "unitName": "N01B-05-04",
        "price": 558000,
        "floor": 5,
        "beds": 0,
        "baths": null,
        "unitType": "TYPE 1-STUDIO",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889905"
      },
      {
        "unitName": "N01B-05-05",
        "price": 587000,
        "floor": 5,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889906"
      },
      {
        "unitName": "N01B-05-06",
        "price": 616000,
        "floor": 5,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 2-1B/2P",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889907"
      },
      {
        "unitName": "N01B-05-07",
        "price": 645000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889908"
      },
      {
        "unitName": "N01B-05-08",
        "price": 674000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/3P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889909"
      },
      {
        "unitName": "N01B-06-01",
        "price": 703000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688990a"
      },
      {
        "unitName": "N01B-06-02",
        "price": 732000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688990b"
      },
      {
        "unitName": "N01B-06-03",
        "price": 761000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 3-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688990c"
      },
      {
        "unitName": "N01B-06-04",
        "price": 790000,
        "floor": 6,
        "beds": 0,
        "baths": null,
        "unitType": "TYPE 1-STUDIO",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688990d"
      },
      {
        "unitName": "N01B-06-05",
        "price": 819000,
        "floor": 6,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688990e"
      },
      {
        "unitName": "N01B-06-06",
        "price": 848000,
        "floor": 6,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 2-1B/2P",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688990f"
      },
      {
        "unitName": "N01B-06-07",
        "price": 877000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889910"
      },
      {
        "unitName": "N01B-06-08",
        "price": 906000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/3P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889911"
      },
      {
        "unitName": "N01B-07-01",
        "price": 935000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889912"
      },
      {
        "unitName": "N01B-07-02",
        "price": 964000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889913"
      },
      {
        "unitName": "N01B-07-03",
        "price": 993000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 3-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889914"
      },
      {
        "unitName": "N01B-07-04",
        "price": 1022000,
        "floor": 7,
        "beds": 0,
        "baths": null,
        "unitType": "TYPE 1-STUDIO",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889915"
      },
      {
        "unitName": "N01B-07-05",
        "price": 1051000,
        "floor": 7,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889916"
      },
      {
        "unitName": "N01B-07-06",
        "price": 1080000,
        "floor": 7,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 2-1B/2P",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889917"
      },
      {
        "unitName": "N01B-07-07",
        "price": 1109000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889918"
      },
      {
        "unitName": "N01B-07-08",
        "price": 1138000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/3P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889919"
      },
      {
        "unitName": "N01B-08-01",
        "price": 1167000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688991a"
      },
      {
        "unitName": "N01B-08-02",
        "price": 1196000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688991b"
      },
      {
        "unitName": "N01B-08-03",
        "price": 1225000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 3-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688991c"
      },
      {
        "unitName": "N01B-08-04",
        "price": 1254000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 4-2B/4P",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688991d"
      },
      {
        "unitName": "N01B-08-05",
        "price": 1283000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 5-2B/4P",
        "aspect": "NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688991e"
      },
      {
        "unitName": "N01B-08-06",
        "price": 1312000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "NW/SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688991f"
      },
      {
        "unitName": "N01B-08-07",
        "price": 1341000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/3P",
        "aspect": "SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889920"
      },
      {
        "unitName": "N01B-09-01",
        "price": 1370000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889921"
      },
      {
        "unitName": "N01B-09-02",
        "price": 1399000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889922"
      },
      {
        "unitName": "N01B-09-03",
        "price": 1428000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 3-2B/4P",
        "aspect": "NW/SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889923"
      },
      {
        "unitName": "N01B-09-04",
        "price": 1457000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 4-2B/4P",
        "aspect": "NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889924"
      },
      {
        "unitName": "N01B-09-05",
        "price": 1486000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 5-2B/4P",
        "aspect": "NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889925"
      },
      {
        "unitName": "N01B-09-06",
        "price": 1515000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "NW/SE",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889926"
      },
      {
        "unitName": "N01B-09-07",
        "price": 1544000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/3P",
        "aspect": "SE",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889927"
      },
      {
        "unitName": "S01C-02-01",
        "price": 1573000,
        "floor": 2,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889928"
      },
      {
        "unitName": "S01C-02-02",
        "price": 1602000,
        "floor": 2,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 2-1B/2P",
        "aspect": "E/SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889929"
      },
      {
        "unitName": "S01C-02-03",
        "price": 1631000,
        "floor": 2,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688992a"
      },
      {
        "unitName": "S01C-02-04",
        "price": 1660000,
        "floor": 2,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2 V-2B/3P",
        "aspect": "SW/NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688992b"
      },
      {
        "unitName": "S01C-02-05",
        "price": 1689000,
        "floor": 2,
        "beds": 0,
        "baths": null,
        "unitType": "TYPE 1-STUDIO",
        "aspect": "NW/E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688992c"
      },
      {
        "unitName": "S01C-03-01",
        "price": 1718000,
        "floor": 3,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688992d"
      },
      {
        "unitName": "S01C-03-02",
        "price": 1747000,
        "floor": 3,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 3-2B/3P",
        "aspect": "E/SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688992e"
      },
      {
        "unitName": "S01C-03-03",
        "price": 1776000,
        "floor": 3,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688992f"
      },
      {
        "unitName": "S01C-03-04",
        "price": 1805000,
        "floor": 3,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889930"
      },
      {
        "unitName": "S01C-03-05",
        "price": 1834000,
        "floor": 3,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889931"
      },
      {
        "unitName": "S01C-04-01",
        "price": 1863000,
        "floor": 4,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889932"
      },
      {
        "unitName": "S01C-04-02",
        "price": 1892000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889933"
      },
      {
        "unitName": "S01C-04-03",
        "price": 1921000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889934"
      },
      {
        "unitName": "S01C-04-04",
        "price": 1950000,
        "floor": 4,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889935"
      },
      {
        "unitName": "S01C-04-05",
        "price": 1979000,
        "floor": 4,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889936"
      },
      {
        "unitName": "S01C-05-01",
        "price": 2008000,
        "floor": 5,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889937"
      },
      {
        "unitName": "S01C-05-02",
        "price": 2037000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889938"
      },
      {
        "unitName": "S01C-05-03",
        "price": 2066000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889939"
      },
      {
        "unitName": "S01C-05-04",
        "price": 2095000,
        "floor": 5,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688993a"
      },
      {
        "unitName": "S01C-05-05",
        "price": 2124000,
        "floor": 5,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688993b"
      },
      {
        "unitName": "S01C-06-01",
        "price": 2153000,
        "floor": 6,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688993c"
      },
      {
        "unitName": "S01C-06-02",
        "price": 2182000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688993d"
      },
      {
        "unitName": "S01C-06-03",
        "price": 2211000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688993e"
      },
      {
        "unitName": "S01C-06-04",
        "price": 2240000,
        "floor": 6,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688993f"
      },
      {
        "unitName": "S01C-06-05",
        "price": 2269000,
        "floor": 6,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889940"
      },
      {
        "unitName": "S01C-07-01",
        "price": 2298000,
        "floor": 7,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889941"
      },
      {
        "unitName": "S01C-07-02",
        "price": 2327000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889942"
      },
      {
        "unitName": "S01C-07-03",
        "price": 2356000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889943"
      },
      {
        "unitName": "S01C-07-04",
        "price": 2385000,
        "floor": 7,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889944"
      },
      {
        "unitName": "S01C-07-05",
        "price": 2414000,
        "floor": 7,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889945"
      },
      {
        "unitName": "S01C-08-01",
        "price": 2443000,
        "floor": 8,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889946"
      },
      {
        "unitName": "S01C-08-02",
        "price": 2472000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889947"
      },
      {
        "unitName": "S01C-08-03",
        "price": 2501000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889948"
      },
      {
        "unitName": "S01C-08-04",
        "price": 2530000,
        "floor": 8,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889949"
      },
      {
        "unitName": "S01C-08-05",
        "price": 2559000,
        "floor": 8,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688994a"
      },
      {
        "unitName": "S01C-09-01",
        "price": 2588000,
        "floor": 9,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688994b"
      },
      {
        "unitName": "S01C-09-02",
        "price": 2617000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688994c"
      },
      {
        "unitName": "S01C-09-03",
        "price": 2646000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688994d"
      },
      {
        "unitName": "S01C-09-04",
        "price": 2675000,
        "floor": 9,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688994e"
      },
      {
        "unitName": "S01C-09-05",
        "price": 2704000,
        "floor": 9,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688994f"
      },
      {
        "unitName": "S01C-10-01",
        "price": 2733000,
        "floor": 10,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889950"
      },
      {
        "unitName": "S01C-10-02",
        "price": 2762000,
        "floor": 10,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889951"
      },
      {
        "unitName": "S01C-10-03",
        "price": 2791000,
        "floor": 10,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889952"
      },
      {
        "unitName": "S01C-10-04",
        "price": 2820000,
        "floor": 10,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889953"
      },
      {
        "unitName": "S01C-10-05",
        "price": 2849000,
        "floor": 10,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889954"
      },
      {
        "unitName": "S01C-11-01",
        "price": 2878000,
        "floor": 11,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889955"
      },
      {
        "unitName": "S01C-11-02",
        "price": 2907000,
        "floor": 11,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889956"
      },
      {
        "unitName": "S01C-11-03",
        "price": 2936000,
        "floor": 11,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889957"
      },
      {
        "unitName": "S01C-11-04",
        "price": 2965000,
        "floor": 11,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889958"
      },
      {
        "unitName": "S01C-11-05",
        "price": 2994000,
        "floor": 11,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889959"
      },
      {
        "unitName": "S01C-12-01",
        "price": 3023000,
        "floor": 12,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688995a"
      },
      {
        "unitName": "S01C-12-02",
        "price": 3052000,
        "floor": 12,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688995b"
      },
      {
        "unitName": "S01C-12-03",
        "price": 3081000,
        "floor": 12,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688995c"
      },
      {
        "unitName": "S01C-12-04",
        "price": 3110000,
        "floor": 12,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688995d"
      },
      {
        "unitName": "S01C-12-05",
        "price": 3139000,
        "floor": 12,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af0002688995e"
      },
      {
        "unitName": "S01C-13-01",
        "price": 3168000,
        "floor": 13,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af0002688995f"
      },
      {
        "unitName": "S01C-13-02",
        "price": 3197000,
        "floor": 13,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889960"
      },
      {
        "unitName": "S01C-13-03",
        "price": 3226000,
        "floor": 13,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889961"
      },
      {
        "unitName": "S01C-13-04",
        "price": 3255000,
        "floor": 13,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889962"
      },
      {
        "unitName": "S01C-13-05",
        "price": 3284000,
        "floor": 13,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889963"
      },
      {
        "unitName": "S01C-14-01",
        "price": 3313000,
        "floor": 14,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 1-1B/2P",
        "aspect": "E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889964"
      },
      {
        "unitName": "S01C-14-02",
        "price": 3342000,
        "floor": 14,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/4P",
        "aspect": "E/SW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889965"
      },
      {
        "unitName": "S01C-14-03",
        "price": 3371000,
        "floor": 14,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 1-2B/4P",
        "aspect": "SW",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889966"
      },
      {
        "unitName": "S01C-14-04",
        "price": 3400000,
        "floor": 14,
        "beds": 2,
        "baths": null,
        "unitType": "TYPE 2-2B/3P",
        "aspect": "SW/NW",
        "viewID": "C-D-12m",
        "status": "Available",
        "id": "67236e89042af00026889967"
      },
      {
        "unitName": "S01C-14-05",
        "price": 3429000,
        "floor": 14,
        "beds": 1,
        "baths": null,
        "unitType": "TYPE 3-1B/2P",
        "aspect": "NW/E",
        "viewID": "A-B-3m",
        "status": "Available",
        "id": "67236e89042af00026889968"
      }
    ],
    "csv": null
  },
  "typeData": {
    "relationTo": "unitTypes",
    "name": "Unit Types - West Ham",
    "unitTypes": [
      {
        "name": "TYPE 1-1B/2P ",
        "beds": 1,
        "baths": 1,
        "floorplan": {
          "id": "671a0ae18168a18eb34513b2",
          "publicURL": "/payload/floorPlanMedia/1.png",
          "filename": "1.png",
          "mimeType": "image/png",
          "filesize": 3925308,
          "width": 4120,
          "height": 2240,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-24T08:52:49.503Z",
          "updatedAt": "2024-10-24T08:52:49.503Z",
          "url": "https://luna.vmistudio.com/api/floorPlanMedia/file/1.png",
          "thumbnailURL": null
        },
        "id": "6712588d52326f002648a81b"
      },
      {
        "name": "TYPE 2-2B/3P",
        "beds": 2,
        "baths": 2,
        "floorplan": {
          "id": "671a0ae98168a18eb34513d3",
          "publicURL": "/payload/floorPlanMedia/2.png",
          "filename": "2.png",
          "mimeType": "image/png",
          "filesize": 3141289,
          "width": 4120,
          "height": 2240,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-24T08:52:57.247Z",
          "updatedAt": "2024-10-24T08:52:57.247Z",
          "url": "https://luna.vmistudio.com/api/floorPlanMedia/file/2.png",
          "thumbnailURL": null
        },
        "id": "67125d9752326f002648a98a"
      }
    ]
  },
  "viewsData": {
    "relationTo": "unitViews",
    "name": "Unit Views - West Ham",
    "viewMedia": [
      {
        "image": {
          "id": "671910c1535ac37b8d5341e2",
          "publicURL": "/payload/viewMedia/B-3m.webp",
          "filename": "B-3m.webp",
          "mimeType": "image/webp",
          "filesize": 1940010,
          "width": 6778,
          "height": 2357,
          "focalX": 50,
          "focalY": 50,
          "createdAt": "2024-10-23T15:05:37.338Z",
          "updatedAt": "2024-10-23T15:05:37.338Z",
          "url": "https://luna.vmistudio.com/api/viewMedia/file/B-3m.webp",
          "thumbnailURL": null
        },
        "id": "671276ac9df70d002647d950"
      }
    ]
  },
  "detailsData": {
    "relationTo": "unitDetails",
    "name": "Unit Details - West Ham"
  },
  "navRoutes": [
    {
      "path": "orbits",
      "feature": "orbit3d",
      "layout": "fullscreen",
      "label": "Destination",
      "include": "true"
    },
    {
      "path": "map",
      "feature": "googleMaps",
      "layout": "fullscreen",
      "label": "Transport & Lifestyle",
      "include": "true"
    },
    {
      "path": "residences",
      "feature": "residencesType1",
      "layout": "fullscreen",
      "label": "Apartment Finder",
      "include": "true"
    },
    {
      "path": "gallery",
      "feature": "masonrygallery",
      "layout": "fullscreen",
      "label": "Gallery",
      "include": "true"
    },
    {
      "path": "favourites",
      "feature": "favourites",
      "layout": "fullscreen",
      "label": "Favourites",
      "include": "true"
    },
    {
      "path": "explorer",
      "feature": null,
      "layout": "fullscreen",
      "label": "Explorer",
      "include": "false"
    },
    {
      "path": "development",
      "feature": "development",
      "layout": "fullscreen",
      "label": "Development",
      "include": "false"
    },
    {
      "path": "sharedOwnership",
      "feature": "sharedOwnership",
      "layout": "fullscreen",
      "label": "Shared Ownership",
      "include": "true"
    },
    {
      "path": "about",
      "feature": "about",
      "layout": "fullscreen",
      "label": "Peabody & Partners",
      "include": "true"
    },
    {
      "path": "info1",
      "feature": "info1",
      "layout": "fullscreen",
      "label": "Info1 Page",
      "include": "false"
    }
  ],
  "allRoutes": [
    {
      "path": "/",
      "feature": "fullscreen-splash",
      "layout": "fullscreen",
      "include": "true"
    },
    {
      "path": "orbits",
      "feature": "orbit3d",
      "layout": "fullscreen",
      "label": "Destination",
      "include": "true"
    },
    {
      "path": "map",
      "feature": "googleMaps",
      "layout": "fullscreen",
      "label": "Transport & Lifestyle",
      "include": "true"
    },
    {
      "path": "residences",
      "feature": "residencesType1",
      "layout": "fullscreen",
      "label": "Apartment Finder",
      "include": "true"
    },
    {
      "path": "gallery",
      "feature": "masonrygallery",
      "layout": "fullscreen",
      "label": "Gallery",
      "include": "true"
    },
    {
      "path": "favourites",
      "feature": "favourites",
      "layout": "fullscreen",
      "label": "Favourites",
      "include": "true"
    },
    {
      "path": "explorer",
      "feature": null,
      "layout": "fullscreen",
      "label": "Explorer",
      "include": "false"
    },
    {
      "path": "development",
      "feature": "development",
      "layout": "fullscreen",
      "label": "Development",
      "include": "false"
    },
    {
      "path": "sharedOwnership",
      "feature": "sharedOwnership",
      "layout": "fullscreen",
      "label": "Shared Ownership",
      "include": "true"
    },
    {
      "path": "about",
      "feature": "about",
      "layout": "fullscreen",
      "label": "Peabody & Partners",
      "include": "true"
    },
    {
      "path": "info1",
      "feature": "info1",
      "layout": "fullscreen",
      "label": "Info1 Page",
      "include": "false"
    }
  ]
};
  