import Map from "./components/Map"
import { MapSidebar } from "./components/MapSidebar"
import { useEffect, useRef, useState } from "react"
import { Place } from "./mapStore"
import { useStore } from "@state/store"
import { initMap, loadScript } from "@lib/utils/google.maps"
import { styles } from "./mapStyles"
import { useProjectDataStore } from "@state/projectDataStore"
import { useMapStore } from "./mapStore"

const GoogleMaps = (props: any) => {
  const { mapData } = useProjectDataStore((s) => ({
    mapData: s.mapData,
  }))
  const { setMapInstance } = useMapStore((s) => ({
    setMapInstance: s.setMapinstance,
  }))

  const { mapStyling, mapPOIS, mapSettings, categories = [] } = mapData || {}
  const [places, setPlaces] = useState<Place[]>([])
  const [filteredPOIS, setFilteredPOIS] = useState<Place[]>([])
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [poiFilterObj, setPoiFilterObj] = useState(0)
  const [directionsRenderer, setDirectionsRenderer] =
    useState<google.maps.DirectionsRenderer | null>(null)

  const panelRef = useRef<HTMLDivElement>(null)
  const mapRef = useRef<HTMLDivElement>(null)

  const syncState = useStore((s) => s.syncState)

  // Ensure categories is an array, if it's not, use an empty array
  // this is to prevent the app from crashing if the categories are not set
  const safeCategories = Array.isArray(categories) ? categories : []
  const categoriesWithAll = ["All", ...safeCategories]

  useEffect(() => {
    syncState({
      contentControls: {
        categories: categoriesWithAll,
        index: 0,
      },
    })
    return () => {
      syncState({
        contentControls: null,
      })
    }
  }, [categories])

  useEffect(() => {
    const initializeMap = async () => {
      if (window.google && window.google.maps) {
        const mapInstance = await initMap({
          mapRef: mapRef,
          mainMarker: mapPOIS?.mainMarker,
          zoom: mapSettings?.zoom,
          center: mapPOIS?.mainMarker.coordinates,
          disableDefaultUI: true,
          mapTypeControl: false,
          mapStyling: mapStyling?.styling,
        })
        mapInstance.setOptions({
          minZoom: 13,
          maxZoom: 18
        })
        const color = getComputedStyle(document.body).getPropertyValue(
          "--secondary",
        )
        const directionsRendererInstance =
        new window.google.maps.DirectionsRenderer()
        directionsRendererInstance.setMap(mapInstance)
        directionsRendererInstance.setPanel(panelRef.current!)
        directionsRendererInstance.setOptions({
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: color,
            strokeWeight: 4,
            strokeOpacity: 1,
          },
          suppressInfoWindows: true,
        })
        setDirectionsRenderer(directionsRendererInstance)
        
        setMapInstance(mapInstance)
        setMap(mapInstance)
      }
    }

    loadScript()
      .then(initializeMap)
      .catch((err) => console.error("Error loading Google Maps script:", err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => setPoiFilterObj(0)
  }, [])

  useEffect(() => {
    setFilteredPOIS(places)
    const filterKey = "WALKING"
    const range = [
      filterTags[poiFilterObj].minDistanceMiles,
      filterTags[poiFilterObj].maxDistanceMiles,
    ]
    const filtered = places.filter((place) => {
      const distance = Number(place.distances[filterKey].distance.split(" ")[0])
      return distance >= range[0] && distance < range[1]
    })
    setFilteredPOIS(filtered)
  }, [poiFilterObj, places])

  return (
    <>
      <div className="list-wr">
        {map && (
          <MapSidebar
            categories={categoriesWithAll}
            withDirections={mapSettings?.withDirections}
            mainMarker={mapPOIS?.mainMarker}
            // this must be set by the MAP so you can get the distances
            // the distances are calculated by the map
            places={filteredPOIS}
            panel={panelRef}
          />
        )}
      </div>
      <div className="map-wr">
        <Map
          map={map}
          directionsRenderer={directionsRenderer}
          categories={categoriesWithAll}
          mapSettings={mapSettings}
          mapStyling={{
            style: styles["west-ham"],
            // style: styles[mapStyling?.styleKey],
            // customColors: mapStyling?.customColors,
          }}
          mapPOIS={mapPOIS}
          panelRef={panelRef}
          mapRef={mapRef}
          // this must be set by the MAP so you can get the distances
          // the distances are calculated by the map
          setPlaces={setPlaces}
        />
        <div className="poi-filter">
          {filterTags.map((obj, i) => {
            return (
              <div
                key={`${obj.label}${i}`}
                onClick={() => setPoiFilterObj(i)}
                className="map-categories"
                style={{
                  backgroundColor:
                    i === poiFilterObj
                      ? "rgba(255,255,255,1)"
                      : "rgba(255,255,255,0)",
                  color: i === poiFilterObj ? "var(--primary)" : "#fff",
                }}
              >
                {obj.label}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default GoogleMaps

const filterTags = [
  {
    label: "All",
    minDistanceMiles: 0,
    maxDistanceMiles: Infinity,
  },
  {
    label: "London",
    minDistanceMiles: 1.1,
    maxDistanceMiles: Infinity,
  },
  {
    label: "Local",
    minDistanceMiles: 0,
    maxDistanceMiles: 1.1,
  },
]
