import React from "react"

export interface IToggleGroupProps {
  options: string[]
  selected: string[]
  type?: string
  labels: any
  onClick: (value: string) => void
}

const ToggleGroup: React.FC<IToggleGroupProps> = (props: IToggleGroupProps) => {
  const { options, selected, type, onClick, labels } = props

  return (
    <div className="filter">
      {options.sort().map((option: string) => (
        <ToggleItem
          label={labels[option]}
          key={option}
          value={option}
          isSelected={selected?.includes(option)}
          onClick={onClick}
          type={type}
        />
      ))}
    </div>
  )
}

const ToggleItem = ({ value, isSelected, onClick, label }: any) => {
  return (
    <button
      className={`option ${isSelected ? "selected" : ""}`}
      aria-pressed={isSelected}
      onClick={() => onClick(value)}
    >
      {label}
    </button>
  )
}

export default ToggleGroup
