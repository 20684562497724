import { useProjectDataStore } from "@state/projectDataStore"
import styled from "styled-components/macro"

const UnitCopy = ({ unit }) => {
  if (!unit) return null
  const copy = unit[0]?.copy
  // console.log(unit)

  return <Wrapper>{copy}</Wrapper>
}

export default UnitCopy

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  padding: 2.75rem;
  line-height: 200%;
  letter-spacing: 1px;
  border-top: 1px solid var(--accent);
  border-left: 1px solid var(--accent);
  color: var(--black);
`
