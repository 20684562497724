import FavouritesInfoCard from "@common/components/ResidenceGeneric/FavouritesInfoCard"
import ImageDisplayCompare from "@common/components/ResidenceGeneric/ImageDisplayCompare"
import TextButton from "@common/ui/TextButton"
import Modal from "@common/ui/Modal"
import { useState } from "react"
import { useStore } from "@state/store"

type UnitSingleProps = {
  sections: any[]
  contentControls: any
  selectedRows: any[]
  removeFromFavorites: (unitName: string) => void
  className?: string
}

const UnitSingle = (props: UnitSingleProps) => {
  const { selectedRows } = props
  const syncState = useStore((s) => s.syncState)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedUnit, setSelectedUnit] = useState(null)

  if (!selectedRows) return null

  const handleModalOpen = (open: boolean, selectedUnit: any) => {
    setIsModalOpen(open)
    setSelectedUnit(selectedUnit)
  }

  return (
    <div className="unit-single-container">
      <div className="unit-single">
        <div className="info-card-container">
          <FavouritesInfoCard unit={selectedRows} />
        </div>

        <div className="image-display-container">
          <ImageDisplayCompare selectedUnit={selectedRows} />
        </div>
        <TextButton
          className="imageOverlayToggle"
          onClick={() => handleModalOpen(true, selectedRows)}
          variant="fill"
          color="primary"
          icon={
            <img
              src="assets/icons/enlarge.png"
              alt=""
              style={{ filter: "invert(100%)" }}
            />
          }
          style={{
            position: "absolute",
            bottom: "2rem",
            right: "2rem",
          }}
        />
      </div>
      <Modal
        style={{
          backgroundColor: "var(--secondary)",
          height: "100%",
          width: "100%",
          padding: "6rem"
        }}
        isFullScreen={true}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <ImageDisplayCompare
          show={setIsModalOpen}
          selectedUnit={selectedUnit}
        />
      </Modal>
    </div>
  )
}

export default UnitSingle
