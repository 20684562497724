import { create } from "zustand"

export type SortConfig = {
  field: string | null
  direction: string
}

const initialSortConfig: SortConfig = {
  field: null,
  direction: "ascending",
}

export const initialFilters = {}

interface InitialState {
  filterVisible: boolean
  residencePopup: boolean
  compareMode: boolean
  compareSection: string
  selectedRows: any[]
  orbitIndex: number
  contentIndex: number
  selectedUnit: any
  selectedBlock: string | null
  imageOverlay: string
  residenceStyleIndex: number
  residenceViewFullscreen: boolean
  galleryImagesIndex: number
  setContentIndex: (index: number) => void
  syncState: (stateToUpdate: any) => void
  contentControls: any
  filteredUnits: any
  sortConfig: SortConfig
  filters: any
  tableVisible: boolean
  aboutIndex: number
}

export const useStore = create<InitialState>((set) => ({
  splatsLoaded: false,
  filterVisible: false,
  orbitIndex: 0,
  filteredUnits: null,
  contentControls: {
    index: 0,
    categories: [],
  },
  selectedUnit: null,
  selectedBlock: "",
  galleryImagesIndex: null,
  residenceStyleIndex: 0,
  residenceViewFullscreen: false,
  sortConfig: initialSortConfig,
  filters: initialFilters,
  imageOverlay: null,
  contentIndex: 0,
  compareMode: false,
  compareSection: "floorplan",
  selectedRows: [],
  residencePopup: false,
  tableVisible: false,
  aboutIndex: 0,

  setContentIndex: (index) => {
    set({ contentIndex: index })
  },

  syncState: (stateToUpdate) => {
    set({ ...stateToUpdate })
  },
}))
