import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { useEffect, useRef } from "react"
import { EffectFade, Navigation, Pagination } from "swiper/modules"
import { useClickWithDistance } from "@common/hooks/useClickWithDistance"
import styled from "styled-components/macro"
import { useStore } from "@state/store"
import gsap from "gsap"
import { useProjectDataStore } from "@state/projectDataStore"

const Carousel = ({ galleryData, brandingData }) => {
  const slides = galleryData?.images
  const swiperRef = useRef(null)
  const syncState = useStore((s) => s.syncState)
  const categories = Array?.from(
    new Set(slides?.map((image) => image?.category)),
  )
  const contentControls = useStore((s) => s.contentControls)
  const categoryIndex = contentControls?.index
  const selectedCategory = categories[categoryIndex]
  const galleryImagesIndex = useStore((s) => s.galleryImagesIndex)

  useEffect(() => {
    if (swiperRef.current && galleryImagesIndex !== null)
      swiperRef.current.slideTo(galleryImagesIndex, 0)

    if (galleryImagesIndex !== null) {
      gsap.to(".imageOverlay", {
        autoAlpha: 1,
        pointerEvents: "all",
        duration: 0.6,
        ease: "power3.inOut",
      })
    } else {
      gsap.to(".imageOverlay", {
        autoAlpha: 0,
        pointerEvents: "none",
        duration: 0.6,
        ease: "power3.inOut",
        onComplete: () => {},
      })
    }
  }, [galleryImagesIndex])

  const { handlePointerDown, handlePointerUp } = useClickWithDistance(() =>
    syncState({ galleryImagesIndex: null }),
  )

  return (
    <ImageOverlay
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      className="imageOverlay"
    >
      <img className="close" src="/assets/icons/cross.svg" alt="Close" />
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper
        }}
        spaceBetween={50}
        slidesPerView={1}
        centeredSlides={true}
        onSlideChange={(_swiper) =>
          syncState({ galleryImagesIndex: _swiper.activeIndex })
        }
        fadeEffect={{ crossFade: true }}
        modules={[EffectFade, Navigation, Pagination]}
        speed={1000}
        style={{ height: "100%", width: "100%" }}
      >
        {slides &&
          slides
            ?.filter((image) => image?.category === selectedCategory)
            ?.map((content, index) => (
              <SwiperSlide
                key={content?.content + index?.toString()}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <img
                  style={{
                    width: "90%",
                    height: "90%",
                    objectFit: "contain",
                  }}
                  alt={content?.content?.filename}
                  src={content?.content?.url}
                />
              </SwiperSlide>
            ))}
      </Swiper>
      <span className="prompt">Swipe to Explore</span>
    </ImageOverlay>
  )
}

export default Carousel

const ImageOverlay = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  cursor: default;
  z-index: 25;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.75rem;
    margin: 2rem;
    background-color: white;
    padding: 0.75rem;
    border-radius: 100%;
    object-fit: contain;
    cursor: pointer;
    z-index: 10;
    transition: 0.3s;

    &:active {
      opacity: 0.5;
      transition: 0.3s;
      transform: scale(0.95);
    }
  }

  .prompt {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.3rem;
    color: var(--accent);
  }
`
