import { useEffect } from "react"
import { SortConfig, useStore } from "@state/store"

const useSortConfig = () => {
  const syncState = useStore((s) => s.syncState)
  const { sortConfig } = useStore((state) => ({
    sortConfig: state.sortConfig,
  }))

  const setSortConfig = (newSortConfig: SortConfig) => {
    syncState({ sortConfig: newSortConfig })
  }

  useEffect(() => {
    return () => {
      syncState({ sortConfig: { field: null, direction: "ascending" } })
    }
  }, [])
  return { setSortConfig, sortConfig }
}

export default useSortConfig
