import styled from "styled-components/macro"

export const HeaderToLabelMap = {
  unitName: "Unit",
  price: "Price",
  floor: "Floor",
  unitType: "Type",
  beds: "Beds",
  baths: "Baths",
  aspect: "Aspect",
  status: "Status",
}

const TableHeader = ({ headers, sortConfig, handleSort, columns }) => {
  return (
    <TableHeaderContainer className="flex-table-header" columns={columns}>
      {headers.map((header: string, index: number) => (
        <HeaderCell key={index} onClick={() => handleSort(header)}>
          {HeaderToLabelMap[header] || header}
          {header === sortConfig.field && (
            <Arrow
              className={`arrow ${
                sortConfig.direction === "ascending" ? "up" : "down"
              }`}
              src={"/assets/icons/up-arrow.svg"}
              alt="Arrow"
            />
          )}
        </HeaderCell>
      ))}
    </TableHeaderContainer>
  )
}

export { TableHeader }

const TableHeaderContainer = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  align-items: center;
  color: white;
  text-transform: uppercase;

  padding-top: 2rem;
  padding-left: 3em;
  padding-right: 3em;
`

const HeaderCell = styled.div`
  padding: 1rem 0;
  text-align: center;
  white-space: nowrap;
  position: relative;
  font-size: 1rem;
`

const Arrow = styled.img`
  position: absolute;
  margin-left: 0.5rem;
  margin-top: 0.15rem;
  height: 0.9em;
  width: 1em;
  transition: transform 0.2s ease-in-out;

  &.up {
    transform: rotate(0deg);
  }

  &.down {
    transform: rotate(180deg);
  }
`
