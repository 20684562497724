import ContentIndexControls from "@common/components/ContentIndexControls"
import { useProjectDataStore } from "@state/projectDataStore"
import { getColor } from "@utils/getColor"
import styled from "styled-components/macro"

const Gallery = ({ children }) => {
  const { galleryData } = useProjectDataStore((s) => ({
    galleryData: s.galleryData,
  }))
  const categories = [
    ...Array.from(
      new Set(galleryData?.images?.map((image) => image?.category)),
    ),
  ]

  const categoryColor = getColor(galleryData?.categoryColor)

  return (
    <>
      <GalleryPageWr>{children}</GalleryPageWr>
      <ControlsWr $categoryColor={categoryColor}>
        {galleryData?.categoryStyle === "type1" && (
          <ContentIndexControls
            categories={categories}
            className="galleryCategories"
          />
        )}
      </ControlsWr>
    </>
  )
}

const GalleryPageWr = styled.div`
  width: 100%;
  height: 100%;

  .grid-wrapper {
    background-size: cover;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
`

const ControlsWr = styled.div<{ $categoryColor: string }>`
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 2.5rem 2rem;
  display: flex;

  .galleryCategories {
    display: flex;
    border: 0.5px solid ${(props) => props.$categoryColor};

    .buttons {
      background-color: transparent;
      color: ${(props) => props.$categoryColor};
      padding: 0.75rem 4rem;
      border: 0.5px solid ${(props) => props.$categoryColor};
    }

    .active {
      background-color: var(--primary);
      color: ${(props) => {
        if (props.$categoryColor === "black") return "white"
        return "black"
      }};
    }
  }
`

export default Gallery
