import styled from "styled-components/macro"

const Residences = ({ children }) => {
  return <ResidencesPageWr>{children}</ResidencesPageWr>
}

const ResidencesPageWr = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;

  background-color: var(--primary);

  .residencesCategory {
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    width: calc(45% - 4rem);
    height: 4em;
    display: flex;

    .buttons {
      background-color: transparent;
      color: var(--primary);
      padding: 0.75rem 4rem;
      border: 0.5px solid var(--primary);
      width: 100%;
    }

    .active {
      background-color: var(--primary);
      color: white;
    }
  }

  .residences-table {
    pointer-events: all;
    width: 100%;
    height: 100%;

    transition: transform 0.4s ease-in-out;
    background-color: var(--primary);
    padding: 2rem;
    padding-top: 0;

    position: absolute;
    z-index: 1;
    width: 23vw;
    top: 42%;
    height: 58%;
    pointer-events: none;

    border-right: 3px solid white;

    .table {
      background-color: var(--primary);
      border: 1px solid white;
      pointer-events: all;
    }
  }

  .canvas_view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .filters-container {
    border-right: 3px solid white;

    position: absolute;
    width: 23vw;
    top: 7vh;
    padding: 2rem;
    /* padding-top: 8vh; */
    height: 35vh;

    display: flex;
    align-items: flex-end;

    .filters {
      width: 100%;
      height: 100%;
      background-color: var(--primary);
      border: 1px solid white;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }

  .residences-popup {
    z-index: 12;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;

    > * {
      pointer-events: all;
    }

    .backButton {
      top: 15%;
    }
  }
  .externalBtn {
    position: absolute;
    z-index: 9;
    bottom: 2rem;
    left: 50%;
    width: 15% !important;
    transform: translateX(-50%);
    pointer-events: all;
  }
`

export default Residences
