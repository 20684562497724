export const updateDocumentStyles = (branding) => {
  // Only update color properties
  document.documentElement.style.setProperty(
    "--primary",
    branding?.colours?.primary ||
      getComputedStyle(document.documentElement).getPropertyValue("--primary"),
  )
  document.documentElement.style.setProperty(
    "--secondary",
    branding?.colours?.secondary ||
      getComputedStyle(document.documentElement).getPropertyValue(
        "--secondary",
      ),
  )
  document.documentElement.style.setProperty(
    "--accent",
    branding?.colours?.accent ||
      getComputedStyle(document.documentElement).getPropertyValue("--accent"),
  )

  // Font loading logic (if needed)
  const loadFont = async (url: string, fontFamily: string) => {
    if (!url) return null
    try {
      const fontFace = new FontFace(fontFamily, `url(${url})`)
      const loadedFont = await fontFace.load()
      document.fonts.add(loadedFont)
      return loadedFont
    } catch (error) {
      console.error(`Failed to load font from ${url}:`, error)
      return null
    }
  }

  // Load fonts if URLs are available
  if (branding?.fonts?.primaryFont?.publicURL) {
    loadFont(branding.fonts.primaryFont.publicURL, "PrimaryFont")
  }
  if (branding?.fonts?.secondaryFont?.publicURL) {
    loadFont(branding.fonts.secondaryFont.publicURL, "SecondaryFont")
  }
}
