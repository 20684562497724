import { useProjectDataStore } from "@state/projectDataStore"
import styled from "styled-components/macro"

type Props = {
  onClick: () => void
}

const BackButton = ({ onClick }: Props) => {
  const { brandingData } = useProjectDataStore((s) => ({
    brandingData: s.brandingData,
  }))
  const colors = brandingData

  return (
    <Wrapper onClick={onClick} colors={colors} className="backButton back-btn">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 50 50"
      >
        <line
          x1={10}
          y1={25}
          x2={40}
          y2={25}
          stroke="var(--accent)"
          strokeWidth={5}
        />
        <polyline
          points="20,10 10,25 20,40"
          stroke="var(--accent)"
          fill="none"
          strokeWidth={5}
        />
      </svg>
    </Wrapper>
  )
}

export default BackButton

const Wrapper = styled.div<{ colors }>`
  position: absolute;
  width: 3.25rem;
  height: 3.25rem;
  z-index: 20;
  margin: 2rem;
  background-color: var(--primary);
  border: 1px solid var(--accent);
  border-radius: 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  pointer-events: all;

  &:active {
    opacity: 0.5;
    transform: scale(0.95);
    transition: 0.3s;
  }
`
