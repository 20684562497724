import styled from "styled-components"
import { useStore } from "@state/store"
import { useProjectDataStore } from "@state/projectDataStore"
import TextButton from "@common/ui/TextButton"
import { Cross2Icon } from "@radix-ui/react-icons"
import { FC, useState } from "react"
import useViewsPublicURL from "@src/hooks/useViewsPublicURL"

type ImageDisplayCompareProps = {
  show?: any
  selectedUnit: any
}

const ImageDisplayCompare: FC<ImageDisplayCompareProps> = ({
  show,
  selectedUnit,
}) => {
  const [view, setView] = useState(0)
  const contentControls = useStore((s) => s.contentControls)

  const { brandingData, viewsData } = useProjectDataStore((s) => ({
    brandingData: s.brandingData,
    viewsData: s.viewsData,
  }))

  const colors = brandingData
  const viewID = selectedUnit?.viewID
  // const floorplan = selectedUnit?.floorplan

  const publicURLs = useViewsPublicURL(viewID, viewsData.viewMedia)

  return (
    <Wrapper colors={colors}>
      {show && (
        <TextButton
          color="#fff"
          className="close-button"
          onClick={() => show(false)}
          style={{ borderRadius: "50%" }}
          icon={<Cross2Icon color="#fff" />}
          iconPosition="right"
          variant="outline"
        />
      )}
      {contentControls?.index === 0 ? (
        <Image src="/payload/floorPlanMedia/1.png" alt="" isview={false} />
      ) : (
        <div className="view-wrapper">
          <Image src={publicURLs[view]} alt="" isview={true} />
          {show && (
            <div className="view-controls">
              <div
                id="increase"
                className={view === 0 ? "buttons active" : "buttons"}
                onClick={() => setView(0)}
              >
                North
              </div>
              <div
                id="decrease"
                className={view === 1 ? "buttons active" : "buttons"}
                onClick={() => setView(1)}
              >
                South
              </div>
            </div>
          )}
        </div>
      )}
    </Wrapper>
  )
}

export default ImageDisplayCompare

const Wrapper = styled.div<{ colors: any }>`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--secondary);
  display: flex;
  justify-content: center;

  .close-button {
    position: absolute;
    top: 2em;
    left: 2em;
    z-index: 1;
  }

  .view-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;

    .view-controls {
      position: absolute;
      left: 1em;
      bottom: 1em;
      display: flex;
      transition: 0.3s;

      .buttons {
        background-color: rgba(256, 256, 256, 0.5);
        backdrop-filter: blur(1px);
        color: var(--primary);
        padding: 0.5rem 2rem;
        cursor: pointer;
        transition: 0.3s;
      }

      .active {
        background-color: var(--primary);
        filter: blur(0px);
        color: white;
        transition: 0.3s;
      }
    }
  }
`

const Image = styled.img<{ isview: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: ${(props) => (props.isview ? "cover" : "contain")};
  padding: ${(props) => (props.isview ? "8em 4em" : "2em")};
`
