import { useState, useRef, useEffect } from "react"
import gsap from "gsap"
import { useStore } from "@state/store"
import styled from "styled-components/macro"

const StylePicker = ({ unitData }) => {
  const selectedUnit = useStore((s) => s.selectedUnit)
  const residenceStyleIndex = useStore((s) => s.residenceStyleIndex)
  const syncState = useStore((s) => s.syncState)

  const selectedSpec = useRef(0)
  const [open, setOpen] = useState(false)

  let specsArr = ["Bathroom", "Bedroom"]
  let styleData
  let styleListData = []

  if (selectedUnit) {
    specsArr = unitData?.specs?.value?.SpecData[0]?.specsType

    const selectedStyleKey = residenceStyleIndex === 0 ? "style1" : "style2"

    styleData =
      unitData?.specs?.value?.SpecData[0]?.specs[selectedSpec.current][
        selectedStyleKey
      ]
    styleListData = Object.values(styleData?.list || {})
  }

  useEffect(() => {
    gsap.to("#dropdown", {
      autoAlpha: open ? 1 : 0,
      duration: 0.3,
    })
  }, [open])

  return (
    <Wrapper>
      <div className="title">
        <div>{styleData?.title}</div>
      </div>
      <SpecsSelector>
        <div
          className="selectedSpec"
          onClick={() => {
            setOpen(!open)
          }}
        >
          {specsArr && specsArr[selectedSpec.current]}
          <span className="arrow">
            <img src="/assets/icons/back.svg" alt="" />
          </span>
        </div>
        <OptionsWrapper id="dropdown">
          {specsArr &&
            specsArr.map((spec, i) => (
              <div
                className={
                  selectedSpec.current === i
                    ? "selected specOption"
                    : "specOption"
                }
                onClick={() => {
                  selectedSpec.current = i
                  setOpen(false)
                }}
                key={`opt${i}`}
              >
                {spec}
              </div>
            ))}
        </OptionsWrapper>
      </SpecsSelector>
      <div className="picker">
        {["Style 1", "Style 2"].map((name, index) => (
          <StyleButton
            key={index}
            onClick={() => syncState({ residenceStyleIndex: index })}
            selected={residenceStyleIndex === index}
          >
            {name}
          </StyleButton>
        ))}
      </div>
      <div className="textContainer">
        <div className="body">{styleData?.body}</div>
        <div className="list">
          {styleListData &&
            styleListData.map((data, i) => (
              <div key={i}>
                <span style={{ fontFamily: "arial", opacity: 0.5 }}>- </span>
                {data}
              </div>
            ))}
        </div>
      </div>
    </Wrapper>
  )
}

export default StylePicker

const Wrapper = styled.div`
  width: 40%;
  height: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 4rem;
  gap: 1.5rem;
  pointer-events: all;

  .picker {
    display: flex;
    width: 100%;
  }

  .title {
    display: flex;
    font-size: 4rem;
    color: var(--black);
  }

  .textContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: var(--black);
    gap: 2rem;

    .body {
      width: 100%;
      font-size: 0.8rem;
      line-height: 150%;
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 0.75rem;
    }
  }
`

const StyleButton = styled.div<{ selected: boolean }>`
  cursor: pointer;
  color: #fff;
  width: 50%;
  display: flex;
  justify-content: center;
  background-color: ${({ selected }) =>
    selected ? `var(--secondary)` : `var(--primary-lighten)`};
  color: ${({ selected }) => (selected ? `var(--white)` : `var(--black)`)};
  font-size: 0.75rem;
  padding: 0.75rem 2rem;
  transition: 0.3s;
`

const SpecsSelector = styled.div`
  position: relative;
  width: 100%;
  background-color: var(--primary-lighten);
  cursor: pointer;

  .selectedSpec {
    width: 100%;
    padding: 0.75rem 1rem;
    display: flex;
    color: var(--black);
    align-items: center;
  }

  .arrow {
    margin-left: auto;
    transform: rotate(-90deg) scale(0.8);
  }
`

const OptionsWrapper = styled.div`
  width: 100%;
  position: absolute;
  background-color: var(--primary-lighten);
  border-radius: 5px;
  margin-top: 1rem;
  z-index: 10;
  overflow: hidden;

  .specOption {
    padding: 0.75rem 1rem;
    overflow: hidden;
    color: var(--black);

    &:hover {
      background-color: var(--secondary);
      transition: 0.3s;
      color: var(--white);
      cursor: pointer;
    }
  }

  .selected {
    background-color: var(--secondary);
    color: var(--white);
  }
`
