import Carousel from "@common/components/Carousel"
import ImageGridMasonry from "@common/components/ImageGridMasonry"
import { useProjectDataStore } from "@state/projectDataStore"
import { useStore } from "@state/store"
import { useEffect } from "react"
import styled from "styled-components/macro"

const MasonryGallery = (props) => {
  const { brandingData, galleryData } = useProjectDataStore((s) => ({
    brandingData: s.brandingData,
    galleryData: s.galleryData,
  }))
  const contentControls = useStore((s) => s.contentControls)
  const syncState = useStore((s) => s.syncState)

  useEffect(() => {
    syncState({
      contentControls: {
        categories: categories,
        index: 0,
      },
    })
  }, [])

  const categories = [
    ...Array.from(
      new Set(galleryData?.images?.map((image) => image?.category)),
    ),
  ]

  // console.log("BRANDING DATA", brandingData)

  return (
    <Wrapper
      style={{
        backgroundImage: `url(${brandingData?.bgImage?.publicURL})`,
      }}
    >
      {contentControls && (
        <ImageGridMasonry
          galleryData={galleryData}
          categories={categories}
          contentControls={contentControls}
        />
      )}
      <Carousel galleryData={galleryData} brandingData={brandingData} />
    </Wrapper>
  )
}

export default MasonryGallery

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
`
