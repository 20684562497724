import { create } from "zustand"
import { persist } from "zustand/middleware"

interface FavoritesState {
  favorites: any[]
  isFavorite: (unit: any) => boolean
  addToFavorites: (unit: any) => void
  removeFromFavorites: (unit: any) => void
  clearFavorites: () => void
}

export const useFavoritesStore = create<FavoritesState>()(
  persist(
    (set, get) => ({
      favorites: [],
      isFavorite: (unit) => {
        return get().favorites.some(
          (favorite) => favorite?.unitName === unit?.unitName,
        )
      },
      addToFavorites: (unit) => {
        if (!unit) return
        const updatedFavorites = get().favorites.filter(
          (favorite) => favorite?.unitName !== unit?.unitName,
        )

        if (updatedFavorites.length === get().favorites.length) {
          updatedFavorites.push(unit)
        }

        set({ favorites: updatedFavorites })
      },
      removeFromFavorites: (unit) => {
        const updatedFavorites = get().favorites.filter(
          (favorite) => favorite?.unitName !== unit?.unitName,
        )

        set({ favorites: updatedFavorites })
      },
      clearFavorites: () => {
        set({ favorites: [] })
      },
    }),
    {
      name: "favorites-storage",
    },
  ),
)
