import React, { useState, useRef } from "react"
import styled from "styled-components"
import ReactDOMServer from "react-dom/server"
import * as Checkbox from "@radix-ui/react-checkbox"
import { CheckIcon } from "@radix-ui/react-icons"
import { validateForm } from "@utils/formValidation"
import { useFavoritesStore } from "@state/favouritesStore"
import { EmailPlainText } from "./EmailPlainText"
import EmailTemplate from "./EmailTemplate"
import TextButton from "@common/ui/TextButton"
import { createNewCustomer } from "@utils/analytics"

const initialForm = {
  name: "",
  email: "",
  cc: [],
  withAttachments: false,
}

function Form(props: any) {
  const { config, setQrUrl } = props
  const SHARE_APP_URL = config?.shareType.shareAppURL

  const { favorites } = useFavoritesStore((state) => state)
  const [formData, setFormData] = useState(initialForm)
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [withAttachments, setWithAttachments] = useState(false)
  const [CCAgents, setCCAgents] = useState<string[]>([])
  const [submitting, setSubmitting] = useState(false)
  const [formMessage, setFormMessage] = useState("")

  const favIDS = favorites?.map((fav: any) => fav.unitName)
  const combinedEncoded = btoa(`${favIDS?.join(",")}£${formData?.name}`)
  const url = `${SHARE_APP_URL}/?${combinedEncoded}`

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }))
  }

  const handleSubmit = async (): Promise<void> => {
    setSubmitting(true)

    setFormData((prevData) => ({
      ...initialForm,
      ...prevData,
    }))

    const validationErrors = validateForm(formData)

    if (validationErrors) {
      setErrors(validationErrors)
      setSubmitting(false)
      return
    }

    setFormData((prevData) => ({
      ...prevData,
      link: url,
      cc: CCAgents.length > 0 ? CCAgents : null,
      withAttachments,
    }))

    try {
      const res = await sendEmail()
      resetFormState()
      setFormMessage(res?.message)
    } catch (err) {
      setFormMessage("Email failed to send. Please try again later.")
    } finally {
      setSubmitting(false)
    }
  }

  const handleCheckedChange = (email: string, isChecked: boolean) => {
    setCCAgents((prevAgents) =>
      isChecked
        ? [...prevAgents, email]
        : prevAgents.filter((agent) => agent !== email),
    )
  }

  const sendEmail = async () => {
    const htmlTemplate = ReactDOMServer.renderToStaticMarkup(
      <EmailTemplate link={url} name={formData?.name} />,
    )

    const res = await fetch(`${config?.emailSettings.emailEndpoint}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        ...formData,
        from: config?.emailSettings.emailFrom,
        to: formData.email,
        message: EmailPlainText({ name: formData.name, url }),
        html: htmlTemplate,
        text: EmailPlainText({ name: formData.name, url }),
        subject: config?.emailSettings.emailSubject,
        filename: "Your Units attached",
        units: [],
      }),
    })

    if (res?.status === 200 || res?.ok) {
      await createNewCustomer(formData?.name, formData?.email, "email", favIDS)

      return res.json()
    } else {
      throw new Error("Could not send email.")
    }
  }

  const resetFormState = () => {
    setFormData(initialForm)
    setWithAttachments(false)
    setCCAgents([])
    setErrors({})
    const form = document.getElementById("form") as HTMLFormElement
    if (form) {
      form.reset()
    }
  }

  const handleSubmitQR = async (anonymous: boolean): Promise<void> => {
    setSubmitting(true)

    let { name, email } = formData

    const validationErrors = validateForm({ name, email })
    if (validationErrors && !anonymous) {
      setErrors(validationErrors)
      setSubmitting(false)
      return
    }

    if (anonymous) {
      name = "anonymous"
      email = "anonymous"
    }

    try {
      const res = await createNewCustomer(name, email, "pwa", favIDS)

      const result = res.data

      if (res?.status === 201 && result?.doc?.link) {
        // Update the QR code value using the URL from backend
        setQrUrl(result?.doc?.link)
        setFormMessage("QR Code generated successfully.")
        // create sent link analytics
      } else {
        throw new Error("Failed to generate QR code.")
      }
    } catch (err) {
      setFormMessage("Failed to generate QR code. Please try again later.")
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <FormWrapper>
      <div className="form-feedback">{formMessage}</div>
      <form id="form" onSubmit={(e) => e.preventDefault()}>
        <div>
          <StyledInput
            placeholder="Name"
            name="name"
            type="text"
            maxLength={20}
            autoComplete="off"
            onInput={handleInputChange}
            disabled={submitting}
          />
          <StyledError>{errors.name}</StyledError>
        </div>
        <div>
          <StyledInput
            placeholder="Email"
            name="email"
            type="email"
            autoComplete="off"
            onInput={handleInputChange}
            disabled={submitting}
          />
          <StyledError>{errors.email}</StyledError>
        </div>
        {config?.ccAgents && config?.ccAgents.length > 0 && (
          <AgentCheckboxes
            agents={config?.ccAgents}
            CCAgents={CCAgents}
            onCheckedChange={handleCheckedChange}
            disabled={submitting}
          />
        )}
        <AttachmentsCheckbox
          isChecked={withAttachments}
          onCheckedChange={() => setWithAttachments((prev) => !prev)}
          disabled={submitting}
        />
        <TextButton
          className="submit-button"
          style={{ width: "100%" }}
          onClick={handleSubmit}
          color="secondary"
          disabled={submitting}
        >
          Send Email
        </TextButton>
        OR
        <TextButton
          className="submit-button"
          style={{ width: "100%" }}
          onClick={() => handleSubmitQR(false)}
          color="secondary"
          disabled={submitting}
        >
          Generate QR Code
        </TextButton>
        <TextButton
          className="submit-button"
          style={{ width: "100%" }}
          onClick={() => handleSubmitQR(true)}
          color="accent"
          disabled={submitting}
        >
          Generate Anonymous QR Code
        </TextButton>
      </form>
    </FormWrapper>
  )
}

const AgentCheckboxes = ({ agents, CCAgents, onCheckedChange, disabled }) => (
  <div className="cc-agents">
    <div className="title">CC Agents:</div>
    {agents.map((agent: any) => (
      <CheckboxItem
        key={agent.email}
        email={agent.email}
        name={agent.name}
        isChecked={CCAgents.includes(agent.email)}
        onCheckedChange={onCheckedChange}
        disabled={disabled}
      />
    ))}
  </div>
)

const AttachmentsCheckbox = ({ isChecked, onCheckedChange, disabled }) => (
  <div
    className="cc-agents"
    style={{ borderTop: "1px solid #fff", paddingTop: "2em" }}
  >
    <CheckboxItem
      email="withAttachments"
      name="Send with attachments"
      isChecked={isChecked}
      onCheckedChange={onCheckedChange}
      disabled={disabled}
    />
  </div>
)

const CheckboxItem = ({
  email,
  name,
  isChecked,
  onCheckedChange,
  disabled,
}) => (
  <StyledContainer>
    <Checkbox.Root
      className="CheckboxRoot"
      id={email}
      checked={isChecked}
      onCheckedChange={(checked) => onCheckedChange(email, checked)}
      disabled={disabled}
    >
      <Checkbox.Indicator className="CheckboxIndicator">
        <CheckIcon />
      </Checkbox.Indicator>
    </Checkbox.Root>
    <label className="Label" htmlFor={email}>
      {name}
    </label>
  </StyledContainer>
)

export default Form

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`

const FormWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 2rem 4rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  user-select: none;

  .form-feedback {
    height: 1.5em;
    margin-top: -5em;
    margin-bottom: 5em;
    line-height: 2;
    visibility: ${({ children }) => (children ? "visible" : "hidden")};
  }

  & form {
    color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    *:not(.submit-button):not(.submit-button *):not([role="checkbox"]) {
      width: 100%;
    }
  }

  .cc-agents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;

    .title {
      font-size: 1.5rem;
      padding: 0.5em 0;
      color: white;
    }

    .CheckboxRoot {
      width: 2rem;
      height: 2rem;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;

      &:disabled {
        background-color: #7a7a7a;
      }
    }

    .CheckboxIndicator {
      color: #000;
    }

    .Label {
      color: white;
      padding-left: 15px;
      font-size: 1.5rem;
      line-height: 1;
      letter-spacing: 0.1rem;
      width: 100%;
    }
  }
`

const StyledInput = styled.input`
  width: 100%;
  height: 4em;
  padding: 0.5rem 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: transparent;
  color: #fff;
  font-size: 1em;
  transition: border-color 0.3s ease;

  ::placeholder {
    color: #aaa;
  }

  :focus-visible {
    outline: none;
    border-color: #888;
  }

  :disabled {
    background-color: #555;
    border-color: #777;
    color: #888;
  }
`

const StyledError = styled.div`
  height: 1.5em;
  color: #f75b00;
  font-size: 1em;
  margin-top: 0.25rem;
  line-height: 2;
  visibility: ${({ children }) => (children ? "visible" : "hidden")};
`
