import styled from "styled-components"
import { useStore } from "@state/store"
import { useProjectDataStore } from "@state/projectDataStore"
import FloorplanImage from "./FloorplanImage"
import ImageOverlay from "../ImageOverlay"
import ViewSection from "../ViewSection"
import { useFavoritesStore } from "@state/favouritesStore"
import { HeartFilledIcon, HeartIcon } from "@radix-ui/react-icons"
import TextButton from "@common/ui/TextButton"

const ImageDisplay = ({ ...props }) => {
  const { addToFavorites, isFavorite } = useFavoritesStore()
  const contentControls = useStore((s) => s.contentControls)
  const selectedUnit = useStore((s) => s.selectedUnit)
  const residencePopup = useStore((s) => s.residencePopup)
  const imageOverlay = useStore((s) => s.imageOverlay)
  const syncState = useStore((s) => s.syncState)

  const { brandingData } = useProjectDataStore((s) => ({
    brandingData: s.brandingData,
  }))

  if (selectedUnit) {
    const colors = brandingData
    const viewID = selectedUnit[0]?.viewID

    return (
      <>
        <Wrapper colors={colors} {...props}>
          <FloorplanImage
            unit={selectedUnit}
            onClick={() => syncState({ imageOverlay: true })}
          />
          <div className="floorplan-btns">
            <TextButton
              onClick={() => {
                addToFavorites(selectedUnit[0])
              }}
              icon={
                isFavorite(selectedUnit[0]) ? (
                  <HeartFilledIcon />
                ) : (
                  <HeartIcon />
                )
              }
              iconPosition="right"
              color="secondary"
              style={{
                margin: 0,
                width: "50%",
              }}
              className="fav-btn"
            >
              Add to Favorites
            </TextButton>
            <TextButton
              onClick={() => {
                syncState({
                  residencePopup: false,
                  tableVisible: true,
                })
              }}
              icon={"assets/icons/right-arrow.svg"}
              iconPosition="right"
              color="secondary"
              style={{
                height: "4em",
                margin: 0,
                width: "50%",
              }}
              className="fav-btn"
            >
              View Similar Units
            </TextButton>
            <TextButton
              color="secondary"
              style={{
                height: "4em",
                margin: 0,
              }}
              icon={
                <img
                  src="assets/icons/enlarge.png"
                  alt=""
                  style={{ filter: "invert(100%)" }}
                />
              }
              onClick={() => syncState({ imageOverlay: !imageOverlay })}
            />
          </div>
          <div className="rotated-text">WEST HAM VILLAGE E16</div>
        </Wrapper>
        <ImageOverlay
          selectedUnit={selectedUnit}
          controlIndex={contentControls?.index}
        />
        <ViewSection viewID={viewID} />
      </>
    )
  }
}

export default ImageDisplay

const Wrapper = styled.div<{ colors: any }>`
  width: 55%;
  height: 100%;
  position: relative;
  background-color: var(--secondary);
  display: flex;
  justify-content: center;

  .floorplan-btns {
    position: absolute;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 2rem;
    border: 1px solid var(--primary);
  }

  .fav-btn {
    & span {
      flex: unset;
    }
  }

  .aptCardImgBtn {
    position: relative !important;
    background-color: none !important;
    > * {
      background-color: none !important;
    }
  }

  .rotated-text {
    position: absolute;
    color: #fff;
    font-weight: 300;
    font-size: 0.8rem;
    right: 0;
    top: 50%;
    transform: translate(40%, -50%) rotate(90deg);
  }
`
