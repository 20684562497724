import { create } from "zustand"
import { initialProjectState } from "./initialProjectState"

// Define types for each page data
interface PageData {
  relationTo?: string
  [key: string]: any
}

interface ProjectState {
  dataWithBeds: any | null
  livePreview: boolean
  brandingData: PageData | null
  navigationData: PageData | null
  navRoutes: PageData | null
  allRoutes: PageData | null
  explorerData: PageData | null
  sharedOwnershipData: PageData | null
  favouritesData: PageData | null
  galleryData: PageData | null
  mapData: PageData | null
  residencesData: PageData | null
  orbitData: PageData | null
  splashData: PageData | null
  availabilityData: PageData | null
  typeData: PageData | null
  viewsData: PageData | null
  detailsData: PageData | null
  aboutData: PageData | null
  info1Data: PageData | null
  developmentData: PageData | null
  setLocalState: (state: Partial<ProjectState>) => void
}

export const useProjectDataStore = create<ProjectState>((set, get) => ({
  ...initialProjectState,
  livePreview: false,
  dataWithBeds: null,
  setLocalState: (stateToUpdate) => {
    set({ ...stateToUpdate })
  },
}))
