import styled from "styled-components/macro"

type NavTextButtonProps = {
  active?: boolean
  children: React.ReactNode
  [key: string]: any
  onClick: any
  className?: string
}

const NavTextButton = ({
  active = false,
  children,
  onClick,
  className,
  ...props
}: NavTextButtonProps) => {
  return (
    <Wrapper onClick={onClick} className={className} {...props}>
      {children}
    </Wrapper>
  )
}

export default NavTextButton

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  font-weight: 400;
  font-size: 1.15rem;
  transition: 0.3s;
  background-color: var(--primary);
  cursor: pointer;
  color: white;
`
