import Fullscreen_Splash from "@common/features/FullScreenSplash/FullScreenSplash"
import Sidebar_Splash from "@common/features/SidebarSplash/SidebarSplash"
import ResidencesType1 from "@common/features/ResidencesType1"
import ResidencesType2 from "@common/features/ResidencesType1"
import MasonryGallery from "@common/features/MasonryGallery"
import GoogleMaps from "@common/features/GoogleMaps"
import Favourites from "@common/features/Favourites"
import Fullscreen_Residences from "@layouts/fullscreen-layout/pages/residences"
import Sidebar_Residences from "@layouts/sidebar-layout/pages/residences-sidebar"
import Gallery from "@layouts/fullscreen-layout/pages/gallery"
import Fullscreen_Location from "@layouts/fullscreen-layout/pages/location"
import Sidebar_Favourites from "@layouts/sidebar-layout/pages/favourites-sidebar"
import Sidebar_Development from "@layouts/sidebar-layout/pages/development-sidebar"
import Fullscreen_Favourites from "@layouts/fullscreen-layout/pages/favourites"
import Fullscreen_Orbit from "@layouts/fullscreen-layout/pages/orbit"
import Orbit3D from "@common/features/Orbit3D"
import SharedOwnership from "@common/features/SharedOwnership"
import Info1 from "@common/features/Info1"
import Fullscreen_SharedOwnership from "@layouts/fullscreen-layout/pages/shared-ownership"
import Fullscreen_About from "@layouts/fullscreen-layout/pages/about"
import About from "@common/features/About"
import InfoDevelopment from "@common/features/InfoDevelopment"
import Sidebar_Info1 from "@common/features/Info1"

const featureIndex = {
  masonrygallery: MasonryGallery,
  residencesType1: ResidencesType1,
  residencesType2: ResidencesType2,
  googleMaps: GoogleMaps,
  favourites: Favourites,
  orbit3d: Orbit3D,
  sharedOwnership: SharedOwnership,
  info1: Info1,
  about: About,
  development: InfoDevelopment,
}

const pageIndex = {
  // SPLASH
  "fullscreen-splash": Fullscreen_Splash,
  "sidebar-splash": Sidebar_Splash,
  // RESIDENCES
  "fullscreen-residences": Fullscreen_Residences,
  "sidebar-residences": Sidebar_Residences,
  // ORBIT
  "fullscreen-orbits": Fullscreen_Orbit,
  // GALLERY
  "fullscreen-gallery": Gallery,
  "sidebar-gallery": Gallery,
  // MAP
  "sidebar-map": Fullscreen_Location,
  "fullscreen-map": Fullscreen_Location,
  //FAVORITES
  "sidebar-favourites": Sidebar_Favourites,
  "fullscreen-favourites": Fullscreen_Favourites,
  //SHARED OWNERSHIP
  "fullscreen-sharedOwnership": Fullscreen_SharedOwnership,
  //About Page
  "fullscreen-about": Fullscreen_About,
  //Info1 Page
  "sidebar-info1": Sidebar_Info1,
  "sidebar-development": Sidebar_Development,
}

export { featureIndex, pageIndex }
