import { Route, Routes } from "react-router-dom"
import { featureIndex, pageIndex } from "./LazyImports"
import ErrorScreen from "@common/components/ErrorScreen"
import { useProjectDataStore } from "@state/projectDataStore"

const PageBuilder = () => {
  const {
    navigationData,
    galleryData,
    residencesData,
    orbitData,
    mapData,
    favouritesData,
    explorerData,
    sharedOwnershipData,
    aboutData,
    info1Data,
    developmentData,
  } = useProjectDataStore((s) => ({
    navigationData: s.navigationData,
    splashData: s.splashData,
    galleryData: s.galleryData,
    residencesData: s.residencesData,
    orbitData: s.orbitData,
    mapData: s.mapData,
    favouritesData: s.favouritesData,
    explorerData: s.explorerData,
    sharedOwnershipData: s.sharedOwnershipData,
    aboutData: s.aboutData,
    info1Data: s.info1Data,
    developmentData: s.developmentData,
  }))

  const pageDataMap = {
    gallery: galleryData,
    residences: residencesData,
    map: mapData,
    favourites: favouritesData,
    explorer: explorerData,
    orbits: orbitData,
    sharedOwnership: sharedOwnershipData,
    about: aboutData,
    info1: info1Data,
    development: developmentData,
  }

  const SplashPage = pageIndex[`${navigationData?.layout}-splash`]

  return (
    <Routes>
      {/* Splash page route */}
      <Route path="/" element={<SplashPage></SplashPage>} />

      {/* Menu item routes */}
      {navigationData?.menuItems
        .filter((item) => item.include === "true")
        .map((item) => {
          const pageKey = `${navigationData?.layout}-${item?.pageType}`
          const Page = pageIndex[pageKey]
          const Feature = featureIndex[pageDataMap[item?.pageType]?.feature]

          // console.log(
          //   pageKey,
          //   ":",
          //   item?.pageType,
          //   ":",
          //   pageDataMap[item?.pageType]?.feature,
          // )

          if (!Page) {
            console.warn(`No page component found for key: ${pageKey}`)
            return null
          }

          return (
            <Route
              key={item?.pageType}
              path={`/${item?.pageType}`}
              element={
                <Page>
                  {Feature && <Feature {...pageDataMap[item?.pageType]} />}
                </Page>
              }
            />
          )
        })}

      <Route path="*" element={<ErrorScreen error={"Page not found"} />} />
    </Routes>
  )
}
export default PageBuilder
