import NavTextButton from "@common/ui/NavTextButton"
import { useStore } from "@state/store"
import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import gsap from "gsap"

const Peabody = ({ aboutData }) => {
  const aboutIndex = useStore((s) => s.aboutIndex)

  useEffect(() => {
    gsap.to(".peabodyWrapper", {
      autoAlpha: aboutIndex === 0 ? 1 : 0,
      duration: 0.6,
      ease: "power3.inOut",
    })
  }, [aboutIndex])
  return (
    <PeabodyWrapper className="peabodyWrapper">
      <div className="peabodyContent">
        <img
          className="peabodyLogo"
          src="/assets/icons/about/peabody.png"
          alt=""
        />
        <Grid>
          {aboutData?.description?.map((block, index) => (
            <div key={index} className="grid-item">
              {block?.img && <img src={block?.img?.publicURL} alt="" />}
              {block?.title && block?.desc && (
                <>
                  <div className="aboutTitle">{block?.title}</div>
                  <div className="aboutDesc">{block?.desc}</div>
                </>
              )}
            </div>
          ))}
        </Grid>
      </div>
    </PeabodyWrapper>
  )
}

export default Peabody

const PeabodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--peabodyTeal);
  display: flex;
  padding: 2rem;

  .peabodyContent {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 95%;
    height: 80%;
    margin: auto 0;
  }

  .peabodyLogo {
    height: 5rem;
    object-fit: contain;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;
  height: 90%;

  .grid-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 1rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .aboutTitle {
      font-size: 2rem;
      font-weight: bold;
      font-family: PbdyPrimary;
      color: white;
    }

    .aboutDesc {
      line-height: 20px;
      font-size: 1.15rem;
      color: var(--peabodyGray);
      font-weight: normal;
    }
  }

  .grid-item:nth-child(4) {
    grid-column: 1/2;
    grid-row: span 2;
  }
`
