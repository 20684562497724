import { create } from "zustand"
import { subscribeWithSelector } from "zustand/middleware"

interface Orbit3DStore {
  labels: any[]
  setLabels: (labels: any[]) => void

  activeLabels: any
  addLabel: (activeLabels: any) => void
  removeLabel: (label: any) => void

  activeView: any
  addActiveView: (activeView: any) => void
  removeActiveView: (viewID: any) => void

  activeLabel: any
  setActiveLabel: (activeLabel: any) => void

  setActiveView: (activeView: any) => void
}

export const useOrbit3DStore = create(
  subscribeWithSelector<Orbit3DStore>((set) => ({
    labels: [],
    setLabels: (labels) => set({ labels }),

    activeLabel: null,
    setActiveLabel: (activeLabel) => set({ activeLabel }),

    activeLabels: [],

    activeView: [],

    setActiveView: (activeView) => set({ activeView }),

    addActiveView: (viewID) =>
      set((state) => ({
        activeView: state.activeView.includes(viewID)
          ? state.activeView
          : [viewID, ...state.activeView],
      })),

    removeActiveView: (viewID) =>
      set((state) => ({
        activeView: state.activeView.filter((l) => l !== viewID),
      })),

    addLabel: (label) =>
      set((state) => ({
        activeLabels: state.activeLabels.includes(label)
          ? state.activeLabels
          : [label, ...state.activeLabels],
      })),

    removeLabel: (label) =>
      set((state) => ({
        activeLabels: state.activeLabels.filter((l) => l !== label),
      })),
  })),
)
