import React from "react";
import { Vector2 } from "three";

export const useClickWithDistance = (onClick, threshold = 10) => {
	const initialXY = React.useRef(new Vector2(0, 0));

	const handlePointerDown = (e) => {
		initialXY.current.x = e.clientX;
		initialXY.current.y = e.clientY;
	};

	const handlePointerUp = (e) => {
		const newVec = new Vector2(e.clientX, e.clientY);
		const distance = newVec.distanceTo(initialXY.current);

		if (distance < threshold) {
			onClick();
		}
	};

	return { handlePointerDown, handlePointerUp };
};
