import { Billboard, Html } from "@react-three/drei"
import { useStore } from "@state/store"
import { Vector3 } from "three"

const BuildingTouchPoints = () => {
  const filteredUnits = useStore((s) => s.filteredUnits)
  const selectedUnit = useStore((s) => s.selectedUnit)

  const residencePopup = useStore((s) => s.residencePopup)
  const filters = useStore((s) => s.filters)

  const listUnits = (list, blockname) => {
    return list.filter((unit) => {
      return unit.active && unit?.unitName.includes(blockname)
    })
  }

  const data = {
    "0": {
      positions: { x: 9, y: 15, z: -16 },
      title: "Oak House",
      value: listUnits(filteredUnits, "N01B").length,
    },
    "1": {
      positions: { x: 5, y: 15, z: 2 },
      title: "Cherry Point",
      value: listUnits(filteredUnits, "S01C").length,
    },
  }
  if (residencePopup || selectedUnit?.length) return null
  return (
    <>
      {!filters?.blocks?.includes("S01C") && (
        <TouchPoint data={data["0"]} index={1} />
      )}
      {!filters?.blocks?.includes("N01B") && (
        <TouchPoint data={data["1"]} index={1} />
      )}
    </>
  )
}

const TouchPoint = (props) => {
  const { data, handleClickTouchPoint } = props
  const { positions } = data

  return (
    <group
      scale={10}
      onClick={() => {
        handleClickTouchPoint(data)
      }}
      position={new Vector3(positions.x, positions.y, positions.z)}
    >
      <Billboard lockX={true} lockY={false} lockZ={false}>
        {data?.title && (
          <group>
            <Html zIndexRange={[1, 0]} color="white" center={true}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "19em",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1
                  style={{
                    fontSize: "2.75rem",
                    fontWeight: 600,
                    color: "#fff",
                  }}
                >
                  {data.title}
                </h1>
                <h2
                  style={{
                    fontFamily: "secondaryFont",
                    fontSize: "2.25rem",
                    fontWeight: 600,
                    color: "#f98557",
                  }}
                >
                  {data.value} Available units
                </h2>
              </div>
            </Html>
          </group>
        )}
      </Billboard>
      <mesh position-y={-0.55}>
        <boxGeometry args={[0.003, 0.7, 0.003]} />
        <meshBasicMaterial color="#8c8c8c" />
      </mesh>
    </group>
  )
}

export default BuildingTouchPoints
