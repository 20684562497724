import { useOrbit3DStore } from "@common/features/Orbit3D/state/orbit3DStore"
import { Billboard, Html, useGLTF } from "@react-three/drei"
import React from "react"
import { Vector3 } from "three"

function Labels() {
  const touchPoints = [
    {
      title: "Arrival",
      value: 12,
      positions: { x: 10, y: 3, z: -10 },
      image: "/images/westham01.webp",
    },
    {
      title: "Courtyard",
      value: 12,
      positions: { x: 0, y: 3, z: -9 },
      image: "/images/westham02.webp",
    },
  ]

  const { nodes: model } = useGLTF(
    "/assets/models/vivid_reality_logo_3d_model/scene.glb",
  ) as any

  const setActiveLabel = useOrbit3DStore((s) => s.setActiveLabel)

  const handleClickTouchPoint = (data) => {
    setActiveLabel(data)
  }

  // if (touchPoints?.length === 0 || touchPoints === undefined) return null

  return (
    <group>
      {touchPoints?.map((data, index) => {
        return (
          <TouchPoint
            handleClickTouchPoint={handleClickTouchPoint}
            data={data}
            model={model}
            key={index}
            index={index}
          />
        )
      })}
    </group>
  )
}

type TouchPointProps = {
  data: any
  model: any
  index: number
  handleClickTouchPoint: (data: any) => void
}

export const TouchPoint = (props: TouchPointProps) => {
  const { data, model, handleClickTouchPoint } = props
  const { positions } = data
  const materials = React.useRef<any[]>([])

  return (
    <group
      scale={12}
      onClick={() => {
        handleClickTouchPoint({ ...data })
      }}
      position={new Vector3(positions.x, positions.y, positions.z)}
    >
      <Billboard lockX={true} lockY={false} lockZ={false}>
        <mesh
          scale={0.4}
          onPointerOver={() => (document.body.style.cursor = "pointer")}
          onPointerOut={() => (document.body.style.cursor = "auto")}
          geometry={model["mesh_0"].geometry}
        >
          <meshMatcapMaterial
            ref={(el) => (materials.current[0] = el)}
            opacity={1}
            transparent
            toneMapped={false}
            color={"#25466A"}
          />
        </mesh>
        <mesh scale={0.4} geometry={model["mesh_1"].geometry}>
          <meshMatcapMaterial
            ref={(el) => (materials.current[1] = el)}
            opacity={1}
            transparent
            toneMapped={false}
            color={"#84BFC2"}
          />
        </mesh>
      </Billboard>
    </group>
  )
}

export default Labels
