import React, { useEffect, useRef } from "react"
import styled from "styled-components/macro"

type SvgIconProps = {
  url: string
  color?: string
  className?: string
  style?: React.CSSProperties
}

const SvgIcon: React.FC<SvgIconProps> = ({ url, color, className, style }) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const loadSVG = async () => {
      if (containerRef.current) {
        const response = await fetch(url)
        const svgText = await response.text()
        containerRef.current.innerHTML = svgText
      }
    }

    loadSVG()
  }, [url])

  return (
    <Wrapper
      color={color}
      ref={containerRef}
      className={className}
      style={style}
    ></Wrapper>
  )
}

export default SvgIcon

const Wrapper = styled.div<{ color: string }>`
  height: 100%;
  width: 100%;
  line-height: 0;

  svg {
    width: 100%;
    height: 100%;

    path {
      stroke: ${(p) => p.color};
      transition: stroke 1.3s;
    }
  }
`
