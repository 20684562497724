import { OrbitControls, PerspectiveCamera } from "@react-three/drei"
import { useCamera } from "./useCamera"

import { Camera, Vector3 } from "three"
import { StoreApi } from "zustand"

interface CameraSettings {
  position: Vector3
  target: Vector3
  fov: number
  near: number
  far: number
}

interface ControlsSettings {
  rotateSpeed: number
  enableDamping: boolean
  dampingFactor: number
  enableZoom: boolean
  enablePan: boolean
  enableRotate: boolean
  enableKeys: boolean
}

interface CameraData {
  position: Vector3
  target: Vector3
}

interface AnimateCameraProps {
  store: StoreApi<any>
  intitalCameraSettings: CameraSettings
  initialControlsSettings?: ControlsSettings
  onAzimuthPolarChange?: (
    azimuth: number,
    polar: number,
    camera: Camera,
  ) => void
}

const AnimateCamera = (props: AnimateCameraProps) => {
  const {
    store,
    intitalCameraSettings,
    initialControlsSettings,
    onAzimuthPolarChange,
  } = props
  const { cameraRef, controlsRef, onChange } = useCamera(
    store,
    intitalCameraSettings,
    onAzimuthPolarChange,
  )

  return (
    <>
      <OrbitControls
        onChange={onChange}
        ref={controlsRef}
        {...initialControlsSettings}
      />
      <PerspectiveCamera
        ref={cameraRef}
        makeDefault
        {...intitalCameraSettings}
      />
    </>
  )
}
export default AnimateCamera
