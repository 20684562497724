import gsap from "gsap"
import AnimateCamera from "@lib/AnimateCamera/AnimateCamera"
import { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Canvas, useFrame, useThree } from "@react-three/fiber"
import Compass from "./Compass"
import Labels from "./Labels"
import { useCanvasStore } from "@state/canvasStore"
import { handleContextLoss } from "./utils/context"
import { useLocation } from "react-router-dom"
import BuildingTouchPoints from "./BuildingTouchPoints"
import { Sky } from "@react-three/drei"
import { Gsc, UnitOverlayGeometry } from "vmi-gsc"
import { Camera, Color, Vector3 } from "three"
import { useStore } from "@state/store"
import { cameraPositionData } from "@data/cameraData"
import { useGPUSaver } from "@src/hooks/useScreenTimeout"

const initCam = {
  animationDuration: 2,
  position: new Vector3(20, 20, 20),
  target: new Vector3(0, 0, 0),
  fov: 70,
  near: 0.05,
  far: 1000,
}

const initialControlsSettings = {
  rotateSpeed: 0.08,
  enableDamping: true,
  dampingFactor: 0.1,
  enableZoom: false,
  enablePan: false,
  enableRotate: true,
  enableKeys: false,
  minPolarAngle: 0,
  maxPolarAngle: 1.5,
}

const aspectCameraPositions = {
  S01C: {
    N: cameraPositionData.S01C.position,
    NE: cameraPositionData.S01C.position,
    E: new Vector3(8.049311243144595, 6.242892041372979, 10.27470819697811),
    SE: cameraPositionData.S01C.position,
    S: cameraPositionData.S01C.position,
    SW: new Vector3(0.24926851143457185, 7.148734798070675, -2.152669386742173),
    W: cameraPositionData.S01C.position,
    NW: cameraPositionData.S01C.position,
  },

  N01B: {
    N: cameraPositionData.N01B.position,
    NE: cameraPositionData.N01B.position,
    E: cameraPositionData.N01B.position,
    SE: new Vector3(14.818337626578852, 5.366725507618945, -10.022700898720577),
    S: cameraPositionData.N01B.position,
    SW: cameraPositionData.N01B.position,
    W: cameraPositionData.N01B.position,
    NW: new Vector3(7.679047497758212, 5.278932840067759, -23.88514620193557),
  },
}

export const TCanvas = () => {
  const { pathname } = useLocation()

  const selectedUnit = useStore((s) => s.selectedUnit)
  const filteredUnits = useStore((s) => s.filteredUnits)

  const setCameraData = useCanvasStore((s) => s.setCameraData)
  const setCamera = useCanvasStore((s) => s.setCamera)
  const setSpherical = useCanvasStore((s) => s.setSpherical)

  const renderCanvasOn = ["/residences", "/orbits"]

  const scrolRef = useRef(0)

  const handleClickMesh = (unitData: any) => {
    const id = unitData.unitName
    const row = document.getElementById(id)
    const table = document.getElementById("table-rows-container")

    if (!row || !table) return

    gsap.fromTo(
      scrolRef,
      {
        current: table.scrollTop,
      },
      {
        current: row.offsetTop - innerHeight * 0.5 + 300,
        onUpdate: () => {
          table.scrollTo({
            top: scrolRef.current,
          })
        },
      },
    )

    row.click()
  }

  const colours = {
    on: new Color("#FF7D49"),
    off: new Color("#245AAF"),
  }

  const handleSpherical = (azimuth: number, polar: number, camera: Camera) => {
    const spherical = { azimuth: azimuth, polar }
    setCamera(
      new Vector3(camera.position.x, camera.position.y, camera.position.z),
    )
    setSpherical(spherical)
  }

  const onSelectedUnitChange = useCallback(
    (unit) => {
      if (!unit) return
      const center = unit.center
      const cleanedAspect = unit.aspect.replace("c", "").split("/")[0]
      const block = unit.unitName.split("-")[0]
      const camPos = aspectCameraPositions[block][cleanedAspect]
      setCameraData({
        position: camPos,
        target: center,
      })
    },
    [setCameraData],
  )

  const [loading, setLoading] = useState(true)

  const {
    gpuSaverRef,
    frameLoop,
    handlePointerDown,
    handlePointerUp,
    handleResume,
    shouldRenderCanvas,
  } = useGPUSaver(pathname, renderCanvasOn, 5 * 60 * 1000)

  const orbit = {
    left: "0%",
    width: "100%",
  }
  const residnces = {
    left: "23%",
    width: "77%",
  }

  return (
    <CanvasWrapper
      style={{
        visibility: shouldRenderCanvas ? "visible" : "hidden",

        ...(pathname === "/residences" ? residnces : orbit),
      }}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
    >
      <GPUSaver ref={gpuSaverRef}>
        <button onClick={handleResume}>Resume</button>
      </GPUSaver>
      <Canvas
        frameloop={frameLoop}
        dpr={1.25}
        onCreated={({ gl }) => {
          handleContextLoss(gl)
        }}
      >
        {/* <axesHelper args={[100]} /> */}
        {pathname === "/residences" && (
          <>
            <mesh position={[0, -0.1, 0]} rotation-x={Math.PI * -0.5}>
              <planeGeometry args={[100, 100]} />
              <meshBasicMaterial toneMapped={false} color="#26303B" />
            </mesh>

            {filteredUnits && (
              <>
                <BuildingTouchPoints />
                <UnitOverlayGeometry
                  colours={colours}
                  selectedUnit={selectedUnit?.[0]}
                  onSelectedUnitChange={onSelectedUnitChange}
                  handleClickMesh={handleClickMesh}
                  data={filteredUnits}
                  glbSrc="https://splat-tests.s3.eu-west-2.amazonaws.com/west_ham/westham.glb"
                />
              </>
            )}
          </>
        )}

        <Gsc
          visible={pathname === "/residences" ? true : false}
          src="https://splat-tests.s3.eu-west-2.amazonaws.com/west_ham/site-01.splat"
          onLoaded={() => {
            console.log("Loaded")
          }}
        />
        <Gsc
          visible={pathname === "/orbits" ? true : false}
          src="https://splat-tests.s3.eu-west-2.amazonaws.com/west_ham/master-cleaned.splat"
          onLoaded={() => {
            console.log("Loaded")
          }}
        />
        {pathname === "/orbits" && (
          <>
            <Labels />

            <Sky />
          </>
        )}

        <AnimateCamera
          onAzimuthPolarChange={handleSpherical}
          initialControlsSettings={initialControlsSettings}
          intitalCameraSettings={initCam}
          // cameraData={cameraData}
          store={useCanvasStore}
        />

        {/* <TestLoop /> */}
        {/* <LogCam /> */}
      </Canvas>

      <Compass offset={65} />
    </CanvasWrapper>
  )
}

const GPUSaver = styled.div`
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primary);

  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: 1px solid white;
    border-radius: 40px;
    padding: 10px;
    color: white;
  }
`

const CanvasWrapper = styled.div`
  background-color: var(--primary);
  position: absolute;
  top: 0;

  height: 100%;
`

export default TCanvas

function TestLoop() {
  useFrame(() => {
    // console.log("rendering")
  })

  return null
}

function LogCam() {
  const { camera } = useThree()

  const logCam = () => {
    console.log(
      `new Vector3(${camera.position.x}, ${camera.position.y}, ${camera.position.z})`,
    )
  }

  useEffect(() => {
    window.addEventListener("click", logCam)

    return () => {
      window.removeEventListener("click", logCam)
    }
  }, [camera])

  return null
}
