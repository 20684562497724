import { useEffect, useRef } from "react"
import styled from "styled-components/macro"
import gsap from "gsap"
import { useProjectDataStore } from "@state/projectDataStore"
import { getColor } from "@utils/getColor"

function MenuButton(props) {
  const { onClick, animationCue, buttonProps } = props

  const svgRef = useRef<SVGSVGElement>(null)

  const iconPoints = {
    top: {
      opened: "38,38 50,50 62,62",
      closed: "35,45 50,45 65,45",
    },
    bottom: {
      opened: "38,62 50,50 62,38",
      closed: "35,55 50,55 65,55",
    },
  }

  useEffect(() => {
    if (!svgRef.current) return
    gsap.to("#icon-line-top", {
      attr: {
        points: iconPoints.top[animationCue ? "opened" : "closed"],
      },
    })
    gsap.to("#icon-line-bottom", {
      attr: {
        points: iconPoints.bottom[animationCue ? "opened" : "closed"],
      },
    })
  }, [animationCue])

  const borderColour =
    getColor(props?.navigationData?.burgerBorderColour) || "white"

  const burgerBgColour = getColor(props?.navigationData?.burgerBackgroundColour)

  const burgerWidth = props?.navigationData?.burgerWidth || 3.5

  return (
    <Button
      $burgerWidth={burgerWidth}
      $burgerBackgroundColour={burgerBgColour}
      $burgerBorderColour={borderColour}
      onClick={onClick}
      {...buttonProps}
    >
      <svg width="100%" height="100%" viewBox="0 0 100 100" ref={svgRef}>
        <title>Menu</title>
        <polyline
          fill="none"
          stroke={borderColour}
          strokeWidth={2}
          points={iconPoints.top.closed}
          id="icon-line-top"
        />
        <polyline
          fill="none"
          stroke={borderColour}
          strokeWidth={2}
          points={iconPoints.bottom.closed}
          id="icon-line-bottom"
        />
      </svg>
    </Button>
  )
}

export default MenuButton

type TNavStyledProps = {
  $burgerBackgroundColour?: string
  $burgerBorderColour?: string
  $burgerWidth?: number
}

const Button = styled.div<TNavStyledProps>`
  width: 4rem;
  height: 4rem;
  aspect-ratio: 1;
  pointer-events: all;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 100%;
  background-color: ${(p) => p.$burgerBackgroundColour || "var(--primary)"};
  border: 1px solid var(--accent);
  border: ${(p) =>
    `1px solid ${p.$burgerBorderColour}` || "1px solid var(--primary)"};

  &:active {
    opacity: 0.5;
    transform: scale(0.95);
    transition: 0.3s;
  }
`
