import styled from "styled-components/macro"

const Orbit = ({ children }) => {
  return (
    <>
      <ObitPageWrapper>{children}</ObitPageWrapper>
    </>
  )
}

const ObitPageWrapper = styled.div`
  width: 100%;
  height: 100%;

  .grid-wrapper {
    background-size: cover;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }
`

export default Orbit
