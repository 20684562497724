import Masonry from "react-masonry-css"
import styled from "styled-components/macro"
import { useProjectDataStore } from "@state/projectDataStore"
import { useStore } from "@state/store"
import { Suspense, useEffect, useRef, useState } from "react"
import { getColor } from "@utils/getColor"

const ImageGridMasonry = ({ galleryData, categories, contentControls }) => {
  const syncState = useStore((s) => s.syncState)
  const [paginated, setPaginated] = useState([])
  const images = galleryData?.images
  const scroll = galleryData?.scroll
  const spacing = galleryData?.spacing
  const rounded = galleryData?.rounded
  const style = galleryData?.style
  const colors = galleryData?.colors

  const selectedCategory = categories[contentControls?.index]
  const filteredImages = images?.filter(
    (image) => image?.category === selectedCategory,
  )
  const imgsPerPage = 7
  const grouped = []

  const galleryIndexChange = (image) => {
    const index = filteredImages?.findIndex((obj) => obj.id === image.id)
    syncState({ galleryImagesIndex: index })
  }

  const scrollRight = () => {
    document
      .getElementById("grid")
      .scrollBy({ left: +1000, behavior: "smooth" })
  }

  const scrollLeft = () => {
    document
      .getElementById("grid")
      .scrollBy({ left: -1000, behavior: "smooth" })
  }
  useEffect(() => {
    for (let i = 0; i < filteredImages.length; i += imgsPerPage) {
      const sliced = [...filteredImages]?.slice(i, i + imgsPerPage)
      const groupOne = []
      const groupTwo = []
      const groupThree = []
      sliced.forEach((img, i) => {
        if ([0, 1, 2].includes(i)) {
          groupOne.push(img)
        } else if ([3, 4].includes(i)) {
          groupTwo.push(img)
        } else if ([5, 6].includes(i)) {
          groupThree.push(img)
        }
      })
      grouped.push([groupOne, groupTwo, groupThree])
    }
    setPaginated(grouped)
  }, [contentControls])
  return (
    <MasonryWrapper
      scroll={scroll}
      className="scrollref grid-wrapper"
      colors={colors}
      windowType={style}
      $categoryColor={getColor(galleryData?.categoryColor)}
    >
      {scroll === "horizontal" && (
        <HorizontalScroll spacing={spacing} id="grid">
          {paginated &&
            paginated.map(
              (arr, i) =>
                arr.length !== 0 &&
                arr.map(
                  (imgArr, ind) =>
                    imgArr.length !== 0 && (
                      <Block
                        key={`block-${selectedCategory}-${ind}`}
                        spacing={spacing}
                        rounded={rounded}
                      >
                        {imgArr.map(
                          (img, i) =>
                            img && (
                              <img
                                onClick={() => galleryIndexChange(img)}
                                key={`${selectedCategory}-img-${i}`}
                                src={img?.content?.publicURL}
                              />
                            ),
                        )}
                      </Block>
                    ),
                ),
            )}
        </HorizontalScroll>
      )}
      {/* {scroll === "horizontal" && (
        <HorizontalScroll spacing={spacing}>
          {[0, 5, 10].map((startIndex, index) => (
            <Block key={startIndex} spacing={spacing} rounded={rounded}>
              {images &&
                images
                  .filter((image) => image?.category === selectedCategory)
                  ?.slice(startIndex, startIndex + 5)
                  ?.map((image, index) => (
                    <img
                      onClick={() => galleryIndexChange(startIndex + index)}
                      key={index}
                      className={`grid-item item${index + 1}`}
                      src={image?.content?.publicURL}
                    />
                  ))}
            </Block>
          ))}
        </HorizontalScroll>
      )} */}
      {scroll === "horizontal" && (
        <div className="button">
          <div onClick={scrollLeft}>
            {" "}
            <img src="/assets/icons/back.svg" alt="" />
          </div>
          <div onClick={scrollRight}>
            <img
              src="/assets/icons/back.svg"
              style={{ transform: "rotate(180deg)" }}
              alt=""
            />
          </div>
        </div>
      )}
    </MasonryWrapper>
  )
}

export default ImageGridMasonry

const MasonryWrapper = styled.div<{
  scroll
  colors
  windowType: string
  $categoryColor: string
}>`
  width: 100%;
  overflow-y: ${({ scroll }) => (scroll === "horizontal" ? "hidden" : "auto")};
  overflow-x: ${({ scroll }) => (scroll === "horizontal" ? "auto" : "hidden")};
  height: ${({ windowType }) => (windowType === "full" ? "100%" : "100%")};
  padding: ${({ windowType }) => (windowType === "window" ? "9rem 2rem" : "")};

  .button {
    display: flex;
    gap: 1rem;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 2.5rem;
    cursor: pointer;

    div {
      background-color: var(--primary);
      padding: 1rem;
      color: #fff;
      width: 4rem;
      height: 4rem;
      img {
        width: 100%;
        height: 100%;
        padding: 0.5rem;
      }
      &:active {
        opacity: 0.75;
        transform: scale(0.95);
        transition: 0.3s;
      }
    }
  }
`

const HorizontalScroll = styled.div<{ spacing: string }>`
  --gap: ${({ spacing }) =>
    spacing === "none"
      ? "0rem"
      : spacing === "large"
        ? "1.75rem"
        : spacing === "medium"
          ? "0.75rem"
          : "0.15rem"};
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`

const Block = styled.div<{ spacing: string; rounded: string }>`
  --margin: calc(var(--gap) / 2);
  display: inline-flex;
  height: 100%;
  vertical-align: top;
  margin-right: var(--gap);
  height: 100%;
  flex-direction: column;
  position: relative;

  & img {
    position: absolute;
    width: 100%;
    object-fit: cover;
  }

  :nth-of-type(3n + 1) {
    width: calc(36.5vw - var(--gap));

    & img {
      :nth-of-type(1) {
        width: 100%;
        height: calc(60% - var(--margin));
        top: 0;
      }
      :nth-of-type(2) {
        width: calc(50% - var(--margin));
        height: calc(40% - var(--margin));
        bottom: 0;
        left: 0;
      }
      :nth-of-type(3) {
        width: calc(50% - var(--margin));
        height: calc(40% - var(--margin));
        bottom: 0;
        right: 0;
      }
    }
  }

  :nth-of-type(3n + 2) {
    width: calc(24vw - var(--gap));

    & img {
      :nth-of-type(1) {
        width: 100%;
        height: calc(30% - var(--margin));
        top: 0;
      }
      :nth-of-type(2) {
        width: 100%;
        height: calc(70% - var(--margin));
        bottom: 0;
      }
    }
  }

  :nth-of-type(3n + 3) {
    width: calc(36.5vw - var(--gap));
    margin-right: none;
    & img {
      :nth-of-type(1) {
        width: 100%;
        height: calc(50% - var(--margin));
        top: 0;
      }
      :nth-of-type(2) {
        width: 100%;
        height: calc(50% - var(--margin));
        bottom: 0;
      }
    }
  }
`

// const Block = styled.div<{ spacing: string; rounded: string }>`
//   display: grid;
//   width: 50%;
//   height: 100%;
//   grid-template-columns: repeat(3, 1fr);
//   grid-template-rows: repeat(3, 1fr);
//   grid-gap: ${({ spacing }) =>
//     spacing === "none"
//       ? "0rem"
//       : spacing === "large"
//         ? "1.75rem"
//         : spacing === "medium"
//           ? "0.75rem"
//           : "0.15rem"};

//   .grid-item {
//     background-color: #ccc;
//     border-radius: ${({ rounded }) => (rounded ? "0.75rem" : "0rem")};
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     cursor: pointer;

//     &:active {
//       opacity: 0.75;
//       transform: scale(0.99);
//       transition: 0.3s;
//     }
//   }

//   .item1 {
//     grid-column: span 2;
//     grid-row: span 2;
//   }

//   .item2 {
//     grid-column: span 1;
//     grid-row: span 1;
//   }

//   .item3 {
//     grid-column: span 1;
//     grid-row: span 2;
//   }

//   .item4 {
//     grid-column: span 1;
//     grid-row: span 1;
//   }
// `

const Image = styled.img<{ rounded; scroll }>`
  display: block;
  width: 100%;
  height: ${({ scroll }) => (scroll === "horizontal" ? "50%" : "auto")};
  object-fit: cover;
  transition: 0.3s;
  border-radius: ${({ rounded }) => (rounded ? "0.75rem" : "0rem")};
  cursor: pointer;

  &:active {
    opacity: 0.75;
    transform: scale(0.99);
    transition: 0.3s;
  }
`

const masonryStyles = `
.masonry-grid {
  display: flex;
  margin: 0rem;
  }
`

document.head.insertAdjacentHTML("beforeend", `<style>${masonryStyles}</style>`)
