export const cameraPointsTemp = {
  0: {
    position: new Vector3(34, 10, -15),
    target: new Vector3(0, 0, 0),
  },
  1: {
    position: new Vector3(
      17.0844008203953,
      29.578567196263712,
      -5.680999428319062,
    ),
    target: new Vector3(0, 0, 0),
  },
  2: {
    position: new Vector3(
      -16.228064117090973,
      9.40568445105459,
      -8.6778920137222,
    ),
    target: new Vector3(0, 0, -10),
  },
}
import { Vector3 } from "three"

export const cameraPositionData = {
  defaultFider: {
    position: new Vector3(26, 12, -11),
    target: new Vector3(2, 5, -4),
  },
  ...cameraPointsTemp,
  N01B: {
    position: new Vector3(15, 5, -10),
    target: new Vector3(9, 2, -14),
  },

  S01C: {
    position: new Vector3(12, 6, 3),
    target: new Vector3(5, 3, 3),
  },
} as any
