import axios from "axios"

const PROJECT_ID = import.meta.env.VITE_PROJECT_ID
const ORG_ID = import.meta.env.VITE_PROJECT_ORGANISATION_ID
const SERVER_URL = import.meta.env.VITE_PAYLOAD_SERVER_URL
const CUSTOMERS_ENDPOINT = `${SERVER_URL}/api/customers`
const ANALYTICS_ENDPOINT = `${SERVER_URL}/api/analytics`

export const incrementSentLink = async () => {
  const res = await axios.post(`${ANALYTICS_ENDPOINT}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Project-ID": PROJECT_ID,
      "X-Organisation-ID": ORG_ID,
    },
  })

  return res
}
export const createNewCustomer = async (
  name: string,
  email: string,
  shareType: string,
  favourites: any[],
) => {
  // Send name and email to backend to get the QR code URL
  const res = await axios.post(
    `${CUSTOMERS_ENDPOINT}`,
    {
      name,
      email,
      shareType,
      favourites,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Project-ID": PROJECT_ID,
      },
    },
  )

  return res
}
