import gsap from "gsap"

import { Distances, POI } from "@common/features/GoogleMaps/mapStore"

export type InitMapParams = {
  mapRef: React.RefObject<HTMLDivElement>
  mainMarker: POI
  zoom: number
  center: { lat: number; lng: number }
  disableDefaultUI: boolean
  mapTypeControl: boolean
  mapStyling?: any
}

const url =
  "https://maps.googleapis.com/maps/api/js?v=beta&key=AIzaSyCDNHFnp2xa0vefJ9fMpTe6N_eHLdawMyY"

const opacityMarker = {
  value: 0.01,
}

export const loadScript = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const existingScript = document.querySelector(`script[src="${url}"]`)
    if (existingScript) {
      resolve()
      return
    }

    const script = document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    script.onload = () => resolve()
    script.onerror = () => reject(new Error(`Failed to load script: ${url}`))
    document.head.appendChild(script)
  })
}

export const initMap = async ({
  mapRef,
  mainMarker,
  zoom,
  center,
  disableDefaultUI,
  mapTypeControl,
  mapStyling,
}: InitMapParams & {}): Promise<google.maps.Map | null> => {
  if (!window.google) {
    console.error("Google Maps API is not loaded.")
    return null
  }

  // Create a new StyledMapType object, passing it an array of styles,
  // and the name to be displayed on the map type control.
  const styledMapType = new google.maps.StyledMapType(mapStyling, {
    name: "Styled Map",
  })

  const { Map } = (await google.maps.importLibrary(
    "maps",
  )) as google.maps.MapsLibrary
  const { AdvancedMarkerElement } = (await google.maps.importLibrary(
    "marker",
  )) as google.maps.MarkerLibrary

  const mapInstance = new Map(mapRef.current as HTMLElement, {
    zoom,
    center,
    disableDefaultUI,
    mapTypeControl,
    styles: mapStyling,
    mapTypeControlOptions: {
      mapTypeIds: ["roadmap", "satellite", "hybrid", "terrain", "styled_map"],
    },
    mapId: "6c9fb364c6ec1d48",
    backgroundColor: "#f8f9fa",
  })

  const bgColor = getComputedStyle(document.body).getPropertyValue("--primary")
  const strokeColor = getComputedStyle(document.body).getPropertyValue(
    "--secondary",
  )
  const svgNS = "http://www.w3.org/2000/svg"
  const mainMarkerElement = document.createElementNS(svgNS, "svg")
  mainMarkerElement.style.position = "absolute"
  mainMarkerElement.style.transform = "translateY(50%)"
  mainMarkerElement.setAttribute("width", "100%")
  mainMarkerElement.setAttribute("height", "100%")
  mainMarkerElement.setAttribute("viewBox", "0 0 300 200")
  mainMarkerElement.setAttribute("preserveAspectRatio", "xMidYMid meet")
  const image = document.createElementNS(svgNS, "image")
  image.setAttribute("href", mainMarker.iconPath?.url)
  image.setAttribute("width", "250")
  image.setAttribute("height", "185")
  image.setAttribute("x", "25")
  image.setAttribute("y", "7.5")
  image.setAttribute("preserveAspectRatio", "xMaxYMid meet")
  const rectangle = document.createElementNS(svgNS, "rect")
  rectangle.setAttribute("width", "300")
  rectangle.setAttribute("height", "100")
  rectangle.setAttribute("x", "0")
  rectangle.setAttribute("y", "50")
  rectangle.setAttribute("fill", bgColor)
  rectangle.setAttribute("fill", bgColor)
  rectangle.setAttribute("stroke", strokeColor)
  rectangle.setAttribute("stroke-width", "2")
  rectangle.setAttribute("fill-opacity", "0.8")
  mainMarkerElement.appendChild(rectangle)
  mainMarkerElement.appendChild(image)

  const markerWrapper = document.createElement("div")
  markerWrapper.style.position = "relative"
  markerWrapper.style.width = "200px"
  markerWrapper.style.height = "150px"
  markerWrapper.style.position = "relative"
  markerWrapper.appendChild(mainMarkerElement)

  // const mainMarkerElement = document.createElement("img")
  // mainMarkerElement.src = mainMarker.iconPath?.url
  // mainMarkerElement.height = 40 //mainMarker.size

  new AdvancedMarkerElement({
    map: mapInstance,
    position: mainMarker.coordinates,
    content: markerWrapper,
    zIndex: 10
  })

  //Associate the styled map with the MapTypeId and set it to display.
  mapInstance.mapTypes.set("styled_map", styledMapType)
  mapInstance.setMapTypeId("styled_map")

  return mapInstance
}

export const calculateAndDisplayRoute = (
  start: any,
  end: any,
  travelMode: "DRIVING" | "WALKING" | "BICYCLING" | "TRANSIT",
  transitMode: "SUBWAY" | "BUS" | "RAIL" | "TRAIN" | "TRAM",
  directionsService: google.maps.DirectionsService,
  directionsRenderer: google.maps.DirectionsRenderer,
  mapInstance: google.maps.Map,
  setRouteSteps: (routeSteps: google.maps.DirectionsStep[]) => void,
) => {
  directionsService
    .route({
      origin: start,
      destination: end,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      travelMode: google.maps.TravelMode[travelMode],
      transitOptions: {
        modes: [google.maps.TransitMode[transitMode]],
      },
    })
    .then((response: google.maps.DirectionsResult) => {
      setRouteSteps(response?.routes[0]?.legs[0]?.steps)
      directionsRenderer.setDirections(response)
      directionsRenderer.setMap(mapInstance)
      gsap.fromTo(
        opacityMarker,
        {
          value: 0.01,
        },
        {
          value: 1,
          duration: 0.4,
          onUpdate: () => {
            directionsRenderer.setOptions({
              polylineOptions: {
                //@ts-ignore
                ...directionsRenderer?.polylineOptions,
                strokeOpacity: opacityMarker.value,
              },
            })
            directionsRenderer.setMap(mapInstance)
          },
        },
      )
    })
    .catch((e: any) => window.alert("Directions request failed due to " + e))
}

export const getUpdatedMapStyle = (mapStyling: any, customColors: any) => {
  const updatedMapStyle = mapStyling.map((style: any) => {
    if (style.featureType === "water" && style.elementType === "geometry") {
      return {
        ...style,
        stylers: style.stylers.map((styler: any) => {
          if (styler.color) {
            return {
              ...styler,
              color: customColors?.waterColor || styler.color,
            }
          }
          return styler
        }),
      }
    }

    if (style.featureType === "landscape" && style.elementType === "geometry") {
      return {
        ...style,
        stylers: style.stylers.map((styler: any) => {
          if (styler.color) {
            return {
              ...styler,
              color: customColors?.landscapeColor || styler.color,
            }
          }
          return styler
        }),
      }
    }

    if (style.featureType === "poi.park" && style.elementType === "geometry") {
      return {
        ...style,
        stylers: style.stylers.map((styler: any) => {
          if (styler.color) {
            return {
              ...styler,
              color: customColors?.parksColor || styler.color,
            }
          }
          return styler
        }),
      }
    }

    if (
      style.featureType === "road.arterial" &&
      style.elementType === "geometry"
    ) {
      return {
        ...style,
        stylers: style.stylers.map((styler: any) => {
          if (styler.color) {
            return {
              ...styler,
              color: customColors?.arterialColor || styler.color,
            }
          }
          return styler
        }),
      }
    }

    if (
      style.featureType === "road.local" &&
      style.elementType === "geometry"
    ) {
      return {
        ...style,
        stylers: style.stylers.map((styler: any) => {
          if (styler.color) {
            return {
              ...styler,
              color: customColors?.localColor || styler.color,
            }
          }
          return styler
        }),
      }
    }

    if (
      style.featureType === "administrative" &&
      style.elementType === "labels"
    ) {
      return {
        ...style,
        stylers: style.stylers.map((styler: any) => {
          if (styler.color) {
            return {
              ...styler,
              color: customColors?.labelsColor || styler.color,
            }
          }
          return styler
        }),
      }
    }

    return style
  })

  return updatedMapStyle
}

export const averageDistance = (distances: Distances): string => {
  const distanceValues = Object.values(distances).map((distance) =>
    parseDistance(distance.distance),
  )
  const totalDistance = distanceValues.reduce(
    (acc, current) => acc + current.distance,
    0,
  )
  const averageDistance = totalDistance / distanceValues.length
  const unitSystem =
    distanceValues.length > 0 ? distanceValues[0].unitSystem : "mi"
  return `${averageDistance.toFixed(1)} ${unitSystem}`
}

const parseDistance = (
  distance: string,
): {
  unitSystem: string
  distance: number
} => {
  const [value, unit] = distance.split(" ")
  return {
    unitSystem: unit ? unit : "mi",
    distance: parseFloat(value) || 0,
  }
}
