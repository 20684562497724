import TCanvas from "@common/canvas/TCanvas"
import WindowNav from "@common/components/Navigation/WindowNav"
import PageBuilder from "@common/general/PageBuilder"
import styled from "styled-components/macro"

const FullScreenLayout = () => {
  return (
    <>
      <FullscreenLayoutContentWr>
        <PageBuilder />
        <TCanvas />
      </FullscreenLayoutContentWr>
      <FullscreenLayoutNavWr>
        <WindowNav />
      </FullscreenLayoutNavWr>
    </>
  )
}

export default FullScreenLayout

const FullscreenLayoutNavWr = styled.nav`
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  /* pointer-events: none; */
`

const FullscreenLayoutContentWr = styled.main`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
`
