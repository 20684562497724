import NavTextButton from "@common/ui/NavTextButton"
import { useStore } from "@state/store"
import { useEffect, useState } from "react"
import styled from "styled-components/macro"
import gsap from "gsap"

const Architects = ({ aboutData }) => {
  const aboutIndex = useStore((s) => s.aboutIndex)

  useEffect(() => {
    gsap.to(".architectsWrapper", {
      autoAlpha: aboutIndex === 1 ? 1 : 0,
      duration: 0.6,
      ease: "power3.inOut",
    })
  }, [aboutIndex])
  return (
    <ArchitectsWrapper className="architectsWrapper">
      <div className="architectsContentLeft">
        <div className="top">
          <div className="main">
            {aboutData?.main} <br />
            <span>{aboutData?.mainName}</span>
          </div>

          <div className="quote">
            <div className="quoteString">{aboutData?.quote}</div>
            <div className="by">{aboutData?.by}</div>
            <div className="by2">{aboutData?.by2}</div>
          </div>
        </div>
        <Stats>
          {aboutData?.stats?.map((stat, index) => (
            <div className="stat" key={index}>
              <div className="statText">{stat?.text}</div>
              <div className="statType">{stat?.type}</div>
            </div>
          ))}
        </Stats>
        <Flex>
          {aboutData?.description?.map((block, index) => (
            <div key={index} className="flex-item">
              <div className="aboutTitleVariant">{block?.title}</div>
              <div className="aboutDesc">{block?.desc}</div>
            </div>
          ))}
        </Flex>
      </div>
      <div className="image">
        <img src={aboutData?.image?.publicURL} alt="" />
      </div>
    </ArchitectsWrapper>
  )
}

export default Architects

const ArchitectsWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--peabodyGray);
  display: flex;

  .architectsContentLeft {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 65%;
    height: 80%;
    margin: auto 0;
    padding: 2rem;
    gap: 10rem;

    .top {
      display: flex;
      justify-content: baseline;
      align-items: center;
      gap: 10rem;
      width: 100%;
      height: 33%;

      .main {
        font-size: 4rem;
        font-weight: bold;
        color: white;
        line-height: 40px;

        span {
          font-style: italic;
          font-weight: normal;
          color: var(--peabodyTeal);
          font-family: PbdySecondary;
        }
      }

      .quote {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 50%;

        .quoteString {
          width: 75%;
          font-size: 1.25rem;
          color: white;
        }

        .by {
          margin-left: auto;
          font-size: 2rem;
          font-style: italic;
          color: var(--peabodyTeal);
          font-family: PbdySecondary;
        }

        .by2 {
          font-size: 1rem;
          color: #d3d3d3;
          letter-spacing: 0.5rem;
        }
      }
    }
  }

  .image {
    width: 35%;
    background-color: var(--peabodyTeal);
    display: flex;

    img {
      width: 100%;
      height: 80%;
      object-fit: cover;
      margin: auto;
      transform: translateX(-15%);
    }
  }
`

const Stats = styled.div`
  display: flex;
  border: 1px solid white;
  width: 80%;

  .stat {
    border-right: 1px solid white;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    width: 100%;

    &:nth-child(3) {
      border: none;
    }

    .statText {
      color: #e4e4e4;
      font-family: PbdyPrimary;
      font-size: 4rem;
    }
    
    .statType {
      font-size: 2.5rem;
      letter-spacing: 0.2rem;
      font-family: PbdySecondary;
      color: var(--peabodyTeal);
      font-style: italic;
    }
  }
`

const Flex = styled.div`
  display: flex;
  gap: 10%;
  width: 90%;
  height: 33%;

  .flex-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 1rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .aboutTitleVariant {
      font-size: 2rem;
      font-weight: bold;
      color: var(--peabodyTeal);
    }

    .aboutDesc {
      line-height: 20px;
      font-size: 1.15rem;
      color: white;
      font-weight: normal;
    }
  }
`
