import { BrowserRouter, useSearchParams } from "react-router-dom"
import ProjectLayout from "@common/general/ProjectLayout"
import { useEffect, useState } from "react"
import { useProjectDataStore } from "@state/projectDataStore"
import { useCustomLivePreview } from "@lib/utils/useCustomLivePreview"
import { loadProjectData } from "@utils/projectDataLoader"
import { updateProjectDataStore } from "@utils/updateProjectDataStore"
import { loadLiveProjectData } from "@utils/projectDataLiveLoader"
import PayloadLivePreview from "@utils/PayloadLivePreview"
import UpdateRoutes from "@utils/UpdateRoutes"
import { fetchLiveData } from "./api/fetchLiveData"
import { useStore } from "@state/store"

function App() {
  return (
    <BrowserRouter>
      <ProjectWrapper />
    </BrowserRouter>
  )
}

export default App

function ProjectWrapper() {
  const { livePreview, setLocalState } = useProjectDataStore((s) => ({
    livePreview: s.livePreview,
    setLocalState: s.setLocalState,
  }))

  const [searchParams] = useSearchParams()

  useEffect(() => {
    const openNavParam = searchParams.get("livePreview")
    if (openNavParam !== null) {
      setLocalState({ livePreview: true })
    }
  }, [searchParams])

  return (
    <>
      <ProjectLayout />
      {livePreview && <PayloadLivePreview />}
    </>
  )
}
