export const getColor = (color: string): string => {
  const colorMap: Record<string, string> = {
    black: "black",
    white: "white",
    primary: "var(--primary)",
    secondary: "var(--secondary)",
    accent: "var(--accent)",
  }

  return colorMap[color?.toLowerCase()] || color
}
