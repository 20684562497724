import styled from "styled-components/macro"
import SpecsImage from "./SpecsImage"
import StylePicker from "./StylePicker"

const SpecsSection = ({ unitData }) => {
  return (
    <Wrapper className="specs">
      <SpecsImage unitData={unitData} />
      <StylePicker unitData={unitData} />
    </Wrapper>
  )
}

export default SpecsSection

const Wrapper = styled.div`
  pointer-events: all;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
`
